"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CarRoute = void 0;
var _utils = require("../utils");
class CarRoute {
  constructor(distance, duration, geometry) {
    this.distance = distance;
    this.duration = duration;
    this.geometry = geometry;
    this.bounds = (0, _utils.toBounds)(geometry);
  }
}
exports.CarRoute = CarRoute;