"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportDataProjection = exports.ExportData = void 0;
class ExportData {
  constructor(id, created, type, model, description, title, tablerExport) {
    this.id = id;
    this.created = created;
    this.type = type;
    this.model = model;
    this.description = description;
    this.title = title;
    this.tablerExport = tablerExport;
  }
}
exports.ExportData = ExportData;
class ExportDataProjection {
  constructor(id, title, srid) {
    this.id = id;
    this.title = title;
    this.srid = srid;
  }
}
exports.ExportDataProjection = ExportDataProjection;