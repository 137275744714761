"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.facilitiesLabels = exports.facilitiesDistribution = exports.PublicTransportSection = exports.PedestrianSection = exports.BikeSection = void 0;
var _utils = require("../utils");
class Section {
  constructor(transportMode, direction, distance, duration, geometry, features, wayPoints, wayPointsIndices, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival) {
    this.transportMode = transportMode;
    this.direction = direction;
    this.distance = distance;
    this.duration = duration;
    this.geometry = geometry;
    this.features = features;
    this.wayPoints = wayPoints;
    this.wayPointsIndices = wayPointsIndices;
    this.estimatedDatetimeOfDeparture = estimatedDatetimeOfDeparture;
    this.estimatedDatetimeOfArrival = estimatedDatetimeOfArrival;
    this.bounds = (0, _utils.toBounds)(features.reduce((res, _ref) => {
      let {
        geometry: {
          coordinates
        }
      } = _ref;
      res.coordinates.push(coordinates);
      return res;
    }, {
      type: 'MultiLineString',
      coordinates: []
    }));
  }
}
const facilitiesDistribution = exports.facilitiesDistribution = {
  cycleway: 'recommended',
  ferry: 'normal',
  forbidden: 'normal',
  footway: 'recommended',
  greenway: 'recommended',
  lane: 'recommended',
  livingstreet: 'normal',
  mixedfacilities: 'normal',
  none: 'normal',
  opposite: 'normal',
  pedestrian: 'recommended',
  primary: 'discouraged',
  residential: 'normal',
  secondary: 'discouraged',
  service: 'normal',
  sharebusway: 'recommended',
  steps: 'normal',
  tertiary: 'discouraged',
  zone30: 'normal'
};
const facilitiesLabels = exports.facilitiesLabels = {
  cycleway: 'commons.facilities.cycleway',
  ferry: 'commons.facilities.ferry',
  footway: 'commons.facilities.footway',
  greenway: 'commons.facilities.greenway',
  lane: 'commons.facilities.lane',
  livingstreet: 'commons.facilities.living_street',
  mixedfacilities: 'commons.facilities.mixed_facilities',
  opposite: 'commons.facilities.opposite',
  pedestrian: 'commons.facilities.pedestrian',
  primary: 'commons.facilities.primary',
  residential: 'commons.facilities.residential_road',
  secondary: 'commons.facilities.secondary',
  service: 'commons.facilities.quiet_road',
  sharebusway: 'commons.facilities.shared_busway',
  steps: 'commons.facilities.stairs',
  zone30: 'commons.facilities.30kms_area',
  forbidden: 'commons.facilities.forbidden',
  none: 'commons.facilities.none',
  tertiary: 'commons.facilities.tertiary',
  unknown: 'commons.facilities.unknown',
  path: 'commons.facilities.path'
};
class BikeSection extends Section {
  transportMode = 'bike';
  constructor(profile, eBike, direction, distance, duration, verticalGain, verticalLoss, calories, geometry, features, waypoints, waypointsIndices, instructions, elevations, facilities, bikeStations, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival) {
    super('bike', direction, distance, duration, geometry, features, waypoints, waypointsIndices, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival);
    this.profile = profile;
    this.eBike = eBike;
    this.verticalGain = verticalGain;
    this.verticalLoss = verticalLoss;
    this.calories = calories;
    this.instructions = instructions;
    this.elevations = elevations;
    this.facilities = facilities;
    this.bikeStations = bikeStations;
  }
}
exports.BikeSection = BikeSection;
class PedestrianSection extends Section {
  transportMode = 'pedestrian';
  constructor(direction, distance, duration, verticalGain, verticalLoss, calories, geometry, features, waypoints, waypointsIndices, instructions, elevations, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival) {
    super('pedestrian', direction, distance, duration, geometry, features, waypoints, waypointsIndices, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival);
    this.verticalGain = verticalGain;
    this.verticalLoss = verticalLoss;
    this.calories = calories;
    this.instructions = instructions;
    this.elevations = elevations;
  }
}
exports.PedestrianSection = PedestrianSection;
class PublicTransportSection extends Section {
  transportMode = 'publicTransports';
  constructor(details, distance, duration, geometry, features, waypoints, waypointsIndices, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival) {
    super('publicTransports', '', distance, duration, geometry, features, waypoints, waypointsIndices, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival);
    this.details = details;
  }
}
exports.PublicTransportSection = PublicTransportSection;