"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FirebaseService = void 0;
var _app = require("firebase/app");
var _storage = require("firebase/storage");
class FirebaseService {
  static async init() {
    const app = (0, _app.initializeApp)({
      apiKey: 'AIzaSyBxpWo1xZtU84LsvM_GcbBwbaMZ1cLbJT4',
      storageBucket: 'apps-showcase.appspot.com'
    });
    this.storage = (0, _storage.getStorage)(app);
  }
  static async uploadImage(folderUrl, blob) {
    try {
      const storageRef = (0, _storage.ref)(this.storage, `${folderUrl}/${new Date().getTime()}.png`);
      const fileRef = await (0, _storage.uploadBytes)(storageRef, blob);
      const url = (0, _storage.getDownloadURL)(fileRef.ref);
      return url;
    } catch (err) {
      console.error('[FirebaseService][uploadImage]', err);
      throw err;
    }
  }
}
exports.FirebaseService = FirebaseService;