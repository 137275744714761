"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CancelledPromiseError = void 0;
exports.useCancellablePromise = useCancellablePromise;
var _react = require("react");
class CancelledPromiseError extends Error {
  constructor() {
    super('promise cancelled');
    this.name = 'CancelledPromiseError';
  }
}
exports.CancelledPromiseError = CancelledPromiseError;
function makeCancelable(promise) {
  let isCanceled = false;
  const wrappedPromise = new Promise((resolve, reject) => {
    promise.then(value => isCanceled ? reject(new CancelledPromiseError()) : resolve(value)).catch(error => isCanceled ? reject(new CancelledPromiseError()) : reject(error));
  });
  return {
    promise: wrappedPromise,
    cancel() {
      isCanceled = true;
    }
  };
}
function useCancellablePromise() {
  const promises = (0, _react.useRef)([]);
  (0, _react.useEffect)(() => {
    return cancelPromises;
  }, []);
  function cancellablePromise(p) {
    const cPromise = makeCancelable(p);
    promises.current.push(cPromise);
    return cPromise.promise;
  }
  function cancelPromises() {
    promises.current.forEach(p => p.cancel());
    promises.current = [];
  }
  return {
    cancellablePromise,
    cancelPromises
  };
}