"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useCyclabilityZones = useCyclabilityZones;
var _centroid = _interopRequireDefault(require("@turf/centroid"));
var _react = require("react");
var _reactI18next = require("react-i18next");
var _units = require("../units");
var _layers = require("./layers");
var _source = require("./source");
var _maplibreGl = require("!maplibre-gl");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const areaSourceId = 'area';
const areaLayerId = 'area';
const cyclabilityZonesSourceId = 'cyclability-zones';
const cyclabilityZonesLayerId = 'cyclability-zones';
const cyclabilityZonesParkingLotsCountSourceId = 'cyclability-zones-parking-lots-count';
const cyclabilityZonesParkingLotsCountLayerId = 'cyclability-zones-parking-lots-count';
const cyclabilityZoneSourceId = 'cyclability-zone';
const cyclabilityZoneLayerId = 'cyclability-zone';
const highlightedZoneSourceId = 'highlighted-cyclability-zone';
const highlightedZoneLayerId = 'highlighted-cyclability-zone';
const parkingsSourceId = 'parkings';
const parkingsLayerId = 'parkings';
const colors = ['#a8caff', '#4c83d9', '#2762bd', '#0a429a', '#063072'];
const radiuses = [{
  value: 4
}, {
  value: 5
}, {
  value: 6
}, {
  value: 7
}, {
  value: 8
}, {
  value: 9
}, {
  value: 10
}, {
  value: 11
}, {
  value: 12
}, {
  value: 13
}, {
  value: 14
}];
function useCyclabilityZones(map, _zoneMap, _ref, _ref2) {
  let {
    onClick,
    getTooltipContent
  } = _ref;
  let {
    isParkings,
    darkTheme,
    outlineWidth
  } = _ref2;
  const [initialized, setInitialized] = (0, _react.useState)(false);
  const {
    t
  } = (0, _reactI18next.useTranslation)();
  const initializedRef = (0, _react.useRef)(false);
  const highlightedZoneTooltip = (0, _react.useRef)();
  const highlightedParkingTooltip = (0, _react.useRef)();
  const zoneMap = (0, _react.useRef)(_zoneMap);
  const {
    addGeoJSONSource: addAreaSource,
    updateGeoJSONSource: updateAreaSource,
    clearGeoJSONSource: clearAreaSource
  } = (0, _source.useSource)(map, areaSourceId);
  const {
    addGeoJSONSource: addCyclabilityZonesSource,
    updateGeoJSONSource: updateCyclabilityZonesSource,
    clearGeoJSONSource: clearCyclabilityZonesSource
  } = (0, _source.useSource)(map, cyclabilityZonesSourceId);
  const {
    addGeoJSONSource: addCyclabilityZonesParkingLotsCountSource,
    updateGeoJSONSource: updateCyclabilityZonesParkingLotsCountSource,
    clearGeoJSONSource: clearCyclabilityZonesParkingLotsCountSource
  } = (0, _source.useSource)(map, cyclabilityZonesParkingLotsCountLayerId);
  const {
    addGeoJSONSource: addCyclabilityZoneSource,
    getGeoJSONSource: getCyclabilityZoneSource,
    updateGeoJSONSource: updateCyclabilityZoneSource,
    clearGeoJSONSource: clearCyclabilityZoneSource
  } = (0, _source.useSource)(map, cyclabilityZoneSourceId);
  const {
    addGeoJSONSource: addHighlightedZoneSource,
    getGeoJSONSource: getHighlightedZoneSource
  } = (0, _source.useSource)(map, highlightedZoneSourceId);
  const {
    addGeoJSONSource: addParkingsSource,
    updateGeoJSONSource: updateParkingsSource,
    clearGeoJSONSource: clearParkingsSource
  } = (0, _source.useSource)(map, parkingsSourceId);
  const {
    addCircleLayer
  } = (0, _layers.useLayers)(map);
  const {
    formatNumber
  } = (0, _units.useUnits)();
  (0, _react.useEffect)(() => {
    zoneMap.current = _zoneMap;
  }, [_zoneMap]);
  (0, _react.useEffect)(() => {
    if (map) {
      map?.setPaintProperty(cyclabilityZonesLayerId, 'fill-outline-color', darkTheme ? '#d3d3d3' : '#3d3d3d');
      map?.setPaintProperty(highlightedZoneLayerId, 'line-color', darkTheme ? '#d3d3d3' : '#3d3d3d');
    }
  }, [darkTheme]);
  let hoveredZoneId;
  let hoveredParkingId;
  function init() {
    if (!map || initializedRef.current) return;
    highlightedZoneTooltip.current = new _maplibreGl.Popup({
      className: 'map-tooltip',
      closeButton: false
    });
    highlightedParkingTooltip.current = new _maplibreGl.Popup({
      className: 'map-tooltip',
      closeButton: false
    });
    if (getCyclabilityZoneSource()) {
      initializedRef.current = true;
      setInitialized(true);
      map.on('mousemove', cyclabilityZonesLayerId, handleMoveInZone);
      return;
    }
    addAreaSource();
    addCyclabilityZonesSource();
    addCyclabilityZonesParkingLotsCountSource();
    addCyclabilityZoneSource();
    addHighlightedZoneSource();
    addParkingsSource();
    map.addLayer({
      id: cyclabilityZonesLayerId,
      type: 'fill',
      source: cyclabilityZonesSourceId,
      paint: {
        'fill-color': ['get', 'color'],
        'fill-opacity': 0.5,
        'fill-outline-color': darkTheme ? '#fff' : '#3d3d3d'
      }
    }, 'labels');
    map.addLayer({
      id: `${cyclabilityZonesParkingLotsCountLayerId}-circle`,
      type: 'circle',
      source: cyclabilityZonesParkingLotsCountSourceId,
      paint: {
        'circle-radius': 25,
        'circle-color': '#283859'
      }
    });
    map.addLayer({
      id: cyclabilityZonesParkingLotsCountLayerId,
      type: 'symbol',
      source: cyclabilityZonesParkingLotsCountSourceId,
      layout: {
        'text-field': ['get', 'count'],
        'text-font': ['Nunito'],
        'text-size': 12,
        'text-max-width': 3
      },
      paint: {
        'text-color': '#fff'
      }
    });
    map.addLayer({
      id: highlightedZoneLayerId,
      type: 'line',
      source: highlightedZoneSourceId,
      paint: {
        'line-color': darkTheme ? '#eee' : '#3d3d3d',
        'line-width': 2
      }
    }, 'labels');
    map.addLayer({
      id: cyclabilityZoneLayerId,
      type: 'line',
      source: cyclabilityZoneSourceId,
      paint: {
        'line-color': '#3d3d3d',
        'line-width': 2
      }
    }, 'labels');
    map.addLayer({
      id: areaLayerId,
      type: 'line',
      source: areaSourceId,
      paint: {
        'line-color': '#3d3d3d',
        'line-width': outlineWidth || 4
      }
    }, 'labels');
    addCircleLayer(parkingsLayerId, parkingsSourceId, {
      'circle-radius': ['get', 'radius'],
      'circle-color': ['get', 'color']
    });
    map.on('mousemove', cyclabilityZonesLayerId, handleMoveInZone);
    map.on('click', cyclabilityZonesLayerId, _ref3 => {
      let {
        features
      } = _ref3;
      if (features && features.length > 0) {
        const {
          id
        } = features[0];
        if (id && typeof id === 'number') {
          const zone = zoneMap.current[id];
          if (zone) onClick?.(zone);
        }
      }
    });
    map.on('mouseleave', cyclabilityZonesLayerId, () => {
      map.getCanvas().style.cursor = '';
      clearZoneHighlight();
    });
    map.on('moveend', () => {
      if (map.getZoom() >= 15) {
        map.getCanvas().style.cursor = '';
        clearZoneHighlight();
      }
    });
    map.on('mousemove', parkingsLayerId, _ref4 => {
      let {
        features
      } = _ref4;
      if (features && features.length > 0) {
        const {
          geometry,
          properties
        } = features[0];
        if (geometry.type !== 'Point' || !properties) return;
        highlightedParkingTooltip.current?.setHTML(`<h3>${[properties.type === 'locked' ? t('commons.parking_types.locked') : properties.type === 'supervised' ? t('commons.parking_types.secure') : properties.type === 'sheltered' ? t('commons.parking_types.sheltered') : '', properties.rackType === 'stands' ? t('commons.parking_types.arches') : properties.rackType === 'wallLoops' ? t('commons.parking_types.racks') : '', properties.free ? t('commons.parking_types.free') : t('commons.parking_types.private')].filter(Boolean).join(' • ')}</h3><span>${t('commons.parking.racks', {
          count: properties.capacity || 0,
          capacity: properties.capacity || 0
        })}</span>`).setLngLat({
          lat: geometry.coordinates[1],
          lng: geometry.coordinates[0]
        }).addTo(map);
        if (hoveredParkingId !== properties?.id) {
          hoveredParkingId = properties?.id;
          if (hoveredParkingId) {
            map.getCanvas().style.cursor = 'pointer';
          } else {
            clearParkingsHighlight();
          }
        }
      }
    });
    map.on('mouseleave', parkingsLayerId, () => {
      map.getCanvas().style.cursor = '';
      clearParkingsHighlight();
    });
    initializedRef.current = true;
    setInitialized(true);
  }
  function handleMoveInZone(_ref5) {
    let {
      lngLat,
      features
    } = _ref5;
    if (map && features && features.length > 0 && map.getZoom() < 15) {
      const {
        id,
        geometry,
        properties
      } = features[0];
      highlightedZoneTooltip.current?.setHTML(getTooltipContent?.(properties) || '').setLngLat(lngLat).addTo(map);
      if (hoveredZoneId !== id) {
        const highlightedZoneSource = getHighlightedZoneSource();
        if (!highlightedZoneSource) return;
        hoveredZoneId = id;
        if (hoveredZoneId) {
          map.getCanvas().style.cursor = 'pointer';
          highlightedZoneSource.setData({
            type: 'Feature',
            geometry,
            properties: {}
          });
        } else {
          clearZoneHighlight();
        }
      }
    }
  }
  function update(zone, childZones, selectedStatsKey, parkings) {
    clear(true);
    if (childZones) {
      const maxDistance = childZones.reduce((res, _ref6) => {
        let {
          stats
        } = _ref6;
        const dateStats = stats.find(_ref7 => {
          let {
            dateAsKey
          } = _ref7;
          return dateAsKey === selectedStatsKey;
        });
        if (dateStats) {
          return Math.max(res, dateStats.distances.all);
        }
        return res;
      }, -1);
      const maxSlots = childZones.reduce((res, _ref8) => {
        let {
          statsBNSC
        } = _ref8;
        const dateStatsBNSC = statsBNSC.find(_ref9 => {
          let {
            dateAsKey
          } = _ref9;
          return dateAsKey === selectedStatsKey;
        });
        if (dateStatsBNSC) {
          return Math.max(res, dateStatsBNSC.slots.all);
        }
        return res;
      }, -1);
      updateCyclabilityZonesSource({
        type: 'FeatureCollection',
        features: childZones.reduce((res, _ref10) => {
          let {
            id,
            name,
            geometry,
            stats,
            statsBNSC
          } = _ref10;
          const dateStats = stats.find(_ref11 => {
            let {
              dateAsKey
            } = _ref11;
            return dateAsKey === selectedStatsKey;
          });
          const dateStatsBNSC = statsBNSC.find(_ref12 => {
            let {
              dateAsKey
            } = _ref12;
            return dateAsKey === selectedStatsKey;
          });
          const distance = dateStats?.distances.all || 0;
          const slots = dateStatsBNSC?.slots.all || 0;
          const colorIndex = isParkings ? Math.round(slots / maxSlots * (colors.length - 1)) : Math.round(distance / maxDistance * (colors.length - 1));
          if (geometry) {
            res.push({
              id,
              type: 'Feature',
              geometry,
              properties: {
                id,
                name,
                distance,
                capacite: slots,
                color: isParkings ? slots > 0 ? colors[colorIndex] : '#6c6c6c' : distance > 0 ? colors[colorIndex] : '#6c6c6c'
              }
            });
          }
          return res;
        }, [])
      });
      if (isParkings && childZones.length > 1) {
        updateCyclabilityZonesParkingLotsCountSource({
          type: 'FeatureCollection',
          features: childZones.reduce((res, _ref13) => {
            let {
              id,
              geometry,
              statsBNSC
            } = _ref13;
            const dateStatsBNSC = statsBNSC.find(_ref14 => {
              let {
                dateAsKey
              } = _ref14;
              return dateAsKey === selectedStatsKey;
            });
            const slots = dateStatsBNSC?.slots.all || 0;
            if (geometry) {
              res.push({
                id,
                type: 'Feature',
                geometry: (0, _centroid.default)(geometry).geometry,
                properties: {
                  id,
                  count: formatNumber(slots)
                }
              });
            }
            return res;
          }, [])
        });
      }
    } else if (parkings && zone) {
      const {
        id,
        geometry
      } = zone;
      if (!geometry) return;
      let minCapacity = Infinity;
      let maxCapacity = 0;
      parkings.forEach(_ref15 => {
        let {
          capacity
        } = _ref15;
        minCapacity = Math.min(minCapacity, capacity || 0);
        maxCapacity = Math.max(maxCapacity, capacity || 0);
      });
      updateCyclabilityZoneSource({
        type: 'FeatureCollection',
        features: [{
          id,
          type: 'Feature',
          geometry,
          properties: {
            id
          }
        }]
      });
      updateParkingsSource({
        type: 'FeatureCollection',
        features: parkings.map(_ref16 => {
          let {
            location,
            capacity,
            rackType,
            free,
            type
          } = _ref16;
          let radiusIndex;
          if (minCapacity === maxCapacity) radiusIndex = Math.round(radiuses.length / 2) - 1;else {
            const percent = ((capacity || 0) - minCapacity) / (maxCapacity - minCapacity);
            radiusIndex = Math.round(percent * (radiuses.length - 1));
          }
          return {
            type: 'Feature',
            geometry: location.point,
            properties: {
              free,
              rackType,
              type,
              capacity,
              color: '#0a429a',
              radius: radiuses[radiusIndex !== null ? radiusIndex : Math.floor(radiuses.length / 2)].value
            }
          };
        })
      });
    } else if (zone) {
      const {
        id,
        geometry
      } = zone;
      if (!geometry) return;
      updateCyclabilityZoneSource({
        type: 'FeatureCollection',
        features: [{
          id,
          type: 'Feature',
          geometry,
          properties: {
            id
          }
        }]
      });
    }
  }
  function displayCurrentArea(_ref17) {
    let {
      geometry
    } = _ref17;
    if (geometry) updateAreaSource({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry,
        properties: {}
      }]
    });
  }
  function clearZoneHighlight() {
    highlightedZoneTooltip.current?.remove();
    const highlightedZoneSource = getHighlightedZoneSource();
    if (highlightedZoneSource) {
      highlightedZoneSource.setData({
        type: 'FeatureCollection',
        features: []
      });
    }
    hoveredZoneId = undefined;
  }
  function clearParkingsHighlight() {
    highlightedParkingTooltip.current?.remove();
    hoveredParkingId = undefined;
  }
  function clear(keepArea) {
    if (!keepArea) clearAreaSource();
    clearCyclabilityZonesSource();
    clearCyclabilityZonesParkingLotsCountSource();
    clearCyclabilityZoneSource();
    clearParkingsSource();
    clearZoneHighlight();
    clearParkingsHighlight();
  }
  function destroy() {
    clear();
    map?.off('mousemove', cyclabilityZonesLayerId, handleMoveInZone);
    initializedRef.current = false;
    setInitialized(false);
  }
  return {
    initialized,
    init,
    update,
    displayCurrentArea,
    clear,
    destroy
  };
}