"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.userPlaceTypes = exports.UserRide = exports.UserReferenceTripOccurrence = exports.UserReferenceTrip = exports.UserPlace = exports.UserChallenge = exports.User = void 0;
var _favoriteWhite = _interopRequireDefault(require("../assets/images/places/favorite-white.svg"));
var _homeWhite = _interopRequireDefault(require("../assets/images/places/home-white.svg"));
var _workWhite = _interopRequireDefault(require("../assets/images/places/work-white.svg"));
var _icons = require("../components/icons");
var _place = require("./place");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class User {
  constructor(id, email, username, birthDate, firstName, lastName, isAdmin, isSupport, isStaff, options, parameters, created, phoneNumber, profilePicture) {
    this.id = id;
    this.email = email;
    this.username = username;
    this.birthDate = birthDate;
    this.firstName = firstName;
    this.lastName = lastName;
    this.isAdmin = isAdmin;
    this.isSupport = isSupport;
    this.isStaff = isStaff;
    this.options = options;
    this.parameters = parameters;
    this.created = created;
    this.phoneNumber = phoneNumber;
    this.profilePicture = profilePicture;
  }
  get pseudo() {
    return !!this.username ? this.username : !!this.firstName && !!this.lastName ? this.firstName + '_' + this.lastName : this.email;
  }
  get isGeovelo() {
    return this.email.indexOf('@geovelo.fr') > -1;
  }
  get isEcoCompteur() {
    return this.email.indexOf('@eco-counter.com') > -1 || this.email.indexOf('@eco-compteur.com') > -1;
  }
  clone() {
    return new User(this.id, this.email, this.username, this.birthDate, this.firstName, this.lastName, this.isAdmin, this.isSupport, this.isStaff, this.options, this.parameters, this.created, this.phoneNumber, this.profilePicture);
  }
}
exports.User = User;
const userPlaceTypes = exports.userPlaceTypes = {
  home: {
    Icon: _icons.HomeIcon,
    icon: _homeWhite.default,
    titleKey: 'commons.favorites.places.home',
    color: '#dd428d'
  },
  other: {
    Icon: _icons.FavoriteIcon,
    icon: _favoriteWhite.default,
    color: '#da6c2e'
  },
  work: {
    Icon: _icons.WorkIcon,
    icon: _workWhite.default,
    titleKey: 'commons.favorites.places.work',
    color: '#dd428d'
  }
};
class UserPlace extends _place.Place {
  constructor(id, type, title, point, address, created) {
    super(id, point, {
      primaryText: address
    });
    this.type = type;
    this.title = title;
    this.created = created;
  }
}
exports.UserPlace = UserPlace;
class UserRide {
  constructor(rideId, created) {
    this.rideId = rideId;
    this.created = created;
  }
}
exports.UserRide = UserRide;
class UserChallenge {
  constructor(challenge, progressValue, rank, leaderboardIndex, subgroupsLeaderboards) {
    this.challenge = challenge;
    this.progressValue = progressValue;
    this.rank = rank;
    this.leaderboardIndex = leaderboardIndex;
    this.subgroupsLeaderboards = subgroupsLeaderboards;
  }
}
exports.UserChallenge = UserChallenge;
class UserReferenceTrip {
  constructor(id, created, title, home, work, distanceHomeWork, distanceWorkHome, enabled) {
    this.id = id;
    this.created = created;
    this.title = title;
    this.home = home;
    this.work = work;
    this.distanceHomeWork = distanceHomeWork;
    this.distanceWorkHome = distanceWorkHome;
    this.enabled = enabled;
  }
}
exports.UserReferenceTrip = UserReferenceTrip;
class UserReferenceTripOccurrence {
  constructor(id, userReferenceTripId, created, date, day, certifiedByGeovelo, manuallyCreated, comment, direction, hasUserTrace, enabled) {
    this.id = id;
    this.userReferenceTripId = userReferenceTripId;
    this.created = created;
    this.date = date;
    this.day = day;
    this.certifiedByGeovelo = certifiedByGeovelo;
    this.manuallyCreated = manuallyCreated;
    this.comment = comment;
    this.direction = direction;
    this.hasUserTrace = hasUserTrace;
    this.enabled = enabled;
  }
}
exports.UserReferenceTripOccurrence = UserReferenceTripOccurrence;