"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.TripStep = exports.Trip = void 0;
var _maplibreGl = require("!maplibre-gl");
class TripStep {
  constructor(id, dayIndex, departureDate, arrivalDate, profile, eBike, poiAnnotationIds, departureAccomodationAnnotationId, arrivalAccomodationAnnotationId, route) {
    this.id = id;
    this.dayIndex = dayIndex;
    this.departureDate = departureDate;
    this.arrivalDate = arrivalDate;
    this.profile = profile;
    this.eBike = eBike;
    this.poiAnnotationIds = poiAnnotationIds;
    this.departureAccomodationAnnotationId = departureAccomodationAnnotationId;
    this.arrivalAccomodationAnnotationId = arrivalAccomodationAnnotationId;
    this.route = route;
  }
}
exports.TripStep = TripStep;
class Trip {
  constructor(id, title, description, departureDate, arrivalDate, nbDays, distance, profile, eBike, steps) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.departureDate = departureDate;
    this.arrivalDate = arrivalDate;
    this.nbDays = nbDays;
    this.distance = distance;
    this.profile = profile;
    this.eBike = eBike;
    this.steps = steps;
    if (steps && steps.length > 0) {
      const bounds = new _maplibreGl.LngLatBounds();
      steps.forEach(_ref => {
        let {
          route
        } = _ref;
        if (route?.geometry) {
          for (const coord of route.geometry.coordinates) {
            bounds.extend(coord);
          }
        }
      });
      this.bounds = bounds;
    }
  }
  clone() {
    return new Trip(this.id, this.title, this.description, this.departureDate?.clone() || null, this.arrivalDate?.clone() || null, this.nbDays, this.distance, this.profile, this.eBike, this.steps ? this.steps.slice() : undefined);
  }
}
exports.Trip = Trip;