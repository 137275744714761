"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bikeStationIcons = exports.BikeStation = void 0;
var _colors = require("@mui/material/colors");
var _bss0BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-0-bikes-marker.svg"));
var _bss1BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-1-bikes-marker.svg"));
var _bss10BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-10-bikes-marker.svg"));
var _bss100BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-100-bikes-marker.svg"));
var _bss11BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-11-bikes-marker.svg"));
var _bss12BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-12-bikes-marker.svg"));
var _bss13BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-13-bikes-marker.svg"));
var _bss14BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-14-bikes-marker.svg"));
var _bss15BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-15-bikes-marker.svg"));
var _bss16BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-16-bikes-marker.svg"));
var _bss17BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-17-bikes-marker.svg"));
var _bss18BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-18-bikes-marker.svg"));
var _bss19BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-19-bikes-marker.svg"));
var _bss2BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-2-bikes-marker.svg"));
var _bss20BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-20-bikes-marker.svg"));
var _bss21BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-21-bikes-marker.svg"));
var _bss22BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-22-bikes-marker.svg"));
var _bss23BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-23-bikes-marker.svg"));
var _bss24BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-24-bikes-marker.svg"));
var _bss25BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-25-bikes-marker.svg"));
var _bss26BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-26-bikes-marker.svg"));
var _bss27BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-27-bikes-marker.svg"));
var _bss28BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-28-bikes-marker.svg"));
var _bss29BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-29-bikes-marker.svg"));
var _bss3BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-3-bikes-marker.svg"));
var _bss30BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-30-bikes-marker.svg"));
var _bss31BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-31-bikes-marker.svg"));
var _bss32BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-32-bikes-marker.svg"));
var _bss33BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-33-bikes-marker.svg"));
var _bss34BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-34-bikes-marker.svg"));
var _bss35BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-35-bikes-marker.svg"));
var _bss36BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-36-bikes-marker.svg"));
var _bss37BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-37-bikes-marker.svg"));
var _bss38BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-38-bikes-marker.svg"));
var _bss39BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-39-bikes-marker.svg"));
var _bss4BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-4-bikes-marker.svg"));
var _bss40BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-40-bikes-marker.svg"));
var _bss41BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-41-bikes-marker.svg"));
var _bss42BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-42-bikes-marker.svg"));
var _bss43BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-43-bikes-marker.svg"));
var _bss44BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-44-bikes-marker.svg"));
var _bss45BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-45-bikes-marker.svg"));
var _bss46BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-46-bikes-marker.svg"));
var _bss47BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-47-bikes-marker.svg"));
var _bss48BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-48-bikes-marker.svg"));
var _bss49BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-49-bikes-marker.svg"));
var _bss5BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-5-bikes-marker.svg"));
var _bss50BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-50-bikes-marker.svg"));
var _bss51BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-51-bikes-marker.svg"));
var _bss52BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-52-bikes-marker.svg"));
var _bss53BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-53-bikes-marker.svg"));
var _bss54BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-54-bikes-marker.svg"));
var _bss55BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-55-bikes-marker.svg"));
var _bss56BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-56-bikes-marker.svg"));
var _bss57BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-57-bikes-marker.svg"));
var _bss58BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-58-bikes-marker.svg"));
var _bss59BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-59-bikes-marker.svg"));
var _bss6BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-6-bikes-marker.svg"));
var _bss60BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-60-bikes-marker.svg"));
var _bss61BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-61-bikes-marker.svg"));
var _bss62BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-62-bikes-marker.svg"));
var _bss63BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-63-bikes-marker.svg"));
var _bss64BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-64-bikes-marker.svg"));
var _bss65BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-65-bikes-marker.svg"));
var _bss66BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-66-bikes-marker.svg"));
var _bss67BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-67-bikes-marker.svg"));
var _bss68BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-68-bikes-marker.svg"));
var _bss69BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-69-bikes-marker.svg"));
var _bss7BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-7-bikes-marker.svg"));
var _bss70BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-70-bikes-marker.svg"));
var _bss71BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-71-bikes-marker.svg"));
var _bss72BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-72-bikes-marker.svg"));
var _bss73BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-73-bikes-marker.svg"));
var _bss74BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-74-bikes-marker.svg"));
var _bss75BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-75-bikes-marker.svg"));
var _bss76BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-76-bikes-marker.svg"));
var _bss77BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-77-bikes-marker.svg"));
var _bss78BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-78-bikes-marker.svg"));
var _bss79BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-79-bikes-marker.svg"));
var _bss8BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-8-bikes-marker.svg"));
var _bss80BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-80-bikes-marker.svg"));
var _bss81BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-81-bikes-marker.svg"));
var _bss82BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-82-bikes-marker.svg"));
var _bss83BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-83-bikes-marker.svg"));
var _bss84BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-84-bikes-marker.svg"));
var _bss85BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-85-bikes-marker.svg"));
var _bss86BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-86-bikes-marker.svg"));
var _bss87BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-87-bikes-marker.svg"));
var _bss88BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-88-bikes-marker.svg"));
var _bss89BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-89-bikes-marker.svg"));
var _bss9BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-9-bikes-marker.svg"));
var _bss90BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-90-bikes-marker.svg"));
var _bss91BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-91-bikes-marker.svg"));
var _bss92BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-92-bikes-marker.svg"));
var _bss93BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-93-bikes-marker.svg"));
var _bss94BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-94-bikes-marker.svg"));
var _bss95BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-95-bikes-marker.svg"));
var _bss96BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-96-bikes-marker.svg"));
var _bss97BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-97-bikes-marker.svg"));
var _bss98BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-98-bikes-marker.svg"));
var _bss99BikesMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-99-bikes-marker.svg"));
var _bssClosedStationMarker = _interopRequireDefault(require("../assets/images/bike-stations/bss-closed-station-marker.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class BikeStation {
  constructor(id, code, label, location, capacity, availableClassicBikes, availableEBikes, availableLots, providerId, updated, status, history) {
    this.id = id;
    this.code = code;
    this.label = label;
    this.location = location;
    this.capacity = capacity;
    this.availableClassicBikes = availableClassicBikes;
    this.availableEBikes = availableEBikes;
    this.availableLots = availableLots;
    this.providerId = providerId;
    this.updated = updated;
    this.status = status;
    this.history = history;
    this.availableBikesPercentage = this.capacity ? Math.round((this.availableClassicBikes + this.availableEBikes) * 100 / this.capacity) : 0;
  }
  get name() {
    return `${this.label.trim()}${this.code ? ` - ${this.code}` : ''}`;
  }
  get color() {
    if (this.status === 'closed') return _colors.grey[500];
    if (this.availableBikesPercentage >= 80) return _colors.green[500];
    if (this.availableBikesPercentage >= 60) return _colors.lightGreen[500];
    if (this.availableBikesPercentage >= 40) return _colors.amber[500];
    if (this.availableBikesPercentage >= 20) return _colors.orange[500];
    return _colors.deepOrange[500];
  }
}
exports.BikeStation = BikeStation;
const bikeStationIcons = exports.bikeStationIcons = {
  0: _bss0BikesMarker.default,
  1: _bss1BikesMarker.default,
  2: _bss2BikesMarker.default,
  3: _bss3BikesMarker.default,
  4: _bss4BikesMarker.default,
  5: _bss5BikesMarker.default,
  6: _bss6BikesMarker.default,
  7: _bss7BikesMarker.default,
  8: _bss8BikesMarker.default,
  9: _bss9BikesMarker.default,
  10: _bss10BikesMarker.default,
  11: _bss11BikesMarker.default,
  12: _bss12BikesMarker.default,
  13: _bss13BikesMarker.default,
  14: _bss14BikesMarker.default,
  15: _bss15BikesMarker.default,
  16: _bss16BikesMarker.default,
  17: _bss17BikesMarker.default,
  18: _bss18BikesMarker.default,
  19: _bss19BikesMarker.default,
  20: _bss20BikesMarker.default,
  21: _bss21BikesMarker.default,
  22: _bss22BikesMarker.default,
  23: _bss23BikesMarker.default,
  24: _bss24BikesMarker.default,
  25: _bss25BikesMarker.default,
  26: _bss26BikesMarker.default,
  27: _bss27BikesMarker.default,
  28: _bss28BikesMarker.default,
  29: _bss29BikesMarker.default,
  30: _bss30BikesMarker.default,
  31: _bss31BikesMarker.default,
  32: _bss32BikesMarker.default,
  33: _bss33BikesMarker.default,
  34: _bss34BikesMarker.default,
  35: _bss35BikesMarker.default,
  36: _bss36BikesMarker.default,
  37: _bss37BikesMarker.default,
  38: _bss38BikesMarker.default,
  39: _bss39BikesMarker.default,
  40: _bss40BikesMarker.default,
  41: _bss41BikesMarker.default,
  42: _bss42BikesMarker.default,
  43: _bss43BikesMarker.default,
  44: _bss44BikesMarker.default,
  45: _bss45BikesMarker.default,
  46: _bss46BikesMarker.default,
  47: _bss47BikesMarker.default,
  48: _bss48BikesMarker.default,
  49: _bss49BikesMarker.default,
  50: _bss50BikesMarker.default,
  51: _bss51BikesMarker.default,
  52: _bss52BikesMarker.default,
  53: _bss53BikesMarker.default,
  54: _bss54BikesMarker.default,
  55: _bss55BikesMarker.default,
  56: _bss56BikesMarker.default,
  57: _bss57BikesMarker.default,
  58: _bss58BikesMarker.default,
  59: _bss59BikesMarker.default,
  60: _bss60BikesMarker.default,
  61: _bss61BikesMarker.default,
  62: _bss62BikesMarker.default,
  63: _bss63BikesMarker.default,
  64: _bss64BikesMarker.default,
  65: _bss65BikesMarker.default,
  66: _bss66BikesMarker.default,
  67: _bss67BikesMarker.default,
  68: _bss68BikesMarker.default,
  69: _bss69BikesMarker.default,
  70: _bss70BikesMarker.default,
  71: _bss71BikesMarker.default,
  72: _bss72BikesMarker.default,
  73: _bss73BikesMarker.default,
  74: _bss74BikesMarker.default,
  75: _bss75BikesMarker.default,
  76: _bss76BikesMarker.default,
  77: _bss77BikesMarker.default,
  78: _bss78BikesMarker.default,
  79: _bss79BikesMarker.default,
  80: _bss80BikesMarker.default,
  81: _bss81BikesMarker.default,
  82: _bss82BikesMarker.default,
  83: _bss83BikesMarker.default,
  84: _bss84BikesMarker.default,
  85: _bss85BikesMarker.default,
  86: _bss86BikesMarker.default,
  87: _bss87BikesMarker.default,
  88: _bss88BikesMarker.default,
  89: _bss89BikesMarker.default,
  90: _bss90BikesMarker.default,
  91: _bss91BikesMarker.default,
  92: _bss92BikesMarker.default,
  93: _bss93BikesMarker.default,
  94: _bss94BikesMarker.default,
  95: _bss95BikesMarker.default,
  96: _bss96BikesMarker.default,
  97: _bss97BikesMarker.default,
  98: _bss98BikesMarker.default,
  99: _bss99BikesMarker.default,
  100: _bss100BikesMarker.default,
  closed: _bssClosedStationMarker.default
};