import { GeoveloTextWhiteIcon, GeoveloWhiteIcon } from '@geovelo-frontends/commons';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function Header(): JSX.Element {
  const { t } = useTranslation();

  return (
    <AppBar elevation={0} position="static">
      <Toolbar sx={{ gap: 3 }}>
        <GeoveloWhiteIcon sx={{ height: 36, width: 36 }} />
        <Typography component="div" fontSize="1.15rem" fontWeight={600} lineHeight={1.2}>
          <Typography component="h1" fontSize="inherit" lineHeight="inherit" marginBlock={0}>
            {t('indemnite_velo.app_title')}
          </Typography>
          <Box alignItems="center" component="span" display="inline-flex" gap={1}>
            {t('commons.by').toLowerCase()}
            <GeoveloTextWhiteIcon sx={{ height: '1.15rem', width: 'auto' }} />
          </Box>
        </Typography>
        <Box display={{ xs: 'none', md: 'block' }}>
          <Typography component="h2" fontSize="inherit" lineHeight="inherit" marginBlock={0}>
            {t('indemnite_velo.app_subtitle')}
          </Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
