"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toBounds = toBounds;
function toBounds(geometry) {
  let north = -90,
    east = -180,
    south = 90,
    west = 180;
  if (geometry.type === 'MultiPolygon') {
    geometry.coordinates.forEach(polygon => {
      polygon.forEach(coordinates => {
        coordinates.forEach(_ref => {
          let [lng, lat] = _ref;
          north = Math.max(north, lat);
          east = Math.max(east, lng);
          south = Math.min(south, lat);
          west = Math.min(west, lng);
        });
      });
    });
  }
  if (geometry.type === 'Polygon' || geometry.type === 'MultiLineString') {
    geometry.coordinates.forEach(coordinates => {
      coordinates.forEach(_ref2 => {
        let [lng, lat] = _ref2;
        north = Math.max(north, lat);
        east = Math.max(east, lng);
        south = Math.min(south, lat);
        west = Math.min(west, lng);
      });
    });
  }
  if (geometry.type === 'LineString') {
    geometry.coordinates.forEach(_ref3 => {
      let [lng, lat] = _ref3;
      north = Math.max(north, lat);
      east = Math.max(east, lng);
      south = Math.min(south, lat);
      west = Math.min(west, lng);
    });
  }
  return {
    north,
    east,
    south,
    west
  };
}