"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _daily = require("./daily");
Object.keys(_daily).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _daily[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _daily[key];
    }
  });
});
var _direct = require("./direct");
Object.keys(_direct).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _direct[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _direct[key];
    }
  });
});
var _safe = require("./safe");
Object.keys(_safe).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _safe[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _safe[key];
    }
  });
});
var _touristic = require("./touristic");
Object.keys(_touristic).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _touristic[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _touristic[key];
    }
  });
});