"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PoiAnnotation = void 0;
class PoiAnnotation {
  constructor(id, source, poi) {
    this.id = id;
    this.source = source;
    this.poi = poi;
  }
}
exports.PoiAnnotation = PoiAnnotation;