"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MapillaryService = void 0;
var _app = require("./app");
class MapillaryService {
  static async getImage(id) {
    try {
      return await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', `https://graph.mapillary.com/${id}?fields=thumb_256_url,geometry&access_token=${_app.AppService.environment.mapillaryAccessToken}`, true);
        request.responseType = 'json';
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
        };
        request.send();
      });
    } catch (err) {
      console.error('[MapillaryService][getImage]', err);
      throw err;
    }
  }
}
exports.MapillaryService = MapillaryService;