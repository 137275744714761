"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.countryCodes = exports.GeoaggloHighlight = exports.Geoagglo = void 0;
const countryCodes = exports.countryCodes = ['france', 'espagne', 'suisse'];
class Geoagglo {
  constructor(id, code, areaId, bounds, center, name, logo, institutionalLogo, photo, hasBSS, importance) {
    this.id = id;
    this.code = code;
    this.areaId = areaId;
    this.bounds = bounds;
    this.center = center;
    this.name = name;
    this.logo = logo;
    this.institutionalLogo = institutionalLogo;
    this.photo = photo;
    this.hasBSS = hasBSS;
    this.importance = importance;
  }
}
exports.Geoagglo = Geoagglo;
class GeoaggloHighlight {
  constructor(id, title, description, photo, url) {
    let external = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : true;
    let rideId = arguments.length > 6 && arguments[6] !== undefined ? arguments[6] : null;
    let bikeRouteId = arguments.length > 7 && arguments[7] !== undefined ? arguments[7] : null;
    let poiId = arguments.length > 8 && arguments[8] !== undefined ? arguments[8] : null;
    this.id = id;
    this.title = title;
    this.description = description;
    this.photo = photo;
    this.url = url;
    this.external = external;
    this.rideId = rideId;
    this.bikeRouteId = bikeRouteId;
    this.poiId = poiId;
  }
}
exports.GeoaggloHighlight = GeoaggloHighlight;