"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSource = useSource;
var _react = require("react");
var _maplibreGl = require("!maplibre-gl");
function useSource(map, id) {
  const featureCollection = (0, _react.useRef)();
  function addGeoJSONSource() {
    let collection = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {
      type: 'FeatureCollection',
      features: []
    };
    let options = arguments.length > 1 ? arguments[1] : undefined;
    if (!map) return;
    const source = getGeoJSONSource();
    if (source) {
      updateGeoJSONSource(collection);
      return;
    }
    map.addSource(id, {
      type: 'geojson',
      data: collection,
      ...(options || {})
    });
  }
  function getGeoJSONSource() {
    const source = map?.getSource(id);
    return source && source instanceof _maplibreGl.GeoJSONSource ? source : null;
  }
  function updateGeoJSONSource(collection) {
    const source = getGeoJSONSource();
    if (source) {
      source.setData(collection);
      if (typeof collection === 'object') featureCollection.current = {
        ...collection
      };
    }
  }
  function clearGeoJSONSource() {
    updateGeoJSONSource({
      type: 'FeatureCollection',
      features: []
    });
  }
  return {
    featureCollection,
    addGeoJSONSource,
    getGeoJSONSource,
    updateGeoJSONSource,
    clearGeoJSONSource
  };
}