"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ComputedRoute = void 0;
var _baseRoute = require("./base-route");
class ComputedRoute extends _baseRoute.BaseRoute {
  constructor(id, description, labelKey, type, wayPoints, distances, duration, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival, sections) {
    super(description, labelKey, type, wayPoints, distances, duration, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival, sections);
    this.id = id;
  }
  clone() {
    const wayPoints = [...this.wayPoints];
    const sections = [...this.sections];
    const clone = Object.assign({}, {
      ...this,
      wayPoints,
      sections
    });
    Object.setPrototypeOf(clone, ComputedRoute.prototype);
    return clone;
  }
}
exports.ComputedRoute = ComputedRoute;