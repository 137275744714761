"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gpxErrors = exports.Route = void 0;
var _baseRoute = require("./base-route");
const gpxErrors = exports.gpxErrors = {
  AlreadyExists: 'commons.gpx_errors.already_exists',
  MissingData: 'commons.gpx_errors.missing_data',
  NoMatch: 'commons.gpx_errors.no_match',
  NoSegment: 'commons.gpx_errors.no_segment',
  TooManyPoints: 'commons.gpx_errors.too_many_points'
};
class Route extends _baseRoute.BaseRoute {
  constructor(id, created, description, labelKey, type, wayPoints, distances, duration, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival, sections, computedRouteId) {
    super(description, labelKey, type, wayPoints, distances, duration, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival, sections);
    this.id = id;
    this.created = created;
    this.computedRouteId = computedRouteId;
  }
  clone() {
    const wayPoints = [...this.wayPoints];
    const sections = [...this.sections];
    const clone = Object.assign({}, {
      ...this,
      wayPoints,
      sections
    });
    Object.setPrototypeOf(clone, Route.prototype);
    return clone;
  }
}
exports.Route = Route;