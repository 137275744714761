"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.lavTrigramSlugs = exports.lavPostTagSlugs = exports.lavCategorySlugs = exports.lavActivitySlugs = void 0;
exports.parseLavCategories = parseLavCategories;
var _accomodationWhite = _interopRequireDefault(require("../assets/images/poi/accomodation-white.svg"));
var _activitiesWhite = _interopRequireDefault(require("../assets/images/poi/activities-white.svg"));
var _restaurantWhite = _interopRequireDefault(require("../assets/images/poi/restaurant-white.svg"));
var _toDoToSeeWhite = _interopRequireDefault(require("../assets/images/poi/to-do-to-see-white.svg"));
var _tourismOfficeWhite = _interopRequireDefault(require("../assets/images/poi/tourism-office-white.svg"));
var _usefulWhite = _interopRequireDefault(require("../assets/images/poi/useful-white.svg"));
var _models = require("../models");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const labelKeys = {
  bicyclePump: 'commons.poi.categories.bicycle_pump',
  chargingStation: 'commons.poi.categories.charging_station',
  drinkingWater: 'commons.poi.categories.drinking_water',
  lavAccomodation: 'commons.poi.categories.lav_accomodation',
  lavActivities: 'commons.poi.categories.lav_activities',
  lavBicycleStopingArea: 'commons.poi.categories.lav_bicycle_stopping_area',
  lavBicycleRental: 'commons.poi.categories.lav_bicycle_rental',
  lavBicycleRepair: 'commons.poi.categories.lav_bicycle_repair',
  lavBnB: 'commons.poi.categories.lav_bnb',
  lavCamping: 'commons.poi.categories.lav_camping',
  lavCampingCarArea: 'commons.poi.categories.lav_camping_car_area',
  lavCastle: 'commons.poi.categories.lav_castle',
  lavCave: 'commons.poi.categories.lav_cave',
  lavCollective: 'commons.poi.categories.lav_collective',
  lavCottage: 'commons.poi.categories.lav_cottage',
  lavFamilyActivities: 'commons.poi.categories.lav_family_activities',
  lavFood: 'commons.poi.categories.lav_food',
  lavGarden: 'commons.poi.categories.lav_garden',
  lavHotel: 'commons.poi.categories.lav_hotel',
  lavLocalProducts: 'commons.poi.categories.lav_local_products',
  lavOtherActivities: 'commons.poi.categories.lav_other_activities',
  lavPackedLunch: 'commons.poi.categories.lav_packed_lunch',
  lavResidence: 'commons.poi.categories.lav_residence',
  lavRestaurant: 'commons.poi.categories.lav_restaurant',
  lavSmallHeritage: 'commons.poi.categories.lav_small_heritage',
  lavToDoToSee: 'commons.poi.categories.lav_to_do_to_see',
  lavTourismOffice: 'commons.poi.categories.lav_tourism_office',
  lavUseful: 'commons.poi.categories.lav_useful',
  lavWaterActivities: 'commons.poi.categories.lav_water_activities',
  picnic: 'commons.poi.categories.picnic',
  toilets: 'commons.poi.categories.toilets'
};
const lavCategories = [{
  code: 'lavTourismOffice',
  color: '#795548',
  icon: _tourismOfficeWhite.default,
  children: []
}, {
  code: 'lavUseful',
  color: '#82ada9',
  icon: _usefulWhite.default,
  children: ['lavBicycleRental', 'lavBicycleRepair', 'lavBicycleStopingArea', 'bicyclePump', 'picnic', 'toilets', 'drinkingWater', 'chargingStation']
}, {
  code: 'lavAccomodation',
  color: '#2196f3',
  icon: _accomodationWhite.default,
  children: ['lavHotel', 'lavBnB', 'lavCamping', 'lavCottage', 'lavCollective', 'lavCampingCarArea', 'lavResidence']
}, {
  code: 'lavActivities',
  color: '#e91e63',
  icon: _activitiesWhite.default,
  children: ['lavWaterActivities', 'lavFamilyActivities', 'lavOtherActivities']
}, {
  code: 'lavToDoToSee',
  color: '#673ab7',
  icon: _toDoToSeeWhite.default,
  children: ['lavCastle', 'lavGarden', 'lavCave', 'lavSmallHeritage']
}, {
  code: 'lavFood',
  color: '#ffb74d',
  icon: _restaurantWhite.default,
  children: ['lavLocalProducts', 'lavRestaurant', 'lavPackedLunch']
}];
const lavTrigramSlugs = exports.lavTrigramSlugs = {
  org: 'lavTourismOffice',
  hot: 'lavHotel',
  chb: 'lavBnB',
  hpa: 'lavCamping',
  git: 'lavCottage',
  hco: 'lavCollective',
  acc: 'lavCampingCarArea',
  vil: 'lavResidence',
  deg: 'lavLocalProducts',
  res: 'lavRestaurant',
  vis: 'lavCastle',
  pna: 'lavGarden'
};
const lavCategorySlugs = exports.lavCategorySlugs = {
  caves: 'lavCave',
  'aire-darret-velo': 'lavBicycleStopingArea'
};
const lavActivitySlugs = exports.lavActivitySlugs = {
  piquenique: 'lavPackedLunch',
  eau: 'lavWaterActivities',
  jeunes: 'lavFamilyActivities',
  autres: 'lavOtherActivities',
  decouverte: 'lavSmallHeritage'
};
const lavPostTagSlugs = exports.lavPostTagSlugs = {
  'loueurs-et-depots-de-velos': 'lavBicycleRental',
  'reparation-velo': 'lavBicycleRepair',
  'accueil-velo-reparateur-de-velo': 'lavBicycleRepair'
};
function parseLavCategories(id) {
  return lavCategories.reduce((res, _ref, index) => {
    let {
      code,
      color,
      icon,
      children: _children
    } = _ref;
    const children = _children.map((childCode, childIndex) => new _models.PoiCategory(id++, childCode, labelKeys[childCode], color, icon, code, [], childIndex + 1));
    res.push(new _models.PoiCategory(id++, code, labelKeys[code], color, icon, null, children, index + 1), ...children);
    return res;
  }, []);
}