"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useMapillary = useMapillary;
var _react = require("react");
var _reactI18next = require("react-i18next");
var _mapillaryViewerMarker = _interopRequireDefault(require("../../assets/images/mapillary-viewer-marker.svg"));
var _mapillaryControl = require("../../components/map/mapillary-control");
var _services = require("../../services");
var _cancellablePromise = require("../cancellable-promise");
var _maplibreGl = require("!maplibre-gl");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const sequencesLayerId = 'mapillary-sequences';
const imagesLayerId = 'mapillary-images';
function useMapillary(theme, map, _zoom, viewerPosition, _ref) {
  let {
    onImageViewed
  } = _ref;
  const [initialized, setInitialized] = (0, _react.useState)(false);
  const zoom = (0, _react.useRef)(_zoom);
  const mapillaryControlRef = (0, _react.useRef)(new _mapillaryControl.MapillaryControl());
  const previewedImageId = (0, _react.useRef)(null);
  const previewPopup = (0, _react.useRef)(new _maplibreGl.Popup({
    className: 'map-mapillary-preview-popup',
    closeButton: false,
    maxWidth: '260px',
    anchor: 'top'
  }));
  const previewContent = (0, _react.useRef)();
  const viewerMarkerRef = (0, _react.useRef)();
  const {
    t
  } = (0, _reactI18next.useTranslation)();
  const {
    cancellablePromise,
    cancelPromises
  } = (0, _cancellablePromise.useCancellablePromise)();
  (0, _react.useEffect)(() => {
    const previewContentElement = document.createElement('div');
    previewContentElement.style.backgroundColor = 'whitesmoke';
    previewContentElement.style.backgroundPosition = 'center';
    previewContentElement.style.backgroundRepeat = 'no-repeat';
    previewContentElement.style.backgroundSize = 'cover';
    previewContentElement.style.height = '128px';
    previewContentElement.style.width = '256px';
    previewContent.current = previewContentElement;
    const viewerMarkerElement = document.createElement('div');
    viewerMarkerElement.style.backgroundImage = `url(${_mapillaryViewerMarker.default})`;
    viewerMarkerElement.style.backgroundPosition = 'center';
    viewerMarkerElement.style.backgroundRepeat = 'no-repeat';
    viewerMarkerElement.style.backgroundSize = 'contain';
    viewerMarkerElement.style.width = '32px';
    viewerMarkerElement.style.height = '32px';
    viewerMarkerRef.current = new _maplibreGl.Marker({
      element: viewerMarkerElement
    });
  }, []);
  (0, _react.useEffect)(() => {
    if (!initialized || _zoom === undefined || viewerPosition) return;
    if ((zoom.current === undefined || zoom.current < 14) && _zoom >= 14) {
      mapillaryControlRef.current.unlock();
    } else if ((zoom.current === undefined || zoom.current >= 14) && _zoom < 14) {
      mapillaryControlRef.current.lock();
      clearPreview();
      showLayers(false);
    }
    zoom.current = _zoom;
  }, [initialized, _zoom, viewerPosition]);
  (0, _react.useEffect)(() => {
    if (viewerPosition) {
      if (!map) return;
      const {
        location,
        bearing
      } = viewerPosition;
      map?.flyTo({
        center: location,
        zoom: Math.max(17, map.getZoom())
      });
      if (viewerMarkerRef.current) {
        viewerMarkerRef.current.setLngLat(location)
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        .setRotation(bearing).addTo(map);
      }
    } else {
      mapillaryControlRef.current.unlock();
      viewerMarkerRef.current?.remove();
      showLayers(false);
    }
  }, [viewerPosition]);
  function clearPreview() {
    cancelPromises();
    previewedImageId.current = null;
    previewPopup.current.remove();
    if (previewContent.current) previewContent.current.style.backgroundImage = 'none';
  }
  async function handleMove(_ref2) {
    let {
      clientX,
      clientY
    } = _ref2;
    const features = map?.queryRenderedFeatures([clientX, clientY], {
      layers: [imagesLayerId]
    }) || [];
    const feature = features.find(_ref3 => {
      let {
        sourceLayer
      } = _ref3;
      return sourceLayer === 'image';
    }) || features[0];
    if (map && feature) {
      const lngLat = map.unproject([clientX, clientY]);
      const {
        properties
      } = feature;
      const {
        id,
        image_id
      } = properties || {};
      const imageId = feature.sourceLayer === 'image' ? id : image_id;
      if (!imageId) {
        clearPreview();
        return;
      }
      previewPopup.current.setLngLat(lngLat);
      if (previewedImageId.current !== imageId) {
        clearPreview();
        previewedImageId.current = imageId;
        if (previewContent.current) previewPopup.current.setDOMContent(previewContent.current);
        try {
          const {
            thumb_256_url: image
          } = await cancellablePromise(_services.MapillaryService.getImage(imageId));
          if (previewContent.current) previewContent.current.style.backgroundImage = `url(${image})`;
        } catch (err) {
          //
        }
      }
      if (!previewPopup.current.isOpen()) previewPopup.current.addTo(map);
    } else {
      clearPreview();
    }
  }
  function showLayers() {
    let visible = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;
    map?.setLayoutProperty(sequencesLayerId, 'visibility', visible ? 'visible' : 'none').setLayoutProperty(imagesLayerId, 'visibility', visible ? 'visible' : 'none');
  }
  function onDragStart() {
    showLayers();
    map?.getContainer().addEventListener('mousemove', handleMove);
  }
  function onDragEnd() {
    map?.getContainer().removeEventListener('mousemove', handleMove);
    if (previewedImageId.current) {
      showLayers();
      onImageViewed(previewedImageId.current);
      mapillaryControlRef.current.lock();
    } else {
      showLayers(false);
      mapillaryControlRef.current.unlock();
    }
    clearPreview();
  }
  function init() {
    if (!map || initialized) return;
    map.addSource('mapillary', {
      type: 'vector',
      tiles: [`https://tiles.mapillary.com/maps/vtp/mly1_computed_public/2/{z}/{x}/{y}?access_token=${_services.AppService.environment.mapillaryAccessToken}`],
      minzoom: 6,
      maxzoom: 14
    });
    map.addLayer({
      id: sequencesLayerId,
      type: 'line',
      source: 'mapillary',
      'source-layer': 'sequence',
      layout: {
        'line-cap': 'round',
        'line-join': 'round',
        visibility: 'none'
      },
      paint: {
        'line-opacity': 0.5,
        'line-color': 'rgb(5, 203, 99)',
        'line-width': 4
      },
      filter: ['==', 'is_pano', true]
    }, 'routes');
    map.addLayer({
      id: imagesLayerId,
      type: 'circle',
      source: 'mapillary',
      'source-layer': 'image',
      layout: {
        visibility: 'none'
      },
      paint: {
        'circle-opacity': 1,
        'circle-color': 'rgb(53, 175, 109)',
        'circle-radius': 5
      },
      filter: ['==', 'is_pano', true]
    }, 'routes');
    map.addControl(mapillaryControlRef.current, 'bottom-right');
    mapillaryControlRef.current.init(theme, {
      tooFar: t('commons.mapillary.actions.zoom_more'),
      locked: t('commons.mapillary.actions.clear_viewer'),
      unlocked: t('commons.mapillary.actions.show_layers')
    }, {
      onDragStart,
      onDragEnd,
      onClear: () => onImageViewed(null)
    });
    setInitialized(true);
  }
  return {
    initialized,
    init
  };
}