"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getCyclabilityZonesPeriods = getCyclabilityZonesPeriods;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function getCyclabilityZonesPeriods(zones) {
  const countByDate = {};
  zones.forEach(_ref => {
    let {
      id,
      stats
    } = _ref;
    stats.forEach(_ref2 => {
      let {
        dateAsKey: key
      } = _ref2;
      if (!countByDate[key]) countByDate[key] = [id];else countByDate[key].push(id);
    });
  });
  const dateKeys = [];
  Object.keys(countByDate).forEach(key => {
    countByDate[key] = [...new Set(countByDate[key])];
    if (countByDate[key].length === zones.length) {
      dateKeys.push(key);
    }
  });
  dateKeys.sort((a, b) => a.localeCompare(b));
  return dateKeys.map(key => ({
    key,
    date: (0, _moment.default)(key)
  }));
}