"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportSource = void 0;
class ReportSource {
  constructor(id, code, url, title) {
    this.id = id;
    this.code = code;
    this.url = url;
    this.title = title;
  }
}
exports.ReportSource = ReportSource;