"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ComputedRouteService = void 0;
exports.parseComputedRoute = parseComputedRoute;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _route = require("./route");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class ComputedRouteService {
  static async get(id) {
    let {
      instructions,
      showPushingBikeInstructions,
      elevations,
      facilities,
      geometry,
      singleResult,
      energy,
      bikeParkings,
      roadCyclingQualities,
      trainStationRadius,
      simplifyCrossingInstructions
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      const params = [{
        key: 'instructions',
        value: instructions || false
      }, {
        key: 'show_pushing_bike_instructions',
        value: showPushingBikeInstructions || false
      }, {
        key: 'elevations',
        value: elevations || false
      }, {
        key: 'facilities',
        value: facilities || false
      }, {
        key: 'geometry',
        value: geometry || false
      }, {
        key: 'single_result',
        value: singleResult || false
      }, {
        key: 'energy',
        value: energy || false
      }, {
        key: 'bike_parkings',
        value: bikeParkings || false
      }, {
        key: 'road_cycling_qualities',
        value: roadCyclingQualities || false
      }, {
        key: 'simplify_crossing_instructions',
        value: simplifyCrossingInstructions || _app.AppService.environment.enableSimplifyCrossingInstructions
      }];
      if (trainStationRadius) {
        params.push({
          key: 'train_station_radius',
          value: trainStationRadius
        });
      }
      const data = await _http.HttpService.get('computedRoutes', `/computedroutes/${id}`, params);
      if (!data[0]) throw new Error('not found');
      return parseComputedRoute(data[0]);
    } catch (err) {
      console.error('[ComputedRouteService][get]', err);
      throw err;
    }
  }
  static async getGPX(id) {
    try {
      const data = await _http.HttpService.get('computedRoutes', `/computedroutes/${id}`, [{
        key: 'content_type',
        value: 'gpx'
      }], [], null, {
        contentType: 'application/gpx+xml',
        responseType: 'blob'
      });
      return data;
    } catch (err) {
      console.error('[ComputedRouteService][getGPX]', err);
      throw err;
    }
  }
  static async compute(search) {
    let {
      showRideItinerary,
      instructions,
      showPushingBikeInstructions,
      elevations,
      facilities,
      geometry,
      singleResult,
      energy,
      bikeParkings,
      allowWaypointsSnapping,
      trainStationRadius,
      simplifyCrossingInstructions,
      roadCyclingQualities
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      const params = [{
        key: 'show_ride_itinerary',
        value: showRideItinerary || false
      }, {
        key: 'instructions',
        value: instructions || false
      }, {
        key: 'show_pushing_bike_instructions',
        value: showPushingBikeInstructions || false
      }, {
        key: 'elevations',
        value: elevations || false
      }, {
        key: 'facilities',
        value: facilities || false
      }, {
        key: 'geometry',
        value: geometry || false
      }, {
        key: 'single_result',
        value: singleResult || false
      }, {
        key: 'energy',
        value: energy || false
      }, {
        key: 'bike_parkings',
        value: bikeParkings || false
      }, {
        key: 'allow_waypoints_snapping',
        value: allowWaypointsSnapping || false
      }, {
        key: 'simplify_crossing_instructions',
        value: simplifyCrossingInstructions || _app.AppService.environment.enableSimplifyCrossingInstructions
      }, {
        key: 'road_cycling_qualities',
        value: roadCyclingQualities || false
      }];
      if (trainStationRadius) {
        params.push({
          key: 'train_station_radius',
          value: trainStationRadius
        });
      }
      const payload = {
        ...search.toParams()
      };
      const response = await _http.HttpService.post('computedRoutes', `/computedroutes`, params, [], JSON.stringify(payload));
      return {
        response,
        computedRoutes: response.map(parseComputedRoute)
      };
    } catch (err) {
      console.error('[ComputedRouteService][compute]', err);
      throw err;
    }
  }
  static async reorder(_ref) {
    let {
      search,
      wayPoints
    } = _ref;
    try {
      let newWayPoints = [];
      let data;
      if (search) {
        const {
          waypoints,
          bikeDetails: {
            profile
          }
        } = search.toParams();
        if (waypoints.find(waypoints => !waypoints)) throw new Error('invalid way points');
        if (waypoints.length <= 2) return search.wayPoints;
        data = await _http.HttpService.post('routeReorder', `/route_reorder`, [{
          key: 'profile',
          value: profile
        }], [], JSON.stringify({
          start: waypoints[0],
          end: waypoints[waypoints.length - 1],
          steps: waypoints.slice(1, waypoints.length - 1).map((waypoint, index) => ({
            ...waypoint,
            title: `${index + 1}`
          }))
        }));
        const oldWayPoints = [...search.wayPoints];
        newWayPoints = [oldWayPoints[0]];
        data.slice(1, waypoints.length - 1).forEach(_ref2 => {
          let {
            title
          } = _ref2;
          return newWayPoints.push(oldWayPoints[parseInt(title)]);
        });
        newWayPoints.push(oldWayPoints[oldWayPoints.length - 1]);
      } else if (wayPoints) {
        if (wayPoints.find(wayPoint => !wayPoint)) throw new Error('invalid way points');
        if (wayPoints.length <= 2) return wayPoints;
        data = await _http.HttpService.post('routeReorder', `/route_reorder`, [], [], JSON.stringify({
          start: {
            longitude: wayPoints[0]?.point.coordinates[0],
            latitude: wayPoints[0]?.point.coordinates[1],
            title: wayPoints[0]?.address
          },
          end: {
            longitude: wayPoints[wayPoints.length - 1]?.point.coordinates[0],
            latitude: wayPoints[wayPoints.length - 1]?.point.coordinates[1],
            title: wayPoints[wayPoints.length - 1]?.address
          },
          steps: wayPoints.slice(1, wayPoints.length - 1).map((waypoint, index) => ({
            longitude: waypoint?.point.coordinates[0],
            latitude: waypoint?.point.coordinates[1],
            title: `${index + 1}`
          }))
        }));
        const oldWayPoints = [...wayPoints];
        newWayPoints = [oldWayPoints[0]];
        data.slice(1, wayPoints.length - 1).forEach(_ref3 => {
          let {
            title
          } = _ref3;
          return newWayPoints.push(oldWayPoints[parseInt(title)]);
        });
        newWayPoints.push(oldWayPoints[oldWayPoints.length - 1]);
      }
      return newWayPoints;
    } catch (err) {
      console.error('[ComputedRouteService][reorder]', err);
      throw err;
    }
  }
}
exports.ComputedRouteService = ComputedRouteService;
function parseComputedRoute(_ref4) {
  let {
    id,
    description,
    title,
    type,
    waypoints,
    distances,
    duration,
    estimatedDatetimeOfDeparture,
    estimatedDatetimeOfArrival,
    sections
  } = _ref4;
  let prevWayPointIndex = 0;
  return new _models.ComputedRoute(id, description, _route.routeLabelKeys[title], _route.routeTypes[type], waypoints.map(_route.parseWayPoint), distances, duration, (0, _moment.default)(estimatedDatetimeOfDeparture), (0, _moment.default)(estimatedDatetimeOfArrival), sections.map((sectionData, index) => {
    const section = (0, _route.parseSection)(sectionData, index, prevWayPointIndex);
    prevWayPointIndex += section.wayPointsIndices.length - 2;
    return section;
  }));
}