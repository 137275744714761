"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerUser = void 0;
class PartnerUser {
  constructor(id, created, email, username, last_activity, isAdmin, permissions) {
    this.id = id;
    this.created = created;
    this.email = email;
    this.username = username;
    this.last_activity = last_activity;
    this.isAdmin = isAdmin;
    this.permissions = permissions;
  }
}
exports.PartnerUser = PartnerUser;