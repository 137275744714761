"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DirectRoute = void 0;
class DirectRoute {
  constructor(distance, duration, elevation_gain, elevation_loss, geometry, weight) {
    this.distance = distance;
    this.duration = duration;
    this.elevation_gain = elevation_gain;
    this.elevation_loss = elevation_loss;
    this.geometry = geometry;
    this.weight = weight;
  }
}
exports.DirectRoute = DirectRoute;