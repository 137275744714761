"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.publicationStatuses = exports.backendPublicationStatuses = void 0;
const publicationStatuses = exports.publicationStatuses = {
  UNPUBLISHED: 'unpublished',
  WAITING_FOR_APPROVAL: 'waitingForApproval',
  PUBLISHED: 'published'
};
const backendPublicationStatuses = exports.backendPublicationStatuses = {
  unpublished: 'UNPUBLISHED',
  waitingForApproval: 'WAITING_FOR_APPROVAL',
  published: 'PUBLISHED'
};