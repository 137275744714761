"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.routeReportsLayerId = exports.routeReportSelectedLayerId = void 0;
exports.useRouteReports = useRouteReports;
var _colors = require("@mui/material/colors");
var _styles = require("@mui/material/styles");
var _center = _interopRequireDefault(require("@turf/center"));
var _react = require("react");
var _layers = require("./layers");
var _source = require("./source");
var _maplibreGl = require("!maplibre-gl");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const routeReportsSourceId = 'route-reports';
const routeReportsLayerId = exports.routeReportsLayerId = 'route-reports';
const routeReportSelectedSourceId = 'selected-route-reports';
const routeReportSelectedLayerId = exports.routeReportSelectedLayerId = 'selected-route-reports';
function useRouteReports(map) {
  let {
    onClick
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const {
    palette
  } = (0, _styles.useTheme)();
  const initialized = (0, _react.useRef)(false);
  const markers = (0, _react.useRef)({});
  const {
    addGeoJSONSource: addRouteReportsSource,
    updateGeoJSONSource: updateRouteReportsSource,
    clearGeoJSONSource: clearRouteReportsSource
  } = (0, _source.useSource)(map, routeReportsSourceId);
  const {
    addGeoJSONSource: addRouteReportSelectedSource,
    updateGeoJSONSource: updateRouteReportSelectedSource,
    clearGeoJSONSource: clearRouteReportSelectedSource
  } = (0, _source.useSource)(map, routeReportSelectedSourceId);
  const {
    addCircleLayer
  } = (0, _layers.useLayers)(map);
  function init() {
    if (!map || initialized.current) return;
    addRouteReportsSource();
    addCircleLayer(routeReportsLayerId, routeReportsSourceId, {
      'circle-radius': 5,
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1,
      'circle-color': ['get', 'color'],
      'circle-stroke-color': '#fff'
    });
    map.on('mouseenter', routeReportsLayerId, event => {
      if (event.defaultPrevented) return;
      event.originalEvent.preventDefault();
      event.preventDefault();
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('click', routeReportsLayerId, event => {
      if (event.defaultPrevented) return;
      event.originalEvent.preventDefault();
      event.preventDefault();
      const {
        features
      } = event;
      const id = features?.[0].id;
      if (typeof id !== 'number') return;
      onClick?.(id);
    });
    map.on('mouseleave', routeReportsLayerId, () => {
      map.getCanvas().style.cursor = '';
    });
    addRouteReportSelectedSource();
    map.addLayer({
      id: routeReportSelectedLayerId,
      type: 'line',
      source: routeReportSelectedSourceId,
      paint: {
        'line-color': ['get', 'color'],
        'line-width': ['get', 'width']
      }
    }, 'labels');
    initialized.current = true;
  }
  function addMarkers(report) {
    if (!map) return;
    const {
      wayPoints
    } = report;
    if (wayPoints) {
      wayPoints.forEach((point, index) => {
        const element = document.createElement('div');
        element.style.border = '2px solid #fff';
        element.style.borderRadius = '50%';
        element.style.backgroundColor = index === 0 ? '#2ac682' : index === wayPoints.length - 1 ? '#dd428d' : '#326ac2';
        element.style.backgroundPosition = 'center';
        element.style.backgroundRepeat = 'no-repeat';
        element.style.backgroundSize = '20px';
        element.style.width = '12px';
        element.style.height = '12px';
        element.style.cursor = 'pointer';
        const marker = new _maplibreGl.Marker({
          element
        }).setLngLat({
          lat: point.coordinates[1],
          lng: point.coordinates[0]
        }).addTo(map);
        markers.current[index] = {
          id: index,
          marker
        };
      });
    }
  }
  function update(routeReports) {
    if (!map) return;
    clear();
    const features = routeReports.reduce((res, _ref) => {
      let {
        id,
        wayPoints,
        status
      } = _ref;
      if (!wayPoints) return res;
      res.push({
        id,
        type: 'Feature',
        geometry: (0, _center.default)({
          type: 'Polygon',
          coordinates: [wayPoints.map(_ref2 => {
            let {
              coordinates
            } = _ref2;
            return coordinates;
          })]
        }).geometry,
        properties: {
          color: status === 'OPEN' ? _colors.red[500] : _colors.green[500]
        }
      });
      return res;
    }, []);
    updateRouteReportsSource({
      type: 'FeatureCollection',
      features: features
    });
  }
  function select(routeReport) {
    clear();
    const {
      bounds,
      geometry,
      geometryDrawn
    } = routeReport;
    updateRouteReportSelectedSource({
      type: 'FeatureCollection',
      features: [{
        type: 'Feature',
        geometry,
        properties: {
          color: '#fff',
          width: 7
        }
      }, {
        type: 'Feature',
        geometry,
        properties: {
          color: '#7E57C2',
          width: 5
        }
      }, {
        type: 'Feature',
        geometry: geometryDrawn,
        properties: {
          color: palette.secondary.main,
          width: 5
        }
      }]
    });
    addMarkers(routeReport);
    if (bounds) map?.fitBounds(bounds, {
      padding: 50
    });
  }
  function clear() {
    Object.values(markers.current).forEach(_ref3 => {
      let {
        id,
        marker
      } = _ref3;
      marker.remove();
      delete markers.current[id];
    });
    clearRouteReportSelectedSource();
    clearRouteReportsSource();
  }
  return {
    initialized: initialized.current,
    init,
    update,
    select,
    clear
  };
}