"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.fmdConfigTypes = exports.fmdConfigMap = exports.days = exports.FMDConfig = void 0;
var _icons = require("../components/icons");
const fmdConfigTypes = exports.fmdConfigTypes = ['distance', 'daily', 'fixed'];
const days = exports.days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
const fmdConfigMap = exports.fmdConfigMap = {
  distance: {
    key: 'distance',
    Icon: _icons.DistanceIcon,
    labelKey: 'commons.fmd.types.distance',
    explanationsLabelKey: 'commons.fmd.explanations.distance',
    rateLabelKey: 'commons.fmd.rate_label.distance',
    rateUnitKey: 'commons.fmd.rate_unit.distance'
  },
  daily: {
    key: 'daily',
    Icon: _icons.CalendarIcon,
    labelKey: 'commons.fmd.types.daily',
    explanationsLabelKey: 'commons.fmd.explanations.daily',
    rateLabelKey: 'commons.fmd.rate_label.daily',
    rateUnitKey: 'commons.fmd.rate_unit.daily'
  },
  fixed: {
    key: 'fixed',
    Icon: _icons.LockOutlinedIcon,
    labelKey: 'commons.fmd.types.fixed',
    explanationsLabelKey: 'commons.fmd.explanations.fixed',
    rateLabelKey: 'commons.fmd.rate_label.fixed'
  }
};
class FMDConfig {
  constructor(id, partner, code, type, compensationRate, maxCompensationPerYear, fixedFMDLevels, minDaysCountPerMonth, certificateValidationLimitDay, compensableDays, enableReceiptionOfCertificates, maxManuallyDeclaredJourneysRatio) {
    this.id = id;
    this.partner = partner;
    this.code = code;
    this.type = type;
    this.compensationRate = compensationRate;
    this.maxCompensationPerYear = maxCompensationPerYear;
    this.fixedFMDLevels = fixedFMDLevels;
    this.minDaysCountPerMonth = minDaysCountPerMonth;
    this.certificateValidationLimitDay = certificateValidationLimitDay;
    this.compensableDays = compensableDays;
    this.enableReceiptionOfCertificates = enableReceiptionOfCertificates;
    this.maxManuallyDeclaredJourneysRatio = maxManuallyDeclaredJourneysRatio;
  }
}
exports.FMDConfig = FMDConfig;