"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AuthService = void 0;
var _http = require("./http");
var _user = require("./user");
class AuthService {
  static async getAndStoreUser(_ref) {
    let {
      authorizationToken,
      userId
    } = _ref;
    let remember = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
    const user = await _user.UserService.getUser(userId);
    if (!user) throw new Error('user not found');
    _user.UserService.currentUser = user;
    try {
      if (remember) {
        localStorage.setItem('authorization_token', authorizationToken);
        localStorage.setItem('user_id', `${userId}`);
      } else {
        sessionStorage.setItem('authorization_token', authorizationToken);
        sessionStorage.setItem('user_id', `${userId}`);
      }
    } catch {
      console.error('localStorage access is denied');
    }
    return user;
  }
  static removeStoredUser() {
    try {
      localStorage.removeItem('impersonate_authorization_token');
      localStorage.removeItem('impersonate_user_id');
      localStorage.removeItem('authorization_token');
      localStorage.removeItem('user_id');
      sessionStorage.removeItem('authorization_token');
      sessionStorage.removeItem('user_id');
    } catch {
      console.error('localStorage access is denied');
    }
  }
  static async signIn(_ref2) {
    let {
      provider,
      token,
      language,
      remember,
      expectedStatus
    } = _ref2;
    try {
      const data = await _http.HttpService.post('authentication', `/authentication/${provider}`, [], [{
        key: 'Authentication',
        value: token
      }, {
        key: 'Accept-Language',
        value: _user.languageCodes[language]
      }], null, {
        setAuthorizationToken: true,
        expectedStatus
      });
      return AuthService.getAndStoreUser(data, remember);
    } catch (err) {
      AuthService.removeStoredUser();
      throw err;
    }
  }
  static async register(email, password, language) {
    try {
      const data = await _http.HttpService.post('register', '/register', [], [{
        key: 'Accept-Language',
        value: _user.languageCodes[language]
      }], JSON.stringify({
        email,
        password
      }), {
        setAuthorizationToken: true
      });
      return AuthService.getAndStoreUser(data);
    } catch (err) {
      console.error('[AuthService][register]', err);
      AuthService.removeStoredUser();
      throw err;
    }
  }
  static async sendResetPasswordEmail(email) {
    try {
      await _http.HttpService.post('sendResetPasswordEmail', '/password/reset_token', [], [], JSON.stringify({
        email
      }));
      return true;
    } catch (err) {
      console.error('[AuthService][sendResetPasswordEmail]', err);
      throw err;
    }
  }
  static async resetPassword(_token, password) {
    try {
      const token = btoa(encodeURIComponent(_token + ';' + password).replace(/%([0-9A-F]{2})/g, function () {
        return String.fromCharCode(parseInt(`0x${arguments.length <= 1 ? undefined : arguments[1]}`, 16));
      }));
      await _http.HttpService.post('resetPassword', '/password/reset', [], [{
        key: 'Authentication',
        value: token
      }]);
      return true;
    } catch (err) {
      console.error('[AuthService][resetPassword]', err);
      throw err;
    }
  }
  static async signInWithEmail(email, password) {
    let remember = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    let language = arguments.length > 3 ? arguments[3] : undefined;
    try {
      if (!email || !password) throw new Error('missing email or password');
      const token = btoa(encodeURIComponent(email + ';' + password).replace(/%([0-9A-F]{2})/g, function () {
        return String.fromCharCode(parseInt(`0x${arguments.length <= 1 ? undefined : arguments[1]}`, 16));
      }));
      const user = await this.signIn({
        provider: 'geovelo',
        token,
        language,
        remember,
        expectedStatus: 200
      });
      return user;
    } catch (err) {
      console.error('[AuthService][signInWithEmail]', err);
      throw err;
    }
  }
  static async signInWithProvider(provider, token) {
    let remember = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : true;
    let language = arguments.length > 3 ? arguments[3] : undefined;
    try {
      const user = await this.signIn({
        provider,
        token,
        language,
        remember
      });
      return user;
    } catch (err) {
      console.error('[AuthService][signInWithProvider]', err);
      throw err;
    }
  }
  static async signOut() {
    try {
      await _http.HttpService.delete('authentication', '/authentication');
      try {
        localStorage.removeItem('impersonate_authorization_token');
        localStorage.removeItem('impersonate_user_id');
        localStorage.removeItem('authorization_token');
        localStorage.removeItem('user_id');
        sessionStorage.removeItem('authorization_token');
        sessionStorage.removeItem('user_id');
      } catch {
        console.error('localStorage access is denied');
      }
      _http.HttpService.authorizationToken = null;
      _user.UserService.currentUser = null;
      if ('google' in window) google.accounts.id.disableAutoSelect();
      return true;
    } catch (err) {
      console.error('[AuthService][signOut]', err);
      throw err;
    }
  }
}
exports.AuthService = AuthService;