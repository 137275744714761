"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Event = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _environment = require("../environment");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class Event {
  allowedParticipantsTypes = [];
  constructor(id, title, startDate, endDate, challengeTemplate, allowedContractCodes, _allowedParticipantsTypes, numberOfParticipants, sumOfProgressValues) {
    this.id = id;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.challengeTemplate = challengeTemplate;
    this.allowedContractCodes = allowedContractCodes;
    this._allowedParticipantsTypes = _allowedParticipantsTypes;
    this.numberOfParticipants = numberOfParticipants;
    this.sumOfProgressValues = sumOfProgressValues;
    const now = (0, _moment.default)();
    if (endDate.isBefore(now)) this.period = 'past';else if (startDate.isAfter(now)) this.period = 'future';else this.period = 'current';
    if (_allowedParticipantsTypes.includes('COMPANY') || allowedContractCodes.length > 0 && allowedContractCodes.find(key => _environment.companyContractCodes.includes(key))) {
      this.allowedParticipantsTypes.push('companies');
    }
    if (_allowedParticipantsTypes.includes('CITY') || allowedContractCodes.length > 0 && allowedContractCodes.find(key => !_environment.companyContractCodes.includes(key))) {
      this.allowedParticipantsTypes.push('territories');
    }
  }
  get isOnlyForCompanies() {
    return this.allowedParticipantsTypes.length === 1 && this.allowedParticipantsTypes.includes('companies');
  }
  get isForCompanies() {
    return this.allowedParticipantsTypes.length > 0 && this.allowedParticipantsTypes.includes('companies');
  }
  get isOnlyForTerritories() {
    return this.allowedParticipantsTypes.length === 1 && this.allowedParticipantsTypes.includes('territories');
  }
  get isForTerritories() {
    return this.allowedParticipantsTypes.length > 0 && this.allowedParticipantsTypes.includes('territories');
  }
}
exports.Event = Event;