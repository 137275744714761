"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _accomodation = require("./accomodation");
Object.keys(_accomodation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _accomodation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _accomodation[key];
    }
  });
});
var _activities = require("./activities");
Object.keys(_activities).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _activities[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _activities[key];
    }
  });
});
var _antics = require("./antics");
Object.keys(_antics).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _antics[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _antics[key];
    }
  });
});
var _beach = require("./beach");
Object.keys(_beach).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _beach[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _beach[key];
    }
  });
});
var _bicycleHome = require("./bicycle-home");
Object.keys(_bicycleHome).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bicycleHome[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _bicycleHome[key];
    }
  });
});
var _bicycleRental = require("./bicycle-rental");
Object.keys(_bicycleRental).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bicycleRental[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _bicycleRental[key];
    }
  });
});
var _bicycleRepair = require("./bicycle-repair");
Object.keys(_bicycleRepair).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _bicycleRepair[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _bicycleRepair[key];
    }
  });
});
var _camping = require("./camping");
Object.keys(_camping).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _camping[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _camping[key];
    }
  });
});
var _castle = require("./castle");
Object.keys(_castle).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _castle[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _castle[key];
    }
  });
});
var _chargingStation = require("./charging-station");
Object.keys(_chargingStation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _chargingStation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _chargingStation[key];
    }
  });
});
var _collective = require("./collective");
Object.keys(_collective).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _collective[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _collective[key];
    }
  });
});
var _fortification = require("./fortification");
Object.keys(_fortification).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _fortification[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _fortification[key];
    }
  });
});
var _fountain = require("./fountain");
Object.keys(_fountain).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _fountain[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _fountain[key];
    }
  });
});
var _garden = require("./garden");
Object.keys(_garden).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _garden[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _garden[key];
    }
  });
});
var _industry = require("./industry");
Object.keys(_industry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _industry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _industry[key];
    }
  });
});
var _museum = require("./museum");
Object.keys(_museum).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _museum[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _museum[key];
    }
  });
});
var _naturalSite = require("./natural-site");
Object.keys(_naturalSite).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _naturalSite[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _naturalSite[key];
    }
  });
});
var _picnic = require("./picnic");
Object.keys(_picnic).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _picnic[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _picnic[key];
    }
  });
});
var _pump = require("./pump");
Object.keys(_pump).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _pump[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _pump[key];
    }
  });
});
var _religious = require("./religious");
Object.keys(_religious).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _religious[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _religious[key];
    }
  });
});
var _remarkableCity = require("./remarkable-city");
Object.keys(_remarkableCity).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _remarkableCity[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _remarkableCity[key];
    }
  });
});
var _restaurant = require("./restaurant");
Object.keys(_restaurant).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _restaurant[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _restaurant[key];
    }
  });
});
var _ruins = require("./ruins");
Object.keys(_ruins).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ruins[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ruins[key];
    }
  });
});
var _smallHeritage = require("./small-heritage");
Object.keys(_smallHeritage).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _smallHeritage[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _smallHeritage[key];
    }
  });
});
var _sport = require("./sport");
Object.keys(_sport).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _sport[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _sport[key];
    }
  });
});
var _thermalStation = require("./thermal-station");
Object.keys(_thermalStation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _thermalStation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _thermalStation[key];
    }
  });
});
var _toDoToSee = require("./to-do-to-see");
Object.keys(_toDoToSee).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _toDoToSee[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _toDoToSee[key];
    }
  });
});
var _toilets = require("./toilets");
Object.keys(_toilets).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _toilets[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _toilets[key];
    }
  });
});
var _tourismOffice = require("./tourism-office");
Object.keys(_tourismOffice).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _tourismOffice[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _tourismOffice[key];
    }
  });
});
var _useful = require("./useful");
Object.keys(_useful).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _useful[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _useful[key];
    }
  });
});
var _viewPoint = require("./view-point");
Object.keys(_viewPoint).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _viewPoint[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _viewPoint[key];
    }
  });
});
var _waterPoint = require("./water-point");
Object.keys(_waterPoint).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _waterPoint[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _waterPoint[key];
    }
  });
});