"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePartnerLayers = usePartnerLayers;
var _react = require("react");
var _layers = require("./layers");
var _parking = require("./parking");
var _pois = require("./pois");
var _routing = require("./routing");
var _source = require("./source");
var _maplibreGl = require("!maplibre-gl");
const sourceId = 'partner-layers';
const layerId = 'partner-layers';
const dashedLayerId = 'dashed-partner-layers';
function usePartnerLayers(map) {
  const [initialized, setInitialized] = (0, _react.useState)(false);
  const popup = (0, _react.useRef)(new _maplibreGl.Popup({
    className: 'map-tooltip',
    closeButton: false
  }));
  const {
    addGeoJSONSource,
    updateGeoJSONSource
  } = (0, _source.useSource)(map, sourceId);
  const {
    addLineLayer
  } = (0, _layers.useLayers)(map);
  function onMouseMove(event, layers) {
    if (!map || event.defaultPrevented || event.originalEvent.defaultPrevented) return;
    event.originalEvent.preventDefault();
    event.preventDefault();
    const {
      point,
      lngLat,
      features
    } = event;
    if (map.queryRenderedFeatures(point).find(_ref => {
      let {
        layer: {
          id: layerId
        }
      } = _ref;
      return [_routing.routesLayerId, _routing.wayPointsLayerId, _routing.arrivalLayerId, _pois.poisLayerId, _parking.parkingLotsLayerId].indexOf(layerId) !== -1;
    }) || !features || !features[0] || !features[0].properties) {
      popup.current.remove();
      return;
    }
    const layer = layers.find(_ref2 => {
      let {
        id
      } = _ref2;
      return id === features[0].properties?.id;
    });
    if (layer) popup.current.setLngLat(lngLat).setHTML(`${layer.name}`).addTo(map);
  }
  function init(layers) {
    if (!map || initialized) return;
    addGeoJSONSource();
    addLineLayer(layerId, sourceId, {
      'line-join': 'round',
      'line-cap': 'round'
    }, {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width']
    }, {
      filter: ['!=', 'dashed', true],
      before: 'partnerLayers'
    });
    addLineLayer(dashedLayerId, sourceId, {
      'line-join': 'round',
      'line-cap': 'round'
    }, {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width'],
      'line-dasharray': [1, 2]
    }, {
      filter: ['==', 'dashed', true],
      before: 'partnerLayers'
    });
    map.on('mousemove', layerId, event => onMouseMove(event, layers));
    map.on('mousemove', dashedLayerId, event => onMouseMove(event, layers));
    map.on('mouseleave', layerId, () => popup.current.remove());
    map.on('mouseleave', dashedLayerId, () => popup.current.remove());
    setInitialized(true);
  }
  function update(layers) {
    updateGeoJSONSource({
      type: 'FeatureCollection',
      features: layers.reduce((res, _ref3) => {
        let {
          collection: {
            features
          }
        } = _ref3;
        res.push(...features);
        return res;
      }, [])
    });
  }
  return {
    initialized,
    init,
    update
  };
}