"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.osrmWayPointsLayerId = exports.osrmRoutesLayerId = void 0;
exports.useOSRMRouting = useOSRMRouting;
var _colors = require("@mui/material/colors");
var _react = require("react");
var _utils = require("../../utils");
var _layers = require("./layers");
var _osrmRoutingStyle = require("./osrm-routing-style");
var _source = require("./source");
const routesSourceId = 'routes';
const osrmRoutesLayerId = exports.osrmRoutesLayerId = 'routes';
const wayPointsSourceId = 'way-points';
const osrmWayPointsLayerId = exports.osrmWayPointsLayerId = 'way-points';
const draggedRouteLayerId = 'dragged-route';
const trainRouteLayerId = 'train-route';
function useOSRMRouting(map) {
  let {
    onContextMenuOpen,
    onWayPointsUpdated
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const [initialized, setInitialized] = (0, _react.useState)(false);
  const draggedWayPointIndex = (0, _react.useRef)(null);
  const wayPointMoved = (0, _react.useRef)(false);
  const wayPoints = (0, _react.useRef)([]);
  const {
    featureCollection: routeSourceCollection,
    addGeoJSONSource: addRoutesSource,
    updateGeoJSONSource: updateRouteSource,
    clearGeoJSONSource: clearRouteSource
  } = (0, _source.useSource)(map, routesSourceId);
  const {
    addGeoJSONSource: addWayPointsSource,
    updateGeoJSONSource: updateWayPointsSource
  } = (0, _source.useSource)(map, wayPointsSourceId);
  const {
    addCircleLayer,
    addLineLayer
  } = (0, _layers.useLayers)(map);
  function handleContextMenuOpen(event, pointClickedId) {
    event.preventDefault();
    if (onContextMenuOpen) onContextMenuOpen({
      point: [event.point.x, event.point.y],
      position: event.lngLat,
      pointClickedId
    });
  }
  function init() {
    if (!map || initialized) return;
    addWayPointsSource();
    addRoutesSource();
    addCircleLayer(osrmWayPointsLayerId, wayPointsSourceId, {
      'circle-radius': 6,
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1,
      'circle-color': ['get', 'color'],
      'circle-stroke-color': '#fff'
    }, {
      filter: ['==', 'wayPoint', true]
    });
    addLineLayer(osrmRoutesLayerId, routesSourceId, {
      'line-join': 'round',
      'line-cap': 'round'
    }, {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width']
    }, {
      filter: ['all', ['!=', 'dragged', true], ['!=', 'dashArray', true]]
    });
    addLineLayer(trainRouteLayerId, routesSourceId, {
      'line-join': 'round',
      'line-cap': 'round'
    }, {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width'],
      'line-dasharray': [0.5, 2]
    }, {
      filter: ['all', ['!=', 'dragged', true], ['==', 'dashArray', true]]
    });
    addLineLayer(draggedRouteLayerId, routesSourceId, {
      'line-join': 'round',
      'line-cap': 'round'
    }, {
      'line-color': ['get', 'color'],
      'line-width': ['get', 'width'],
      'line-dasharray': [1, 1]
    }, {
      filter: ['==', 'dragged', true]
    });
    map.on('contextmenu', osrmWayPointsLayerId, event => {
      event.preventDefault();
      handleContextMenuOpen(event, event.features?.[0].properties?.index);
    });
    map.on('mousedown', osrmWayPointsLayerId, handleWayPointMouseDown);
    map.on('contextmenu', event => {
      if (event.defaultPrevented) return;
      handleContextMenuOpen(event);
    });
    setInitialized(true);
  }
  function updateWayPoints(_wayPoints) {
    const features = [];
    wayPoints.current = _wayPoints;
    wayPoints.current.forEach((wayPoint, index) => {
      if (wayPoint) {
        features.push({
          type: 'Feature',
          geometry: wayPoint.point,
          properties: {
            index,
            color: index === 0 ? _colors.green[500] : index === wayPoints.current.length - 1 ? _colors.red[500] : _colors.purple[500],
            wayPoint: true,
            movable: true
          }
        });
      }
    });
    updateWayPointsSource({
      type: 'FeatureCollection',
      features
    });
  }
  function handleWayPointMouseDown(event) {
    if (event.originalEvent.button !== 0) return;
    const feature = event.features?.[0];
    if (!map) return;
    event.preventDefault();
    map.getCanvas().style.cursor = 'grab';
    draggedWayPointIndex.current = feature?.properties?.index;
    map.on('mousemove', handleMove);
    map.once('mouseup', handleDrop);
  }
  function handleMove(_ref) {
    let {
      lngLat
    } = _ref;
    if (!map || draggedWayPointIndex.current === null) return;
    map.getCanvas().style.cursor = 'grabbing';
    wayPointMoved.current = true;
    const {
      wayPoints: _wayPoints,
      feature: preview
    } = (0, _utils.getDraggedRoutePreview)(wayPoints.current, draggedWayPointIndex.current, lngLat);
    wayPoints.current = _wayPoints;
    updateWayPoints(_wayPoints);
    showPreview(preview);
  }
  function handleDrop(event) {
    event.preventDefault();
    if (!map) return;
    map.getCanvas().style.cursor = 'pointer';
    if (wayPointMoved.current) {
      onWayPointsUpdated?.(wayPoints.current);
    }
    wayPointMoved.current = false;
    map.off('mousemove', handleMove);
  }
  function showPreview(preview) {
    let routeIndexesToKeep = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
    updateRouteSource({
      type: 'FeatureCollection',
      features: [...(routeSourceCollection.current?.features.filter(_ref2 => {
        let {
          properties: {
            index
          }
        } = _ref2;
        return routeIndexesToKeep.indexOf(index) > -1;
      }) || []), {
        ...preview,
        properties: {
          ...preview.properties,
          color: '#fff',
          width: 9
        }
      }, {
        ...preview,
        properties: {
          ...preview.properties,
          color: _colors.purple[500],
          width: 5,
          dragged: true
        }
      }]
    });
  }
  function updateTiles(profile, calculator, displayedTilesEnv, customCalculatorUrl, displayedTilesType, turnsDisplayed) {
    if (!map) return;
    const layers = (0, _osrmRoutingStyle.getOSRMRoutingLayers)(displayedTilesType, turnsDisplayed);
    layers.forEach(_ref3 => {
      let {
        id
      } = _ref3;
      if (map.getLayer(id)) map.removeLayer(id);
    });
    if (map.getSource('osrm')) map.removeSource('osrm');
    if (displayedTilesEnv === null || displayedTilesEnv === 'custom' && !customCalculatorUrl) return;
    map.addSource('osrm', {
      type: 'vector',
      tiles: displayedTilesEnv === 'custom' ? [`${customCalculatorUrl}/tile/v1/${profile}/tile({x},{y},{z}).mvt`] : [`https://routing-${calculator}-${profile}-${displayedTilesEnv}.geovelo.fr/tile/tile({x},{y},{z}).mvt`]
    });
    layers.forEach(layer => map.addLayer(layer, osrmRoutesLayerId));
  }
  function showRoutes(routes) {
    if (!map || !routes) return;
    const features = [];
    if (routes[0] && routes[0].geometry) {
      features.push({
        type: 'Feature',
        geometry: routes[0].geometry,
        properties: {
          index: 0,
          width: 5,
          color: _colors.common.black
        }
      });
    }
    if (routes[1] && routes[1].geometry) {
      features.push({
        type: 'Feature',
        geometry: routes[1].geometry,
        properties: {
          index: 1,
          width: 3,
          color: _colors.lightBlue[500]
        }
      });
    }
    if (routes[2] && routes[2].geometry) {
      features.push({
        type: 'Feature',
        geometry: routes[2].geometry,
        properties: {
          index: 2,
          width: 2,
          color: _colors.purple[500]
        }
      });
    }
    updateRouteSource({
      type: 'FeatureCollection',
      features
    });
  }
  function clearRoutes() {
    clearRouteSource();
  }
  function clear() {
    clearRoutes();
    updateWayPoints([]);
    if (!map) return;
    map.off('mousedown', osrmWayPointsLayerId, handleWayPointMouseDown);
    map.off('contextmenu', handleContextMenuOpen);
  }
  return {
    initialized,
    init,
    updateWayPoints,
    updateTiles,
    showRoutes,
    clearRoutes,
    clear
  };
}