"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BikeStationService = void 0;
exports.parseBikeStation = parseBikeStation;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class BikeStationService {
  static async getBikeStations() {
    let {
      ids,
      bounds
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const queryParams = [];
      if (ids) queryParams.push(...ids.map(id => ({
        key: 'ids[]',
        value: id
      })));else if (bounds) {
        const {
          north,
          east,
          south,
          west
        } = bounds;
        queryParams.push({
          key: 'north',
          value: north
        }, {
          key: 'east',
          value: east
        }, {
          key: 'south',
          value: south
        }, {
          key: 'west',
          value: west
        });
      } else throw new Error('missing query params');
      const data = await _http.HttpService.get('bikeStations', `/bikestations`, queryParams);
      const bikeStations = data.map(parseBikeStation);
      return bikeStations;
    } catch (err) {
      console.error('[BikeStationService][getBikeStations]', err);
      throw err;
    }
  }
  static async getBikeStation(id) {
    let {
      history
    } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
    try {
      const queryParams = [];
      if (history) queryParams.push({
        key: 'history',
        value: true
      });
      const data = await _http.HttpService.get('bikeStations', `/bikestations/${id}`, queryParams);
      const bikeStation = parseBikeStation(data);
      return bikeStation;
    } catch (err) {
      console.error('[BikeStationService][getBikeStation]', err);
      throw err;
    }
  }
}
exports.BikeStationService = BikeStationService;
function parseBikeStation(_ref) {
  let {
    id,
    number,
    label,
    location,
    address,
    capacity,
    availableClassicBikes,
    availableEBikes,
    availablePlaces,
    vlsProvider,
    updated,
    status,
    history
  } = _ref;
  let days = 0;
  return new _models.BikeStation(id, number, label, new _models.Place(undefined, location, {
    primaryText: address
  }), capacity, availableClassicBikes, availableEBikes, availablePlaces, vlsProvider, (0, _moment.default)(updated), status === 'OPEN' ? 'open' : 'closed', history ? history.map(_ref2 => {
    let {
      availableClassicBikes,
      availableEBikes,
      availablePlaces: availableLots,
      hour
    } = _ref2;
    if (hour === 23) --days;
    return {
      availableClassicBikes,
      availableEBikes,
      availableLots,
      date: (0, _moment.default)().add(days, 'days').set('hours', hour).startOf('hour')
    };
  }) : undefined);
}