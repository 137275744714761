"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.traceWayPointsLayerId = exports.traceLayerId = exports.speeds = void 0;
exports.useTraces = useTraces;
var _colors = require("@mui/material/colors");
var _react = require("react");
var _utils = require("../../utils");
var _layers = require("./layers");
var _source = require("./source");
const minSpeedMax = 5;
const speeds = exports.speeds = [{
  min: 30,
  color: _colors.orange[500]
}, {
  min: 25,
  color: _colors.yellow[500]
}, {
  min: 20,
  color: _colors.lime[500]
}, {
  min: 15,
  color: _colors.lightGreen[500]
}, {
  min: 10,
  color: _colors.green[500]
}, {
  min: minSpeedMax,
  color: _colors.lightBlue[500]
}, {
  min: 0,
  max: minSpeedMax,
  color: _colors.blue[500]
}];
const traceSourceId = 'route';
const traceLayerId = exports.traceLayerId = 'route';
const traceWayPointsSourceId = 'way-points';
const traceWayPointsLayerId = exports.traceWayPointsLayerId = 'way-points';
function useTraces(map) {
  const [initialized, setInitialized] = (0, _react.useState)(false);
  const {
    addGeoJSONSource: addRoutesSource,
    updateGeoJSONSource: updateRouteSource,
    clearGeoJSONSource: clearRouteSource
  } = (0, _source.useSource)(map, traceSourceId);
  const {
    addGeoJSONSource: addWayPointsSource,
    updateGeoJSONSource: updateWayPointsSource,
    clearGeoJSONSource: clearWayPointSource
  } = (0, _source.useSource)(map, traceWayPointsSourceId);
  const {
    addCircleLayer,
    addLineLayer
  } = (0, _layers.useLayers)(map);
  const initializedRef = (0, _react.useRef)(false);
  function init() {
    if (!map || initializedRef.current) return;
    addWayPointsSource();
    addRoutesSource(undefined, {
      type: 'geojson',
      lineMetrics: true
    });
    addCircleLayer(traceWayPointsLayerId, traceWayPointsSourceId, {
      'circle-radius': 6,
      'circle-stroke-width': 2,
      'circle-stroke-opacity': 1,
      'circle-color': ['get', 'color'],
      'circle-stroke-color': '#fff'
    }, {
      filter: ['==', 'wayPoint', true]
    });
    addLineLayer(traceLayerId, traceSourceId, {
      'line-join': 'round',
      'line-cap': 'round'
    }, {
      'line-color': ['get', 'color'],
      'line-width': 7
    });
    initializedRef.current = true;
    setInitialized(true);
  }
  function addWayPoints(trace) {
    const features = [];
    features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: trace.geometry.coordinates[0]
      },
      properties: {
        color: '#2ac682',
        wayPoint: true,
        movable: false
      }
    });
    features.push({
      type: 'Feature',
      geometry: {
        type: 'Point',
        coordinates: trace.geometry.coordinates[trace.geometry.coordinates.length - 1]
      },
      properties: {
        color: '#dd428d',
        wayPoint: true,
        movable: false
      }
    });
    updateWayPointsSource({
      type: 'FeatureCollection',
      features
    });
  }
  function update(trace, distances, slopePercentages, colorScale) {
    if (!map) return;
    clearTrace();
    addWayPoints(trace);
    const {
      geometry,
      speeds,
      elevations
    } = trace;
    const {
      coordinates
    } = geometry;
    const features = [];
    if (colorScale === 'speed' && speeds && speeds.length === coordinates.length || colorScale === 'elevations' && elevations && elevations.length === coordinates.length) {
      features.push({
        type: 'Feature',
        geometry,
        properties: {
          color: 'black'
        }
      });
      const totalDistance = distances[distances.length - 1];
      map.setPaintProperty(traceLayerId, 'line-gradient', ['interpolate', ['linear'], ['line-progress'], ...distances.reduce((res, distance, index) => {
        if (index === 0 || distance > distances[index - 1]) res.push(distance / totalDistance, colorScale === 'elevations' ? (0, _utils.getElevationColor)(slopePercentages[index]) : getSpeedColor(speeds[index]));
        return res;
      }, [])]);
    } else {
      features.push({
        type: 'Feature',
        geometry,
        properties: {
          color: '#326ac2'
        }
      });
      map.setPaintProperty(traceLayerId, 'line-gradient', ['interpolate', ['linear'], ['line-progress'], 0, '#326ac2']);
    }
    updateRouteSource({
      type: 'FeatureCollection',
      features
    });
  }
  function clearTrace() {
    clearWayPointSource();
    clearRouteSource();
  }
  function getSpeedColor(speed) {
    return speeds.find(_ref => {
      let {
        min
      } = _ref;
      return speed >= min;
    })?.color || speeds[speeds.length - 1].color;
  }
  function destroy() {
    clearTrace();
    initializedRef.current = false;
    setInitialized(false);
  }
  return {
    initialized,
    init,
    update,
    clear: clearTrace,
    destroy
  };
}