"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FuelService = void 0;
class FuelService {
  static async getPrice() {
    try {
      const result = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', 'https://public.opendatasoft.com/api/v2/catalog/datasets/prix_des_carburants_j_7/records?limit=1&refine=update:2022&refine=fuel:Gazole', true);
        request.responseType = 'json';
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
          return;
        };
        request.send();
      });
      const price = result.records[0]?.record.fields.price_gazole;
      if (!price) throw new Error('gazole price not retrieved');
      return price;
    } catch (err) {
      console.error('[FuelService][getPrice]', err);
      throw err;
    }
  }
}
exports.FuelService = FuelService;