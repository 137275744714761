"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backendTransportModes = void 0;
const backendTransportModes = exports.backendTransportModes = {
  BIKE: 'bike',
  PEDESTRIAN: 'pedestrian',
  PUBLIC_TRANSPORT: 'publicTransports'
};