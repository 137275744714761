"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _kmsArea = require("./30-kms-area");
Object.keys(_kmsArea).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _kmsArea[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _kmsArea[key];
    }
  });
});
var _cycleway = require("./cycleway");
Object.keys(_cycleway).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _cycleway[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _cycleway[key];
    }
  });
});
var _dangerousRoad = require("./dangerous-road");
Object.keys(_dangerousRoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dangerousRoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _dangerousRoad[key];
    }
  });
});
var _ferry = require("./ferry");
Object.keys(_ferry).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _ferry[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _ferry[key];
    }
  });
});
var _footway = require("./footway");
Object.keys(_footway).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _footway[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _footway[key];
    }
  });
});
var _greenway = require("./greenway");
Object.keys(_greenway).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _greenway[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _greenway[key];
    }
  });
});
var _lane = require("./lane");
Object.keys(_lane).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _lane[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _lane[key];
    }
  });
});
var _livingStreet = require("./living-street");
Object.keys(_livingStreet).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _livingStreet[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _livingStreet[key];
    }
  });
});
var _mixedFacilities = require("./mixed-facilities");
Object.keys(_mixedFacilities).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _mixedFacilities[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _mixedFacilities[key];
    }
  });
});
var _opposite = require("./opposite");
Object.keys(_opposite).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _opposite[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _opposite[key];
    }
  });
});
var _quietRoad = require("./quiet-road");
Object.keys(_quietRoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _quietRoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _quietRoad[key];
    }
  });
});
var _residentialRoad = require("./residential-road");
Object.keys(_residentialRoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _residentialRoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _residentialRoad[key];
    }
  });
});
var _sharedBusway = require("./shared-busway");
Object.keys(_sharedBusway).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _sharedBusway[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _sharedBusway[key];
    }
  });
});
var _stairs = require("./stairs");
Object.keys(_stairs).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _stairs[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _stairs[key];
    }
  });
});