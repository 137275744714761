"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _blockedRoad = require("./blocked-road");
Object.keys(_blockedRoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _blockedRoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _blockedRoad[key];
    }
  });
});
var _dangerousRoad = require("./dangerous-road");
Object.keys(_dangerousRoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _dangerousRoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _dangerousRoad[key];
    }
  });
});
var _mapError = require("./map-error");
Object.keys(_mapError).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _mapError[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _mapError[key];
    }
  });
});
var _missingFacility = require("./missing-facility");
Object.keys(_missingFacility).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _missingFacility[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _missingFacility[key];
    }
  });
});
var _missingParking = require("./missing-parking");
Object.keys(_missingParking).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _missingParking[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _missingParking[key];
    }
  });
});
var _missingPoi = require("./missing-poi");
Object.keys(_missingPoi).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _missingPoi[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _missingPoi[key];
    }
  });
});
var _poorQualityRoad = require("./poor-quality-road");
Object.keys(_poorQualityRoad).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _poorQualityRoad[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _poorQualityRoad[key];
    }
  });
});
var _roadworks = require("./roadworks");
Object.keys(_roadworks).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _roadworks[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _roadworks[key];
    }
  });
});