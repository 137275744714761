"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RideTrace = exports.CondensedRideTrace = void 0;
var _utils = require("../utils");
class CondensedRideTrace {
  constructor(id, title, description, creator, created) {
    this.id = id;
    this.title = title;
    this.description = description;
    this.creator = creator;
    this.created = created;
  }
}
exports.CondensedRideTrace = CondensedRideTrace;
class RideTrace extends CondensedRideTrace {
  constructor(id, title, description, geometry, osmRelationId, referenceUrl, creator, created) {
    super(id, title, description, creator, created);
    this.geometry = geometry;
    this.osmRelationId = osmRelationId;
    this.referenceUrl = referenceUrl;
    this.bounds = (0, _utils.toBounds)(geometry);
  }
}
exports.RideTrace = RideTrace;