"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.placeTypeIcons = exports.Place = void 0;
var _LocationCity2 = _interopRequireDefault(require("@mui/icons-material/LocationCity"));
var _Train2 = _interopRequireDefault(require("@mui/icons-material/Train"));
var _icons = require("../components/icons");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const placeTypeIcons = exports.placeTypeIcons = {
  accomodation: _icons.AccomodationIcon,
  antics: _icons.AnticsIcon,
  beach: _icons.BeachIcon,
  bicycleHome: _icons.BicycleHomeIcon,
  bicyclePump: _icons.PumpIcon,
  bicycleRental: _icons.BicycleRentalIcon,
  bicycleRepair: _icons.BicycleRepairIcon,
  bicycleParking: _icons.ParkingIcon,
  camping: _icons.CampingIcon,
  castle: _icons.CastleIcon,
  chargingStation: _icons.ChargingStationIcon,
  collective: _icons.CollectiveIcon,
  drinkingWater: _icons.WaterPointIcon,
  fortification: _icons.FortificationIcon,
  garden: _icons.GardenIcon,
  hotel: _icons.AccomodationIcon,
  industry: _icons.IndustryIcon,
  museum: _icons.MuseumIcon,
  naturalSite: _icons.NaturalSiteIcon,
  picnic: _icons.PicnicIcon,
  remarkableCity: _icons.RemarkableCityIcon,
  restaurant: _icons.RestaurantIcon,
  ruins: _icons.RuinsIcon,
  thermalCenter: _icons.ThermalStationIcon,
  toDoToSee: _icons.ToDoToSeeIcon,
  toilets: _icons.ToiletsIcon,
  tourismOffice: _icons.TourismOfficeIcon,
  useful: _icons.UsefulIcon,
  viewPoint: _icons.ViewPointIcon,
  trainStation: _Train2.default,
  information: _icons.TourismOfficeIcon,
  fountain: _icons.FountainIcon,
  city: _LocationCity2.default,
  town: _LocationCity2.default,
  smallHeritage: _icons.SmallHeritageIcon,
  religious: _icons.ReligiousIcon,
  sport: _icons.SportIcon
};
class Place {
  constructor(id, _point, addressDetail, poiId, placeType) {
    this.id = id;
    this._point = _point;
    this.addressDetail = addressDetail;
    this.poiId = poiId;
    this.placeType = placeType;
    if (typeof _point === 'string') {
      const [lng, lat] = _point.substring(_point.indexOf('(') + 1).replace(')', '').split(' ').map(coord => parseFloat(coord));
      this.point = {
        type: 'Point',
        coordinates: [lng, lat]
      };
    } else this.point = _point;
  }
  get strPoint() {
    return `[${this.point.coordinates.map(coord => coord.toFixed(6)).join(', ')}]`;
  }
  get asParam() {
    return `${this.point.coordinates.map(coord => coord.toFixed(6)).join(',')}`;
  }
  get address() {
    return this.addressDetail?.primaryText ? `${this.addressDetail.primaryText}${this.addressDetail.secondaryText ? `, ${this.addressDetail.secondaryText}` : ''}` : this.strPoint;
  }
  clone() {
    return new Place(this.id, {
      ...this.point
    }, this.addressDetail ? {
      ...this.addressDetail
    } : undefined);
  }
}
exports.Place = Place;