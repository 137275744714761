"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DynamicLinkService = void 0;
var _app = require("./app");
class DynamicLinkService {
  static async createShortLink(link, socialMetaTagInfo) {
    try {
      return await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        const {
          ios,
          android,
          firebase: {
            apiKey,
            dynamicLinksDomainUriPrefix
          }
        } = _app.AppService.environment;
        request.open('POST', `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${apiKey}`, true);
        request.responseType = 'json';
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300 && response.shortLink) {
            resolve(response.shortLink);
          } else {
            reject(response);
          }
        };
        request.send(JSON.stringify({
          dynamicLinkInfo: {
            domainUriPrefix: dynamicLinksDomainUriPrefix,
            link,
            androidInfo: {
              androidPackageName: android.bundleId
            },
            iosInfo: {
              iosBundleId: ios.bundleId
            },
            socialMetaTagInfo
          }
        }));
      });
    } catch (err) {
      console.error('[DynamicLinkService][createShortLink]', err);
      throw err;
    }
  }
}
exports.DynamicLinkService = DynamicLinkService;