"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppService = void 0;
var _environment = require("../environment");
class AppService {
  static environment = (() => _environment.defaultEnvironment)();
  static disableTracking = false;
}
exports.AppService = AppService;