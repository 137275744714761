"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteReportService = void 0;
var _models = require("../models");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
class RouteReportService {
  static async getRouteReports(_ref) {
    let {
      cyclabilityZoneId,
      period
    } = _ref;
    try {
      const params = [];
      if (period) params.push(...(0, _models.periodToParams)(period));
      if (cyclabilityZoneId) params.push({
        key: 'cyclability_zone_id',
        value: cyclabilityZoneId
      });
      const data = await _http.HttpService.get('routeReports', '/route_reports', params);
      const routeReports = data.map(parseRouteReport);
      return routeReports;
    } catch (err) {
      console.error('[ReportService][getReports]', err);
      return [];
    }
  }
  static async getRouteReport(id) {
    try {
      const data = await _http.HttpService.get('routeReports', `/route_reports/${id}`);
      const routeReports = parseRouteReport(data);
      return routeReports;
    } catch (err) {
      console.error('[ReportService][getReport]', err);
      throw err;
    }
  }
  static async send(props) {
    try {
      const data = await _http.HttpService.post('routeReports', `/route_reports`, [], [], JSON.stringify(props));
      return parseRouteReport(data);
    } catch (err) {
      console.error('[RouteReportService][send]', err);
      throw err;
    }
  }
  static async updateRouteReport(id, props) {
    try {
      const data = await _http.HttpService.put('routeReports', `/route_reports/${id}`, [], [], JSON.stringify(props));
      return parseRouteReport(data);
    } catch (err) {
      console.error('[RouteReportService][updateRouteReport]', err);
      throw err;
    }
  }
}
exports.RouteReportService = RouteReportService;
function parseRouteReport(_ref2) {
  let {
    id,
    created,
    creator,
    computed_route_id,
    profile,
    alternative,
    geo_steps,
    geo_itinerary,
    geo_report_itinerary,
    description,
    status,
    response,
    source
  } = _ref2;
  return new _models.RouteReport(id, new Date(created), creator, computed_route_id, _cyclingProfile.backendCyclingProfiles[profile], alternative, geo_steps ? geo_steps.coordinates.map(coordinates => {
    return {
      type: 'Point',
      coordinates: coordinates
    };
  }) : undefined, geo_itinerary, geo_report_itinerary, description, status, response, (0, _models.toSource)(source));
}