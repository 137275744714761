"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Poi = void 0;
var _poiCategory = require("./poi-category");
class Poi {
  constructor(id, title, description, location, photos, website, email, phone, detailsUrl, categoryCodes, importance, bicycleHome, imported, publicationStatus, source, externalId) {
    let filterByBounds = arguments.length > 16 && arguments[16] !== undefined ? arguments[16] : false;
    this.id = id;
    this.title = title;
    this.description = description;
    this.location = location;
    this.photos = photos;
    this.website = website;
    this.email = email;
    this.phone = phone;
    this.detailsUrl = detailsUrl;
    this.categoryCodes = categoryCodes;
    this.importance = importance;
    this.bicycleHome = bicycleHome;
    this.imported = imported;
    this.publicationStatus = publicationStatus;
    this.source = source;
    this.externalId = externalId;
    this.filterByBounds = filterByBounds;
  }
  get isAccomodation() {
    return Boolean(this.categoryCodes.find(code => _poiCategory.accomodationCategoryCodes.indexOf(code) > -1));
  }
}
exports.Poi = Poi;