"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useTrip = useTrip;
var _react = require("react");
var _tripStepWhite = _interopRequireDefault(require("../../assets/images/trip/trip-step-white.svg"));
var _utils = require("../../utils");
var _maplibreGl = require("!maplibre-gl");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function useTrip(map, search, _ref) {
  let {
    routes: _routes,
    selectedStepIndex: _selectedStepIndex,
    selectedTrip: _selectedTrip,
    onSearchUpdated,
    onRouteSelected,
    onPreviewShowed
  } = _ref;
  const routes = (0, _react.useRef)(_routes);
  const selectedStepIndex = (0, _react.useRef)(_selectedStepIndex);
  const selectedTrip = (0, _react.useRef)(_selectedTrip);
  const markers = (0, _react.useRef)([]);
  (0, _react.useEffect)(() => {
    routes.current = _routes;
  }, [_routes]);
  (0, _react.useEffect)(() => {
    selectedStepIndex.current = _selectedStepIndex;
  }, [_selectedStepIndex]);
  (0, _react.useEffect)(() => {
    selectedTrip.current = _selectedTrip;
    updateTripMarkers();
  }, [_selectedTrip]);
  function addTripStepMarker(wayPoint, _ref2) {
    let {
      selected,
      draggable,
      wayPointIndex,
      stepIndex
    } = _ref2;
    if (!map) return;
    const element = document.createElement('div');
    element.style.border = '2px solid #fff';
    element.style.borderRadius = '50%';
    element.style.backgroundColor = selected ? '#e91e63' : '#aaa';
    element.style.backgroundImage = `url(${_tripStepWhite.default})`;
    element.style.backgroundPosition = 'center';
    element.style.backgroundRepeat = 'no-repeat';
    element.style.backgroundSize = '14px';
    element.style.width = '20px';
    element.style.height = '20px';
    element.style.cursor = draggable ? 'move' : 'pointer';
    element.addEventListener('click', event => {
      event.stopPropagation();
      if (!selected && stepIndex !== undefined) onRouteSelected(stepIndex);
    });
    const {
      point: {
        coordinates: [lng, lat]
      }
    } = wayPoint;
    const marker = new _maplibreGl.Marker({
      element,
      draggable: selected
    }).setLngLat({
      lat,
      lng
    }).addTo(map);
    if (draggable && stepIndex !== undefined && wayPointIndex !== undefined) {
      marker.on('drag', () => {
        if (!routes.current) return;
        const step = routes.current[stepIndex];
        const routeIndexesToClear = {
          [stepIndex]: true
        };
        const {
          feature
        } = (0, _utils.getDraggedRoutePreview)(step ? [...step.wayPoints] : [], wayPointIndex, marker.getLngLat());

        // TODO: refactor this code to use common function
        if (wayPointIndex === 0 && stepIndex > 0) {
          const prevStep = routes.current[stepIndex - 1];
          if (prevStep) {
            const prevStepWayPoints = prevStep.wayPoints;
            const {
              feature: {
                geometry: {
                  coordinates
                }
              }
            } = (0, _utils.getDraggedRoutePreview)([...prevStepWayPoints], prevStepWayPoints.length - 1, marker.getLngLat());
            routeIndexesToClear[stepIndex - 1] = true;
            feature.geometry.coordinates.splice(0, 0, ...coordinates);
          }
        } else if (step && wayPointIndex === step.wayPoints.length - 1 && stepIndex < routes.current.length - 1) {
          const nextStep = routes.current[stepIndex + 1];
          if (nextStep) {
            const nextStepWayPoints = nextStep.wayPoints;
            const {
              feature: {
                geometry: {
                  coordinates
                }
              }
            } = (0, _utils.getDraggedRoutePreview)([...nextStepWayPoints], 0, marker.getLngLat());
            routeIndexesToClear[stepIndex + 1] = true;
            feature.geometry.coordinates.push(...coordinates);
          }
        }
        onPreviewShowed(feature, routes.current.map((_, index) => index).filter(index => !routeIndexesToClear[index]));
      }).on('dragend', () => {
        if (!routes.current) return;
        const step = routes.current[stepIndex];
        const {
          wayPoints: newWayPoints
        } = (0, _utils.getDraggedRoutePreview)(step ? [...step.wayPoints] : [], wayPointIndex, marker.getLngLat());
        search.wayPoints = newWayPoints;
        onSearchUpdated({
          wayPointIndex
        });
      });
    }
    markers.current.push(marker);
  }
  function updateTripMarkers() {
    clearTripMarkers();
    if (!routes.current || selectedStepIndex.current === null || selectedTrip.current === null) return;
    routes.current.forEach((route, stepIndex) => {
      const step = selectedTrip.current?.steps?.[stepIndex];
      const selected = selectedStepIndex.current === stepIndex;
      if (selected && !step?.departureAccomodationAnnotationId || !selected && stepIndex === 0) {
        const prevStep = routes.current?.[stepIndex - 1];
        const wayPoint = route ? route.wayPoints[0] : prevStep ? prevStep.wayPoints[prevStep.wayPoints.length - 1] : undefined;
        if (wayPoint) addTripStepMarker(wayPoint, {
          selected,
          draggable: true,
          wayPointIndex: 0,
          stepIndex
        });
      }
      if (route && selectedStepIndex.current !== null && selectedStepIndex.current - 1 !== stepIndex && !step?.arrivalAccomodationAnnotationId) {
        const wayPointIndex = route.wayPoints.length - 1;
        addTripStepMarker(route.wayPoints[wayPointIndex], {
          selected,
          draggable: true,
          wayPointIndex,
          stepIndex
        });
      }
    });
  }
  function clearTripMarkers() {
    markers.current.forEach(marker => marker.remove());
    markers.current = [];
  }
  return {
    addTripStepMarker,
    updateTripMarkers,
    clearTripMarkers
  };
}