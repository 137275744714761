"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSavings = useSavings;
var _environment = require("../environment");
const openDaysPerYear = 253;
function useSavings() {
  function getAllowancePerDay(distance) {
    return Math.round(distance / 1000 * 25 * 2) / 100;
  }
  function getAllowancePerYear(distance) {
    return Math.round(distance / 1000 * 25 * openDaysPerYear * 2) / 100;
  }
  function getFuelPricePerDay(fuelPrice, distance) {
    return Math.round(fuelPrice * Math.round(2 * 6.5 * distance / 1000)) / 100;
  }
  function getFuelPricePerYear(fuelPrice, distance) {
    return Math.round(openDaysPerYear * fuelPrice * Math.round(2 * 6.5 * distance / 1000)) / 100;
  }
  function getCO2SavedPerDay(distance) {
    return Math.round(distance / 1000 * _environment.defaultEnvironment.savedKgOfCO2PerKilometer * 2 * 100) / 100;
  }
  function getCO2SavedPerYear(distance) {
    return Math.round(openDaysPerYear * (distance / 1000) * _environment.defaultEnvironment.savedKgOfCO2PerKilometer * 2);
  }
  return {
    getAllowancePerDay,
    getAllowancePerYear,
    getFuelPricePerDay,
    getFuelPricePerYear,
    getCO2SavedPerDay,
    getCO2SavedPerYear
  };
}