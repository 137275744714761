"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.statuses = exports.formats = exports.File = void 0;
var _colors = require("@mui/material/colors");
const statuses = exports.statuses = {
  requested: {
    titleKey: 'commons.files.statuses.requested',
    color: _colors.blue[500]
  },
  prepared: {
    titleKey: 'commons.files.statuses.prepared',
    color: _colors.green[500]
  },
  downloaded: {
    titleKey: 'commons.files.statuses.downloaded',
    color: _colors.green[500]
  },
  failure: {
    titleKey: 'commons.files.statuses.failure',
    color: _colors.red[500]
  },
  expired: {
    titleKey: 'commons.files.statuses.expired',
    color: _colors.deepOrange[500]
  }
};
const formats = exports.formats = [{
  titleKey: 'commons.files.formats.geojson',
  code: 'GEOJSON'
}, {
  titleKey: 'commons.files.formats.shapefile',
  code: 'SHAPEFILE'
}, {
  titleKey: 'commons.files.formats.csv',
  code: 'CSV'
}];
class File {
  constructor(id, type, title, description, _status, _downloadsCount, created, creatorId) {
    this.id = id;
    this.type = type;
    this.title = title;
    this.description = description;
    this._status = _status;
    this._downloadsCount = _downloadsCount;
    this.created = created;
    this.creatorId = creatorId;
  }
  get status() {
    return this._status;
  }
  get downloadsCount() {
    return this._downloadsCount;
  }
  incrementDownloadsCount() {
    this._status = 'downloaded';
    this._downloadsCount += 1;
  }
}
exports.File = File;