"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useClipboard = useClipboard;
function useClipboard(successCallback, errorCallback) {
  async function write(value) {
    try {
      if (typeof value === 'string') await navigator.clipboard.writeText(value);else await navigator.clipboard.write([new ClipboardItem({
        [value.type]: value
      })]);
      successCallback();
    } catch {
      errorCallback();
    }
  }
  function copy(value) {
    if (navigator.permissions) {
      const permissionName = 'clipboard-write';
      navigator.permissions.query({
        name: permissionName
      }).then(async _ref => {
        let {
          state
        } = _ref;
        if (state === 'granted' || state === 'prompt') {
          write(value);
        }
      }, async () => {
        write(value);
      });
    } else {
      write(value);
    }
  }
  return {
    copy
  };
}