"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSignIn = useSignIn;
var _app = require("firebase/app");
var _auth = require("firebase/auth");
var _react = require("react");
var _services = require("../services");
function useSignIn() {
  let {
    setLoading
  } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  const authRef = (0, _react.useRef)();
  (0, _react.useEffect)(() => {
    initFirebaseAuth();
  }, []);
  function initFirebaseAuth() {
    try {
      const app = (0, _app.initializeApp)({
        apiKey: _services.AppService.environment.firebase.apiKey,
        authDomain: 'geovelo-fr-geovelo.firebaseapp.com',
        projectId: 'geovelo.fr:geovelo'
      });
      authRef.current = (0, _auth.getAuth)(app);
    } catch (err) {
      console.error(err);
    }
  }
  async function facebookSignIn(locale) {
    setLoading?.(true);
    return new Promise(async (resolve, reject) => {
      try {
        if (!authRef.current) throw new Error('Firebase not initialized');
        authRef.current.languageCode = locale;
        const provider = new _auth.FacebookAuthProvider();
        const result = await (0, _auth.signInWithPopup)(authRef.current, provider);
        const idToken = await result.user.getIdToken();
        const user = await _services.AuthService.signInWithProvider('firebase', `Token ${idToken}`, true, locale);
        resolve(user);
      } catch (err) {
        reject(err);
      }
      setLoading?.(false);
    });
  }
  function googleSignIn(locale) {
    setLoading?.(true);
    return new Promise(async (resolve, reject) => {
      try {
        if (!authRef.current) throw new Error('Firebase not initialized');
        authRef.current.languageCode = locale;
        const provider = new _auth.GoogleAuthProvider();
        provider.setCustomParameters({
          prompt: 'select_account'
        });
        const result = await (0, _auth.signInWithPopup)(authRef.current, provider);
        const idToken = await result.user.getIdToken();
        const user = await _services.AuthService.signInWithProvider('firebase', `Token ${idToken}`, true, locale);
        resolve(user);
      } catch (err) {
        reject(err);
      }
      setLoading?.(false);
    });
  }
  function appleSignIn(locale) {
    setLoading?.(true);
    return new Promise(async (resolve, reject) => {
      try {
        if (!authRef.current) throw new Error('Firebase not initialized');
        const provider = new _auth.OAuthProvider('apple.com');
        provider.addScope('email');
        provider.setCustomParameters({
          locale
        });
        const result = await (0, _auth.signInWithPopup)(authRef.current, provider);
        const idToken = await result.user.getIdToken();
        const user = await _services.AuthService.signInWithProvider('firebase', `Token ${idToken}`, true, locale);
        resolve(user);
      } catch (err) {
        reject(err);
      }
      setLoading?.(false);
    });
  }
  return {
    facebookSignIn,
    googleSignIn,
    appleSignIn
  };
}