"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Counter = void 0;
class Counter {
  constructor(id, title, place, isVirtual) {
    this.id = id;
    this.title = title;
    this.place = place;
    this.isVirtual = isVirtual;
  }
}
exports.Counter = Counter;