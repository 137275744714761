"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.instructionOrientationsMap = exports.instructionFacilitiesMap = exports.instructionDirectionsMap = void 0;
var _icons = require("../components/icons");
const instructionDirectionsMap = exports.instructionDirectionsMap = {
  dropSharedBike: {
    labelKey: 'commons.routing.directions.reach_station'
  },
  elevator: {
    labelKey: 'commons.routing.directions.take_elevator',
    Icon: _icons.ElevatorIcon,
    PrintableIcon: _icons.PrintableElevatorIcon
  },
  enterAgainstAllowedDirection: {
    labelKey: 'commons.routing.directions.take_no_entry'
  },
  getOffTheBike: {
    labelKey: 'commons.routing.directions.get_off_the_bike',
    Icon: _icons.GetOffTheBikeIcon,
    PrintableIcon: _icons.PrintableGetOffTheBikeIcon
  },
  getOnTheBike: {
    labelKey: 'commons.routing.directions.get_on_the_bike',
    Icon: _icons.GetOnTheBikeIcon,
    PrintableIcon: _icons.PrintableGetOnTheBikeIcon
  },
  goStraight: {
    labelKey: 'commons.routing.directions.go_straight',
    Icon: _icons.GoStraightIcon,
    PrintableIcon: _icons.PrintableGoStraightIcon
  },
  headOn: {
    labelKey: 'commons.routing.directions.head_on',
    Icon: _icons.GoStraightIcon,
    PrintableIcon: _icons.PrintableGoStraightIcon
  },
  stayOn: {
    labelKey: 'commons.routing.directions.stay_on',
    Icon: _icons.GoStraightIcon,
    PrintableIcon: _icons.PrintableGoStraightIcon
  },
  reachViaLocation: {
    labelKey: 'commons.routing.directions.reach_step',
    Icon: _icons.DirectionStepIcon,
    PrintableIcon: _icons.PrintableStepIcon
  },
  reachedTheStation: {
    labelKey: 'commons.routing.directions.reach_station'
  },
  reachedYourDestination: {
    labelKey: 'commons.routing.directions.reach_destination'
  },
  leaveRoundAbout: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit1: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_1st_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit2: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_2nd_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit3: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_3rd_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit4: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_4th_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit5: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_5th_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit6: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_6th_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit7: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_7th_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit8: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_8th_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit9: {
    labelKey: 'commons.routing.directions.leave_roundabout_at_9th_exit',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit10: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit11: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit12: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit13: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit14: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit15: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit16: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit17: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit18: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit19: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExit20: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  roundAboutExitX: {
    labelKey: 'commons.routing.directions.leave_roundabout',
    Icon: _icons.RoundaboutIcon,
    PrintableIcon: _icons.PrintableRoundaboutIcon
  },
  turnLeft: {
    labelKey: 'commons.routing.directions.turn_left',
    Icon: _icons.TurnLeftIcon,
    PrintableIcon: _icons.PrintableTurnLeftIcon
  },
  turnRight: {
    labelKey: 'commons.routing.directions.turn_right',
    Icon: _icons.TurnRightIcon,
    PrintableIcon: _icons.PrintableTurnRightIcon
  },
  turnSharpLeft: {
    labelKey: 'commons.routing.directions.turn_sharp_left',
    Icon: _icons.TurnSharpLeftIcon,
    PrintableIcon: _icons.PrintableTurnSharpLeftIcon
  },
  turnSharpRight: {
    labelKey: 'commons.routing.directions.turn_sharp_right',
    Icon: _icons.TurnSharpRightIcon,
    PrintableIcon: _icons.PrintableTurnSharpRightIcon
  },
  turnSlightLeft: {
    labelKey: 'commons.routing.directions.turn_slight_left',
    Icon: _icons.TurnSlightLeftIcon,
    PrintableIcon: _icons.PrintableTurnSlightLeftIcon
  },
  turnSlightRight: {
    labelKey: 'commons.routing.directions.turn_slight_right',
    Icon: _icons.TurnSlightRightIcon,
    PrintableIcon: _icons.PrintableTurnSlightRightIcon
  },
  uTurn: {
    labelKey: 'commons.routing.directions.make_u_turn',
    Icon: _icons.UTurnIcon,
    PrintableIcon: _icons.PrintableUTurnIcon
  },
  unknown: {
    labelKey: 'commons.routing.directions.unknown'
  }
};
const instructionFacilitiesMap = exports.instructionFacilitiesMap = {
  cycleway: {
    labelKey: 'commons.facilities.cycleway',
    Icon: _icons.CyclewayIcon,
    PrintableIcon: _icons.PrintableCyclewayIcon
  },
  ferry: {
    labelKey: 'commons.facilities.ferry',
    Icon: _icons.FerryIcon,
    PrintableIcon: _icons.PrintableFerryIcon
  },
  footway: {
    labelKey: 'commons.facilities.footway',
    Icon: _icons.FootwayIcon,
    PrintableIcon: _icons.PrintableFootwayIcon
  },
  greenway: {
    labelKey: 'commons.facilities.greenway',
    Icon: _icons.GreenwayIcon,
    PrintableIcon: _icons.PrintableGreenwayIcon
  },
  lane: {
    labelKey: 'commons.facilities.lane',
    Icon: _icons.LaneIcon,
    PrintableIcon: _icons.PrintableLaneIcon
  },
  livingstreet: {
    labelKey: 'commons.facilities.living_street',
    Icon: _icons.LivingStreetIcon,
    PrintableIcon: _icons.PrintableLivingStreetIcon
  },
  opposite: {
    labelKey: 'commons.facilities.opposite',
    Icon: _icons.OppositeIcon,
    PrintableIcon: _icons.PrintableOppositeIcon
  },
  pedestrian: {
    labelKey: 'commons.facilities.pedestrian',
    Icon: _icons.FootwayIcon,
    PrintableIcon: _icons.PrintableFootwayIcon
  },
  primary: {
    labelKey: 'commons.facilities.primary',
    Icon: _icons.PrimaryRoadIcon,
    PrintableIcon: _icons.PrintableDangerousRoadIcon
  },
  residential: {
    labelKey: 'commons.facilities.residential_road',
    Icon: _icons.ResidentialRoadIcon,
    PrintableIcon: _icons.PrintableResidentialRoadIcon
  },
  secondary: {
    labelKey: 'commons.facilities.secondary',
    Icon: _icons.PrimaryRoadIcon,
    PrintableIcon: _icons.PrintableDangerousRoadIcon
  },
  service: {
    labelKey: 'commons.facilities.quiet_road',
    Icon: _icons.QuietRoadIcon,
    PrintableIcon: _icons.PrintableQuietRoadIcon
  },
  sharebusway: {
    labelKey: 'commons.facilities.shared_busway',
    Icon: _icons.SharedBuswayIcon,
    PrintableIcon: _icons.PrintableSharedBuswayIcon
  },
  steps: {
    labelKey: 'commons.facilities.stairs',
    Icon: _icons.StairsIcon,
    PrintableIcon: _icons.PrintableStairsIcon
  },
  tertiary: {
    labelKey: 'commons.facilities.tertiary',
    Icon: _icons.PrimaryRoadIcon,
    PrintableIcon: _icons.PrintableDangerousRoadIcon
  },
  zone30: {
    labelKey: 'commons.facilities.30kms_area',
    Icon: _icons.ThirtyKmsAreaIcon,
    PrintableIcon: _icons.PrintableThirtyKmsAreaIcon
  }
};
const instructionOrientationsMap = exports.instructionOrientationsMap = {
  E: {
    labelKey: 'commons.routing.orientations.east'
  },
  N: {
    labelKey: 'commons.routing.orientations.north'
  },
  NE: {
    labelKey: 'commons.routing.orientations.north_east'
  },
  NW: {
    labelKey: 'commons.routing.orientations.north_west'
  },
  S: {
    labelKey: 'commons.routing.orientations.south'
  },
  SE: {
    labelKey: 'commons.routing.orientations.south_east'
  },
  SW: {
    labelKey: 'commons.routing.orientations.south_west'
  },
  W: {
    labelKey: 'commons.routing.orientations.west'
  }
};