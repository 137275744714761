import { GeoveloInlineWhiteIcon } from '@geovelo-frontends/commons';
import { Facebook, Instagram, LinkedIn, X } from '@mui/icons-material';
import { Box, IconButton, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { AppStoreBadge, GooglePlayBadge } from '../../components';

const socialMediaItems = [
  {
    key: 'x',
    Icon: X,
    label: 'X',
    url: 'https://twitter.com/geovelofr',
  },
  {
    key: 'facebook',
    Icon: Facebook,
    label: 'Facebook',
    url: 'https://www.facebook.com/geovelo',
  },
  {
    key: 'instagram',
    Icon: Instagram,
    label: 'commons.social_media.instagram',
    url: 'https://www.instagram.com/geovelofr',
  },
  {
    key: 'linkedIn',
    Icon: LinkedIn,
    label: 'LinkedIn',
    url: 'https://www.linkedin.com/company/la-compagnie-des-mobilit-s',
  },
];

function Footer(): JSX.Element {
  const { t } = useTranslation();

  return (
    <Box
      alignItems="center"
      bgcolor={({ palette }) => palette.primary.main}
      color="#fff"
      component="footer"
      display="flex"
      flexDirection="column"
      flexShrink={0}
      paddingX={{ xs: 5, xl: 10 }}
      paddingY={5}
    >
      <Box
        alignItems="flex-start"
        display="flex"
        gap={5}
        justifyContent="space-between"
        maxWidth="100%"
        width={1000}
      >
        <Box display={{ xs: 'none', md: 'flex' }} flexDirection="column" gap={1}>
          <Typography variant="body2">
            {t('indemnite_velo.app_title')} {t('commons.by').toLowerCase()}
          </Typography>
          <GeoveloInlineWhiteIcon sx={{ height: 20, width: 'auto' }} />
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <Box display="flex" gap={2}>
            {socialMediaItems.map(({ key, url, label, Icon }) => {
              return (
                <Tooltip arrow key={key} title={label}>
                  <IconButton color="inherit" component="a" href={url} size="small" target="_blank">
                    <Icon fontSize="small" />
                  </IconButton>
                </Tooltip>
              );
            })}
          </Box>
          <Box display="flex" gap={2}>
            <AppStoreBadge height={41} />
            <GooglePlayBadge height={41} />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Footer;
