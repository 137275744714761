"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SummaryType = exports.Summary = void 0;
class SummaryType {
  constructor(code, title, startDate, endDate, image) {
    this.code = code;
    this.title = title;
    this.startDate = startDate;
    this.endDate = endDate;
    this.image = image;
  }
}
exports.SummaryType = SummaryType;
class Summary {
  constructor(summaryType, previewUrl, videoUrl) {
    this.summaryType = summaryType;
    this.previewUrl = previewUrl;
    this.videoUrl = videoUrl;
  }
}
exports.Summary = Summary;