import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import Home from '../pages/home';

import i18n from './i18n';
import './tracking';
import theme from './theme';

function App(): JSX.Element {
  return (
    <I18nextProvider i18n={i18n}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={1}>
          <BrowserRouter>
            <Routes>
              <Route index element={<Home />} />
              <Route element={<Navigate to="/" />} path="*" />
            </Routes>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </I18nextProvider>
  );
}

export default App;
