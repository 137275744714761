import { IEnvironment, defaultEnvironment } from '@geovelo-frontends/commons';

let _environment: IEnvironment & {
  amplitude: { apiKey: string };
} = {
  ...defaultEnvironment,
  source: 'INDEMNITEVELO',
  apiKey: '2193f5a9-a78d-4a49-b0c7-7c12338a4c4c',
  amplitude: { apiKey: '92283ce0622c2a07693ed65c67b00aaa' },
};

if (process.env.REACT_APP_ENV === 'staging') {
  _environment = {
    ..._environment,
  };
}

if (process.env.REACT_APP_ENV === 'production') {
  _environment = {
    ..._environment,
  };
}

export const environment = _environment;
