"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFileSaver = useFileSaver;
var _fileSaver = require("file-saver");
var _xlsx = require("xlsx");
function useFileSaver() {
  function downloadBlob(fileName, blob) {
    (0, _fileSaver.saveAs)(blob, fileName);
  }
  function createCSV(headers, rows) {
    const content = `${headers.length > 0 ? `${headers.join(',')}\r\n` : ''}${rows.map(row => row.join(',')).join('\r\n')}`;
    return new Blob([content], {
      type: 'text/csv;charset=utf-8'
    });
  }
  function downloadCSV(fileName, headers, rows) {
    const blob = createCSV(headers, rows);
    downloadBlob(fileName, blob);
  }
  function downloadXLSX(fileName, headers, rows) {
    const ws = _xlsx.utils.aoa_to_sheet(headers.length > 0 ? [headers, ...rows] : rows);
    ws['!cols'] = headers.map((header, index) => ({
      wch: rows.reduce((wch, row) => Math.max(wch, `${row[index]}`.length), header.length)
    }));
    const wb = _xlsx.utils.book_new();
    _xlsx.utils.book_append_sheet(wb, ws, 'Sheet1');
    (0, _xlsx.writeFileXLSX)(wb, fileName);
  }
  function download(url, fileName) {
    (0, _fileSaver.saveAs)(url, fileName);
  }
  return {
    download,
    createCSV,
    downloadCSV,
    downloadXLSX,
    downloadBlob
  };
}