"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.surfaces = exports.highways = exports.facilitiesTypes = exports.ScenarioDetails = exports.Scenario = void 0;
const facilitiesTypes = exports.facilitiesTypes = ['Autres_chemins_piéton_autorisé_aux_vélos-1x', 'Autres_chemins_piéton_autorisé_aux_vélos-2x', 'Bandes_cyclables-1xD', 'Bandes_cyclables-1xG', 'Bandes_cyclables-2x', 'Bandes_cyclables-2xD', 'Bandes_cyclables-2xG', 'Cheminements_cyclables-1xD', 'Cheminements_cyclables-1xG', 'Cheminements_cyclables-2x', 'Double-sens_cyclables_sans_bande', 'Doubles-sens_cyclables_en_bande-G', 'Doubles-sens_cyclables_piste-G', 'Footway_path_designated-1x', 'Footway_path_designated-2x', 'Limite_a_30-1x', 'Limite_a_30-2x', 'Pedestrian_1x', 'Pedestrian_2x', 'Pistes_cyclables-1xD', 'Pistes_cyclables-1xG', 'Pistes_cyclables-2x', 'Pistes_cyclables-2xD', 'Pistes_sur_Trottoirs-1x', 'Routes_services_chemins_agricoles-1x', 'Routes_services_chemins_agricoles-2x', 'Trottoirs_cyclables-1x', 'Trottoirs_cyclables-2x', 'Voies_bus-1xD', 'Voies_bus-1xG', 'Voies_bus-2x', 'Voies_vertes-1x', 'Voies_vertes-2x', 'Zones_30-1x', 'Zones_30-2x', 'Zones_rencontre-1x', 'Zones_rencontre-2x', 'chaucidou', 'escalier-2x', 'footway_permissive-2x'];
const highways = exports.highways = ['cicleway', 'footway', 'living_street', 'motorway', 'motorway_link', 'path', 'pedestrian', 'primary', 'primary_link', 'residential', 'secondary', 'secondary_link', 'service', 'steps', 'tertiary', 'tertiary_link', 'track', 'trunk', 'trunk_link', 'unclassified'];
const surfaces = exports.surfaces = ['asphalt', 'clay', 'cobblestone', 'compacted', 'concrete', 'concrete:plates', 'concrete;unhewn_cobblestone', 'dirt', 'earth', 'fine_gravel', 'grass', 'grass_paver', 'gravel', 'gravier', 'ground', 'metal', 'metal_grid', 'paved', 'paving_stones', 'pebblestone', 'rock', 'sand', 'sett', 'stepping_stones', 'unhewn_cobblestone', 'unpaved', 'wood', 'woodchips'];
class Scenario {
  constructor(areaId, areaName, computed, creationDate, scenarioDescription, scenarioId) {
    this.areaId = areaId;
    this.areaName = areaName;
    this.computed = computed;
    this.creationDate = creationDate;
    this.scenarioDescription = scenarioDescription;
    this.scenarioId = scenarioId;
  }
}
exports.Scenario = Scenario;
class ScenarioDetails {
  constructor(areaId, areaName, dataDescription, startDate, stopDate, mapState, geometry, metaData) {
    this.areaId = areaId;
    this.areaName = areaName;
    this.dataDescription = dataDescription;
    this.startDate = startDate;
    this.stopDate = stopDate;
    this.mapState = mapState;
    this.geometry = geometry;
    this.metaData = metaData;
  }
}
exports.ScenarioDetails = ScenarioDetails;