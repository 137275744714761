"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.gisFileStatuses = exports.GISFile = void 0;
var _colors = require("@mui/material/colors");
class GISFile {
  constructor(id, title, comment, documentUrl, status, created, creatorId, messages) {
    this.id = id;
    this.title = title;
    this.comment = comment;
    this.documentUrl = documentUrl;
    this.status = status;
    this.created = created;
    this.creatorId = creatorId;
    this.messages = messages;
  }
}
exports.GISFile = GISFile;
const gisFileStatuses = exports.gisFileStatuses = {
  toDo: {
    titleKey: 'commons.gis_file_statuses.to_do',
    color: _colors.deepOrange[500]
  },
  doing: {
    titleKey: 'commons.gis_file_statuses.doing',
    color: _colors.blue[500]
  },
  standby: {
    titleKey: 'commons.gis_file_statuses.standby',
    color: _colors.orange[500]
  },
  done: {
    titleKey: 'commons.gis_file_statuses.done',
    color: _colors.green[500]
  }
};