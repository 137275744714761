"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GeogroupMember = void 0;
class GeogroupMember {
  constructor(id, username, joinDate, lastActivityDate, distance) {
    this.id = id;
    this.username = username;
    this.joinDate = joinDate;
    this.lastActivityDate = lastActivityDate;
    this.distance = distance;
  }
}
exports.GeogroupMember = GeogroupMember;