"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RouteReport = void 0;
var _maplibreGl = require("!maplibre-gl");
class RouteReport {
  constructor(id, created, creator, computedRouteId, profile, isAlternative, wayPoints, geometry, geometryDrawn, description, status, response, source) {
    this.id = id;
    this.created = created;
    this.creator = creator;
    this.computedRouteId = computedRouteId;
    this.profile = profile;
    this.isAlternative = isAlternative;
    this.wayPoints = wayPoints;
    this.geometry = geometry;
    this.geometryDrawn = geometryDrawn;
    this.description = description;
    this.status = status;
    this.response = response;
    this.source = source;
    const bounds = geometry || geometryDrawn ? new _maplibreGl.LngLatBounds() : null;
    if (geometry) {
      geometry.coordinates.forEach(_ref => {
        let [lng, lat] = _ref;
        return bounds?.extend(new _maplibreGl.LngLat(lng, lat));
      });
    }
    if (geometryDrawn) {
      this.geometryDrawn.coordinates.forEach(coordinates => {
        coordinates.forEach(_ref2 => {
          let [lng, lat] = _ref2;
          return bounds?.extend(new _maplibreGl.LngLat(lng, lat));
        });
      });
    }
    this.bounds = bounds;
    this.isClosed = this.status === 'CLOSED' || this.status === 'ARCHIVED' || this.status === 'CLOSED_BY_OSM';
  }
  get JOSMParams() {
    if (!this.bounds) return '';
    return `left=${this.bounds.getWest()}&right=${this.bounds.getEast()}&top=${this.bounds.getNorth()}&bottom=${this.bounds.getSouth()}`;
  }
  clone() {
    return new RouteReport(this.id, this.created, this.creator, this.computedRouteId, this.profile, this.isAlternative, this.wayPoints, this.geometry, this.geometryDrawn, this.description, this.status, this.response, this.source);
  }
}
exports.RouteReport = RouteReport;