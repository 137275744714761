"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.jerseysMap = exports.jerseys = void 0;
var _green = _interopRequireDefault(require("../assets/images/jerseys/green.svg"));
var _red = _interopRequireDefault(require("../assets/images/jerseys/red.svg"));
var _yellow = _interopRequireDefault(require("../assets/images/jerseys/yellow.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const jerseys = exports.jerseys = ['distance', 'consecutiveDaysCount', 'homeWorkJourneysCount'];
const jerseysMap = exports.jerseysMap = {
  consecutiveDaysCount: {
    labelKey: 'commons.jerseys.consecutive_days_count.title',
    descriptionKey: 'commons.jerseys.consecutive_days_count.description',
    icon: _green.default,
    color: '#8EEAC2',
    lightColor: '#EEF9F4',
    textColor: '#005A44'
  },
  distance: {
    labelKey: 'commons.jerseys.distance.title',
    descriptionKey: 'commons.jerseys.distance.description',
    icon: _yellow.default,
    color: '#FFE27D',
    lightColor: '#FFF5D0',
    textColor: '#846858'
  },
  homeWorkJourneysCount: {
    labelKey: 'commons.jerseys.home_work_journeys_count.title',
    descriptionKey: 'commons.jerseys.home_work_journeys_count.description',
    icon: _red.default,
    color: '#FFA3A3',
    lightColor: '#FFEDED',
    textColor: '#D34949'
  }
};