"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseLAVPoi = parseLAVPoi;
var _models = require("../models");
var _app = require("./app");
var _poiCategoryLav = require("./poi-category-lav");
function parseLAVPoi(_ref) {
  let {
    id,
    sit_id,
    title,
    content,
    lat,
    lon,
    adresse,
    commune,
    img,
    img_alt,
    img_copyright,
    web,
    telephone,
    url,
    trigramme_slugs,
    accueil_velo,
    category_slugs,
    activite_slugs,
    post_tag_slugs
  } = _ref;
  let categoryCodes = [];
  trigramme_slugs?.forEach(slug => {
    const categoryCode = _poiCategoryLav.lavTrigramSlugs[slug];
    if (categoryCode) categoryCodes.push(categoryCode);
  });
  category_slugs?.forEach(slug => {
    const categoryCode = _poiCategoryLav.lavCategorySlugs[slug];
    if (categoryCode) categoryCodes.push(categoryCode);
  });
  activite_slugs?.forEach(slug => {
    const categoryCode = _poiCategoryLav.lavActivitySlugs[slug];
    if (categoryCode) categoryCodes.push(categoryCode);
  });
  post_tag_slugs?.forEach(slug => {
    const categoryCode = _poiCategoryLav.lavPostTagSlugs[slug];
    if (categoryCode) categoryCodes.push(categoryCode);
  });
  categoryCodes = [...new Set(categoryCodes)];
  if (typeof lat !== 'number' || typeof lon !== 'number' || categoryCodes.length === 0) return null;
  const imageUrl = img ? `${_app.AppService.environment.lav.imagesUrl}${img}` : null;
  return new _models.Poi(id, title, content, new _models.Place(undefined, {
    type: 'Point',
    coordinates: [lon, lat]
  }, {
    primaryText: adresse,
    secondaryText: commune
  }), imageUrl ? [{
    id,
    url: imageUrl,
    thumbnailUrl: imageUrl,
    squaredThumbnailUrl: imageUrl,
    title: img_alt,
    description: null,
    copyright: img_copyright
  }] : [], web && web.indexOf('http') > -1 ? web.slice(web.indexOf('http')) : null, null, telephone && (telephone.indexOf('0') > -1 || telephone.indexOf('+') > -1) ? telephone.slice(Math.max(telephone.indexOf('0'), telephone.indexOf('+'))) : null, url || null, categoryCodes, null, accueil_velo === 1, true, 'published', 'lav', sit_id, true);
}