"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useClusters = useClusters;
var _react = require("react");
var _models = require("../../models");
var _layers = require("./layers");
var _source = require("./source");
var _maplibreGl = require("!maplibre-gl");
const defaultClusterMaxZoom = 13;
function useClusters(map, sourceId, clusterMaxZoom, _backgroundColor) {
  const clusters = (0, _react.useRef)({});
  const [initialized, setInitialized] = (0, _react.useState)(false);
  const {
    addGeoJSONSource,
    updateGeoJSONSource
  } = (0, _source.useSource)(map, sourceId);
  const {
    addCircleLayer
  } = (0, _layers.useLayers)(map);
  function init() {
    if (!map || initialized) return;
    addGeoJSONSource({
      type: 'FeatureCollection',
      features: []
    }, {
      type: 'geojson',
      cluster: true,
      clusterMaxZoom: clusterMaxZoom !== undefined ? clusterMaxZoom : defaultClusterMaxZoom,
      clusterRadius: 50
    });
    addCircleLayer(`${sourceId}-layer`, sourceId, {
      'circle-opacity': 0
    }, {
      filter: ['!=', 'cluster', true]
    });
    setInitialized(true);
  }
  function createCluster(point, count) {
    let backgroundColor = _backgroundColor;
    if (!backgroundColor) {
      if (count >= 100) {
        backgroundColor = '#fd9c73';
      } else if (count >= 10) {
        backgroundColor = '#f1d357';
      } else {
        backgroundColor = '#6ecc39';
      }
    }
    const element = document.createElement('div');
    element.className = 'cluster-marker';
    element.innerText = `${count}`;
    element.style.backgroundColor = backgroundColor;
    const center = 'lat' in point ? point : (0, _models.toPoint)(point);
    element.addEventListener('mousemove', event => {
      event.stopPropagation();
    });
    element.addEventListener('click', event => {
      event.stopPropagation();
      map?.flyTo({
        center,
        zoom: Math.ceil(map.getZoom() + 1)
      });
    });
    const marker = new _maplibreGl.Marker({
      element
    }).setLngLat(center);
    return marker;
  }
  function getUnclusteredFeatures() {
    if (!map) return [];
    const features = map.getSource(sourceId) && map.querySourceFeatures(sourceId) || [];

    // Add clusters
    features.filter(_ref => {
      let {
        properties
      } = _ref;
      return properties?.cluster;
    }).forEach(_ref2 => {
      let {
        geometry,
        properties
      } = _ref2;
      if (!properties || geometry.type !== 'Point') return;
      const {
        cluster_id: id,
        point_count: count
      } = properties;
      if (id !== undefined && count && !clusters.current[id]) {
        const marker = createCluster(geometry, count).addTo(map);
        clusters.current[id] = marker;
      }
    });

    // return unclustered markers
    return features.filter(_ref3 => {
      let {
        properties
      } = _ref3;
      return !properties?.cluster;
    });
  }
  function updateData() {
    let features = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
    updateGeoJSONSource({
      type: 'FeatureCollection',
      features
    });
  }
  function update() {
    if (!map || !map.getSource(sourceId) || !map.isSourceLoaded(sourceId)) return [];
    return getUnclusteredFeatures();
  }
  function clear() {
    Object.keys(clusters.current).forEach(clusterId => {
      const marker = clusters.current[clusterId];
      marker.remove();
    });
    clusters.current = {};
  }
  function destroy() {
    clear();
    setInitialized(false);
  }
  return {
    initialized,
    init,
    updateData,
    update,
    clear,
    destroy
  };
}