"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.createImageMarker = createImageMarker;
exports.createNewImageMarker = createNewImageMarker;
exports.getAnchorAndOffset = getAnchorAndOffset;
exports.getDataFromUrl = getDataFromUrl;
var _styles = require("@mui/material/styles");
var _maplibreGl = require("!maplibre-gl");
function createImageMarker(_ref) {
  let {
    id,
    radius,
    borderWidth,
    borderColor,
    backgroundImage,
    backgroundColor,
    backgroundSize,
    text,
    draggable,
    zIndex
  } = _ref;
  let {
    onMouseEnter,
    onMouseDown,
    onClick,
    onDragStart,
    onDrag,
    onDragEnd,
    onMouseLeave
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const el = document.createElement('div');
  if (id) el.id = id;
  el.style.backgroundColor = backgroundColor || 'whitesmoke';
  if (backgroundImage) {
    el.style.backgroundImage = `url(${backgroundImage})`;
    el.style.backgroundPosition = 'center';
    el.style.backgroundRepeat = 'no-repeat';
    el.style.backgroundSize = backgroundSize ? `${backgroundSize}px` : 'cover';
  }
  if (text) {
    const backdrop = document.createElement('div');
    backdrop.innerText = text;
    backdrop.style.position = 'absolute';
    backdrop.style.top = '0';
    backdrop.style.left = '0';
    backdrop.style.width = '100%';
    backdrop.style.height = '100%';
    backdrop.style.backgroundColor = 'rgba(0, 0, 0, 0.4)';
    backdrop.style.borderRadius = '50%';
    backdrop.style.color = '#fff';
    backdrop.style.fontSize = '1.5em';
    backdrop.style.fontFamily = 'Roboto, Helvetica, Arial, sans-serif';
    backdrop.style.lineHeight = `${(radius || 14) * 2}px`;
    backdrop.style.textAlign = 'center';
    el.appendChild(backdrop);
  }
  el.style.border = `${borderWidth || 2}px solid ${borderColor || 'white'}`;
  el.style.borderRadius = '50%';
  el.style.height = `${(radius || 14) * 2}px`;
  el.style.width = `${(radius || 14) * 2}px`;
  el.style.zIndex = zIndex || '1';
  el.style.cursor = 'pointer';
  el.addEventListener('mouseenter', event => {
    event.stopPropagation();
    el.style.zIndex = '3';
    onMouseEnter?.();
  });
  el.addEventListener('mousemove', event => {
    event.stopPropagation();
  });
  el.addEventListener('mouseleave', event => {
    event.stopPropagation();
    el.style.zIndex = zIndex || '1';
    onMouseLeave?.();
  });
  el.addEventListener('mousedown', event => {
    if (!draggable) event.stopPropagation();
    onMouseDown?.();
  });
  el.addEventListener('click', event => {
    event.stopPropagation();
    onClick?.();
  });
  el.addEventListener('dblclick', event => event.stopPropagation());
  el.addEventListener('contextmenu', event => event.stopPropagation());
  const marker = new _maplibreGl.Marker({
    element: el,
    draggable
  });
  if (draggable) {
    marker.on('dragstart', event => onDragStart?.(event.target.getLngLat())).on('drag', _ref2 => {
      let {
        target
      } = _ref2;
      return onDrag?.(target.getLngLat());
    }).on('dragend', _ref3 => {
      let {
        target
      } = _ref3;
      return onDragEnd?.(target.getLngLat());
    });
  }
  return marker;
}
function createNewImageMarker(_ref4) {
  let {
    id,
    round,
    height: _height,
    color,
    icon,
    draggable,
    zIndex
  } = _ref4;
  let {
    onMouseEnter,
    onMouseDown,
    onClick,
    onDragStart,
    onDrag,
    onDragEnd,
    onMouseLeave
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  const el = document.createElement('div');
  if (id) el.id = id;
  const height = _height || (round ? 30 : 40);
  const width = round ? height * 28 / 34 : height * 28 / 41;
  el.style.width = `${width}px`;
  el.style.height = `${height}px`;
  el.style.zIndex = zIndex || '1';
  el.style.cursor = 'pointer';
  const markerEl = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
  markerEl.setAttribute('width', `${width}`);
  markerEl.setAttribute('height', `${height}`);
  markerEl.setAttribute('viewBox', round ? '0 0 28 34' : '0 0 28 40');
  if (round) {
    const maskEl = document.createElementNS('http://www.w3.org/2000/svg', 'mask');
    maskEl.id = 'path-1-inside-1_2079_9066';
    markerEl.setAttribute('fill', 'white');
    const path1El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path1El.setAttribute('fill-rule', 'evenodd');
    path1El.setAttribute('clip-rule', 'evenodd');
    path1El.setAttribute('d', 'M16.8023 27.7195C23.1914 26.4215 28 20.7723 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 20.7723 4.80859 26.4215 11.1977 27.7195L13.0667 32.5753C13.3956 33.4297 14.6044 33.4297 14.9333 32.5753L16.8023 27.7195Z');
    maskEl.appendChild(path1El);
    markerEl.appendChild(maskEl);
    const path2El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path2El.setAttribute('fill-rule', 'evenodd');
    path2El.setAttribute('clip-rule', 'evenodd');
    path2El.setAttribute('d', 'M16.8023 27.7195C23.1914 26.4215 28 20.7723 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 20.7723 4.80859 26.4215 11.1977 27.7195L13.0667 32.5753C13.3956 33.4297 14.6044 33.4297 14.9333 32.5753L16.8023 27.7195Z');
    path2El.setAttribute('fill', color);
    markerEl.appendChild(path2El);
    const path3El = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    path3El.setAttribute('d', 'M16.8023 27.7195L16.6032 26.7395L16.0659 26.8487L15.869 27.3603L16.8023 27.7195ZM11.1977 27.7195L12.131 27.3603L11.9341 26.8487L11.3968 26.7395L11.1977 27.7195ZM13.0667 32.5753L12.1335 32.9345L13.0667 32.5753ZM14.9333 32.5753L15.8665 32.9345L14.9333 32.5753ZM27 14C27 20.2874 22.5355 25.5343 16.6032 26.7395L17.0014 28.6995C23.8473 27.3086 29 21.2572 29 14H27ZM14 1C21.1797 1 27 6.8203 27 14H29C29 5.71573 22.2843 -1 14 -1V1ZM1 14C1 6.8203 6.8203 1 14 1V-1C5.71573 -1 -1 5.71573 -1 14H1ZM11.3968 26.7395C5.46447 25.5343 1 20.2874 1 14H-1C-1 21.2572 4.15271 27.3086 10.9986 28.6995L11.3968 26.7395ZM14 32.2161L12.131 27.3603L10.2645 28.0787L12.1335 32.9345L14 32.2161ZM14 32.2161L12.1335 32.9345C12.7912 34.6433 15.2088 34.6433 15.8665 32.9345L14 32.2161ZM15.869 27.3603L14 32.2161L15.8665 32.9345L17.7355 28.0787L15.869 27.3603Z');
    path3El.setAttribute('fill', (0, _styles.darken)(color, 0.2));
    path3El.setAttribute('mask', 'url(#path-1-inside-1_2079_9066)');
    markerEl.appendChild(path3El);
  } else {
    const pathEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    pathEl.setAttribute('d', 'M1 13.5634C1 6.62481 6.59973 1 13.5074 1C20.415 1 26.0147 6.62481 26.0147 13.5634C26.0147 19.0299 22.6109 29.1514 14.6415 37.5698C14.0243 38.2218 12.9905 38.2218 12.3732 37.5698C4.40377 29.1514 1 19.0299 1 13.5634Z');
    pathEl.setAttribute('fill-rule', 'evenodd');
    pathEl.setAttribute('clip-rule', 'evenodd');
    pathEl.setAttribute('fill', color);
    pathEl.setAttribute('stroke', '#fff');
    pathEl.setAttribute('stroke-width', '2');
    markerEl.appendChild(pathEl);
    if (!icon) {
      const ellipseEl = document.createElementNS('http://www.w3.org/2000/svg', 'path');
      ellipseEl.setAttribute('d', 'M13.5074 19.5285C16.8332 19.5285 19.5294 16.8323 19.5294 13.5064C19.5294 10.1805 16.8332 7.48438 13.5074 7.48438C10.1815 7.48438 7.48529 10.1805 7.48529 13.5064C7.48529 16.8323 10.1815 19.5285 13.5074 19.5285Z');
      ellipseEl.setAttribute('fill-rule', 'evenodd');
      ellipseEl.setAttribute('clip-rule', 'evenodd');
      ellipseEl.setAttribute('fill', '#fff');
      markerEl.appendChild(ellipseEl);
    }
  }
  el.appendChild(markerEl);
  if (icon) {
    const iconEl = document.createElement('div');
    iconEl.style.position = 'absolute';
    iconEl.style.top = '0';
    iconEl.style.left = '0';
    iconEl.style.width = `${width}px`;
    iconEl.style.height = `${width}px`;
    iconEl.style.backgroundImage = `url(${icon})`;
    iconEl.style.backgroundPosition = 'center';
    iconEl.style.backgroundRepeat = 'no-repeat';
    iconEl.style.backgroundSize = round ? '75%' : '70%';
    el.appendChild(iconEl);
  }
  el.addEventListener('mouseenter', event => {
    event.stopPropagation();
    el.style.zIndex = '3';
    onMouseEnter?.();
  });
  el.addEventListener('mousemove', event => {
    event.stopPropagation();
  });
  el.addEventListener('mouseleave', event => {
    event.stopPropagation();
    el.style.zIndex = zIndex || '1';
    onMouseLeave?.();
  });
  el.addEventListener('mousedown', event => {
    if (!draggable) event.stopPropagation();
    onMouseDown?.();
  });
  el.addEventListener('click', event => {
    event.stopPropagation();
    onClick?.();
  });
  el.addEventListener('dblclick', event => event.stopPropagation());
  el.addEventListener('contextmenu', event => event.stopPropagation());
  const marker = new _maplibreGl.Marker({
    element: el,
    anchor: 'bottom',
    draggable
  });
  if (draggable) {
    marker.on('dragstart', event => onDragStart?.(event.target.getLngLat())).on('drag', _ref5 => {
      let {
        target
      } = _ref5;
      return onDrag?.(target.getLngLat());
    }).on('dragend', _ref6 => {
      let {
        target
      } = _ref6;
      return onDragEnd?.(target.getLngLat());
    });
  }
  return marker;
}
function getAnchorAndOffset(map, _ref7, radius) {
  let {
    lng
  } = _ref7;
  return map.getCenter().lng > lng ? {
    anchor: 'left',
    offset: [radius, 0]
  } : {
    anchor: 'right',
    offset: [-radius, 0]
  };
}
function getDataFromUrl(url) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'json';
    xhr.onload = () => {
      const {
        status,
        response
      } = xhr;
      if (status === 200) {
        const {
          version,
          sources,
          layers,
          glyphs,
          sprite
        } = response;
        resolve({
          version: version || 0,
          sources: sources || {},
          layers: layers || [],
          glyphs,
          sprite
        });
      } else {
        reject(status);
      }
    };
    xhr.send();
  });
}