"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BadgeService = void 0;
exports.parseBadgeLevels = parseBadgeLevels;
exports.parseConditionalBadges = parseConditionalBadges;
var _http = require("./http");
var _user = require("./user");
class BadgeService {
  static async getBadgeLevels() {
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const [res1, res2] = await Promise.all([_http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/game_level_badges`), _http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/game_conditional_badges`)]);
      return {
        levels: parseBadgeLevels(res1),
        conditionalsBadges: parseConditionalBadges(res2)
      };
    } catch (err) {
      console.error('[BadgeService][getBadgeLevels]', err);
      throw err;
    }
  }
}
exports.BadgeService = BadgeService;
const badgeLevelKeys = {
  cycling_days: 'regularity',
  cycling_distance: 'cyclingDistance',
  report: 'contributor'
};
function parseBadgeLevels(_ref) {
  let {
    level_badges
  } = _ref;
  return level_badges.reduce((res, _ref2) => {
    let {
      code,
      level,
      progress,
      level_thresholds
    } = _ref2;
    const key = badgeLevelKeys[code];
    if (key) {
      const maxLevelReached = level_thresholds ? level === level_thresholds.length : true;
      const nextLevelMinProgress = maxLevelReached || !level_thresholds ? null : level_thresholds[level];
      res[key] = {
        key,
        level,
        progress,
        maxLevelReached,
        nextLevelMinProgress
      };
    }
    return res;
  }, {});
}
const conditionalBadgeKeys = {
  altitude_gain_100m: 'verticalGain',
  distance_50km: 'longDistance',
  night_bird: 'nightBird'
};
function parseConditionalBadges(_ref3) {
  let {
    conditional_badges
  } = _ref3;
  return conditional_badges.reduce((res, _ref4) => {
    let {
      code,
      count
    } = _ref4;
    const key = conditionalBadgeKeys[code];
    if (key) {
      res[key] = {
        key,
        count
      };
    }
    return res;
  }, {});
}