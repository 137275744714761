"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FacilitiesSuggestion = void 0;
class FacilitiesSuggestion {
  constructor(id, title, budget, suggestions) {
    this.id = id;
    this.title = title;
    this.budget = budget;
    this.suggestions = suggestions;
  }
}
exports.FacilitiesSuggestion = FacilitiesSuggestion;