"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VirtualCounterService = void 0;
var _models = require("../models");
var _stats = require("../utils/stats");
var _http = require("./http");
class VirtualCounterService {
  static async addVirtualCounter(_ref) {
    let {
      title,
      place: {
        point,
        address
      }
    } = _ref;
    try {
      const data = await _http.HttpService.post('virtualCounters', '/stats/virtual_counter', [], [], JSON.stringify({
        title,
        geo_point: point,
        address
      }));
      const virtualCounter = parseVirtualCounter(data);
      return virtualCounter;
    } catch (err) {
      console.error('[VirtualCounterService][addVirtualCounter]', err);
      throw err;
    }
  }
  static async getVirtualCounters() {
    try {
      const data = await _http.HttpService.get('virtualCounters', `/stats/virtual_counter`);
      const virtualCounters = data.map(parseVirtualCounter);
      return virtualCounters;
    } catch (err) {
      console.error('[VirtualCounterService][getVirtualCounters]', err);
      throw err;
    }
  }
  static async getVirtualCountersStats(period) {
    try {
      const {
        results
      } = await _http.HttpService.get('virtualCounters', `/stats/virtual_counters/stats`, [...(0, _models.periodToParams)(period), {
        key: 'page_size',
        value: 500
      }, {
        key: 'unit',
        value: 'all'
      }]);
      const stats = results.map(_ref2 => {
        let {
          virtual_counter_id: counterId,
          count
        } = _ref2;
        return {
          counterId,
          count
        };
      });
      return stats;
    } catch (err) {
      console.error('[VirtualCounterService][getVirtualCountersStats]', err);
      throw err;
    }
  }
  static async getVirtualCountersExtrapolatedStats(period) {
    try {
      const {
        results
      } = await _http.HttpService.get('virtualCounters', `/stats/virtual_counters_extrapolated/stats`, [...(0, _models.periodToParams)(period), {
        key: 'page_size',
        value: 500
      }, {
        key: 'unit',
        value: 'all'
      }]);
      const stats = results.map(_ref3 => {
        let {
          virtual_counter_id: counterId,
          count
        } = _ref3;
        return {
          counterId,
          count
        };
      });
      return stats;
    } catch (err) {
      console.error('[VirtualCounterService][getVirtualCountersExtrapolatedStats]', err);
      throw err;
    }
  }
  static async getVirtualCounterStats(counterId, period) {
    try {
      const data = await _http.HttpService.get('virtualCounters', `/stats/virtual_counter/${counterId}`, [...(0, _models.periodToParams)(period)]);
      const stats = parseVirtualCounterStats(data);
      return stats;
    } catch (err) {
      console.error('[VirtualCounterService][getVirtualCounterStats]', err);
      throw err;
    }
  }
  static async getVirtualCounterExtrapolatedStats(counterId, period) {
    try {
      const data = await _http.HttpService.get('virtualCounters', `/stats/virtual_counter_extrapolated/${counterId}`, [...(0, _models.periodToParams)(period)]);
      const stats = parseVirtualCounterStats(data);
      return stats;
    } catch (err) {
      console.error('[VirtualCounterService][getVirtualCounterExtrapolatedStats]', err);
      throw err;
    }
  }
  static async updateVirtualCounter(counterId, _ref4) {
    let {
      title,
      place
    } = _ref4;
    try {
      const data = await _http.HttpService.put('virtualCounters', `/stats/virtual_counter/${counterId}`, [], [], JSON.stringify({
        title,
        ...(place ? {
          geo_point: place.point,
          address: place.address
        } : {})
      }));
      const virtualCounter = parseVirtualCounter(data);
      return virtualCounter;
    } catch (err) {
      console.error('[VirtualCounterService][updateVirtualCounter]', err);
      throw err;
    }
  }
  static async removeVirtualCounter(counterId) {
    try {
      await _http.HttpService.delete('virtualCounters', `/stats/virtual_counter/${counterId}`);
      return true;
    } catch (err) {
      console.error('[VirtualCounterService][removeVirtualCounter]', err);
      throw err;
    }
  }
  static async downloadVirtualCounters(period, counters) {
    try {
      const params = [...(0, _models.periodToParams)(period)];
      if (counters) {
        params.push(...counters.map(counter => {
          return {
            key: 'ids[]',
            value: counter.id
          };
        }));
      }
      await _http.HttpService.post('virtualCounters', '/stats/virtual_counter/csv', params);
      return true;
    } catch (err) {
      console.error('[VirtualCounterService][addVirtualCounter]', err);
      throw err;
    }
  }
}
exports.VirtualCounterService = VirtualCounterService;
function parseVirtualCounter(_ref5) {
  let {
    id,
    title,
    geo_point,
    address
  } = _ref5;
  return new _models.Counter(id, title, new _models.Place(id, geo_point, {
    primaryText: address
  }), true);
}
function parseVirtualCounterStats(_ref6) {
  let {
    stats
  } = _ref6;
  return (stats || []).map(_ref7 => {
    let {
      date: strDate,
      day_date: dayDate,
      month,
      year,
      count
    } = _ref7;
    const date = (0, _stats.parseStatsDate)({
      strDate: strDate || dayDate,
      month,
      year
    });
    if (!date) throw new Error('invalid stats');
    return {
      date,
      count
    };
  });
}