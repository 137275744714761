"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parsePhoto = parsePhoto;
function parsePhoto(_ref, backendUrl) {
  let {
    id,
    title,
    description,
    copyright,
    url,
    thumbnail,
    thumbnailSquare
  } = _ref;
  return {
    id,
    title,
    description,
    copyright,
    url: backendUrl ? `${backendUrl}${url}` : url,
    thumbnailUrl: backendUrl ? `${backendUrl}${thumbnail}` : thumbnail,
    squaredThumbnailUrl: backendUrl ? `${backendUrl}${thumbnailSquare}` : thumbnailSquare
  };
}