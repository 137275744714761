import { Button, useAmplitudeTracker } from '@geovelo-frontends/commons';
import { OpenInNew } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';

import mockup from '../../assets/img/entreprise.png';
import { environment } from '../../environment';
import Layout from '../../layouts/default';

function Home(): JSX.Element {
  const [initialized, setInitialized] = useState(false);
  const { trackEvent } = useAmplitudeTracker();

  useEffect(() => {
    setInitialized(true);
  }, []);

  useEffect(() => {
    trackEvent('Page Visited', { pathname: '/' });
  }, [initialized]);

  return (
    <Layout>
      <Box
        alignSelf="center"
        display="flex"
        flexDirection="column"
        flexGrow={1}
        gap={5}
        justifyContent="center"
        maxWidth="100%"
        width={1000}
      >
        <Typography fontSize="1.15rem" fontWeight={600}>
          📣 Indemnité vélo évolue !
        </Typography>
        <Typography>
          Le calcul du Forfait Mobilités Durables est désormais disponible sur notre nouvelle
          plateforme <b>Geovelo Entreprise</b>. Créez un compte gratuit pour la tester, ou parlez-en
          à votre RH !
        </Typography>
        <Box display="flex" flexDirection={{ xs: 'column', md: 'row' }} gap={5}>
          <Box
            component="ul"
            display="flex"
            flexDirection="column"
            gap={2}
            width={{ md: 'calc((100% - 40px) / 2)' }}
          >
            <li>
              <b>Retrouvez des fonctionnalités liées au Forfait Mobilités Durables</b>
              <br />
              Configuration avancée, trajets effectués avec l'application Geovelo synchronisés
              automatiquement avec votre calendrier
            </li>
            <li>
              <b>Et d'autres fonctionnalités 🎉</b>
              <br />
              Créez une communauté entreprise, lancez des challenges entre collègues et suivez vos
              statistiques et votre impact carbone !
            </li>
          </Box>
          <Box width={{ md: 'calc((100% - 40px) / 2)' }}>
            <img alt="Geovelo Entreprise" src={mockup} width="100%" />
          </Box>
        </Box>
        <Box display="flex" gap={2}>
          <Button
            color="primary"
            component="a"
            href="https://faq.geovelo-entreprise.com/fr/article/jutilisais-le-site-indemnitevelofr-comment-basculer-sur-geovelo-entreprise-1mxl17k/"
            onClick={() => {
              trackEvent('Learn More Button Clicked');
            }}
            size="large"
            sx={{ '&&&': { borderRadius: 3 } }}
            target="_blank"
            variant="outlined"
          >
            En savoir plus
          </Button>
          <Button
            disableElevation
            color="primary"
            component="a"
            endIcon={<OpenInNew />}
            href={`${environment.enterpriseShowcaseUrl}?utm_source=indemnite_velo`}
            onClick={() => {
              trackEvent('Discover Geovelo Entreprise Button Clicked');
            }}
            size="large"
            sx={{ '&&&': { borderRadius: 3 } }}
            variant="contained"
          >
            Découvrir Geovelo Entreprise
          </Button>
        </Box>
      </Box>
    </Layout>
  );
}

export default Home;
