"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useSubGroupColors = useSubGroupColors;
var _react = require("react");
const colors = ['#E06621', '#DA3A87', '#326AC2', '#1589BB', '#BB8A1A', '#268B1D', '#D34949', '#B235C6', '#03825C', '#743AEF'];
function useSubGroupColors(_ref) {
  let {
    teams
  } = _ref;
  const [teamsColors, setTeamsColors] = (0, _react.useState)();
  (0, _react.useEffect)(() => {
    if (teams) getTeamsColors(teams);
    return () => setTeamsColors(undefined);
  }, [teams]);
  function getTeamsColors(teams) {
    setTeamsColors(teams.reduce((res, _ref2) => {
      let {
        id
      } = _ref2;
      res[id] = colors[id % 10];
      return res;
    }, {}));
  }
  return {
    teamsColors
  };
}