"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GarminService = void 0;
var _http = require("./http");
var _user = require("./user");
class GarminService {
  static async getStatus() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const {
        oauth_token_authorized: oauthTokenAuthorized,
        activity_api_authorized: activityApiAuthorized
      } = await _http.HttpService.get('v1', `/users/${_user.UserService.currentUser.id}/garmin_connect`, [], [], null, {
        expectedStatus: 200
      });
      return {
        activityApiAuthorized,
        oauthTokenAuthorized
      };
    } catch (err) {
      if (typeof err === 'object' && err && 'status' in err && err.status === 404) return null;
      console.error('[GarminService][getStatus]', err);
      throw err;
    }
  }
  static async startConnection() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const {
        garmin_url
      } = await _http.HttpService.post('v1', `/garmin_connect/start`);
      return garmin_url;
    } catch (err) {
      console.error('[GarminService][startConnection]', err);
      throw err;
    }
  }
  static async deleteConnection() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      await _http.HttpService.delete('v1', `/users/${_user.UserService.currentUser.id}/garmin_connect`);
      return true;
    } catch (err) {
      console.error('[GarminService][deleteConnection]', err);
      throw err;
    }
  }
}
exports.GarminService = GarminService;