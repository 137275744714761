"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.defaultBounds = exports.WidgetConfig = void 0;
const defaultBounds = exports.defaultBounds = {
  north: 51.3055721,
  east: 9.8282225,
  south: 41.2611155,
  west: -5.4517733
};
const widgetConfigCodes = ['geovelo', 'larochelle', 'lav'];
class WidgetConfig {
  constructor(_ref) {
    let {
      id,
      partnerId,
      apiKey,
      code,
      title,
      bounds,
      enableSimpleSearch,
      enableRouting,
      cyclingProfiles,
      defaultCyclingProfile,
      enablePublicTransports,
      enableBSS,
      enableRides,
      showPartnerRidesOnly,
      showPartnerArea,
      rideBounds,
      bikeRouteIds,
      enableTrips,
      enableFacilities,
      enableViewer,
      disableScrollZoom,
      disableSmallDeviceScrollZoom,
      primaryColor,
      secondaryColor,
      area
    } = _ref;
    this.id = id;
    this.partnerId = partnerId;
    this.apiKey = apiKey;
    this.code = code && [...widgetConfigCodes].includes(code) ? code : 'geovelo';
    this.title = title || '';
    this.bounds = bounds || defaultBounds;
    this.enableSimpleSearch = enableSimpleSearch || false;
    this.enableRouting = enableRouting === false ? false : true;
    this.cyclingProfiles = cyclingProfiles || ['safe', 'daily', 'direct', 'touristic'];
    this.defaultCyclingProfile = defaultCyclingProfile || 'touristic';
    this.enablePublicTransports = enablePublicTransports || false;
    this.enableBSS = enableBSS || false;
    this.enableRides = enableRides || false;
    this.showPartnerRidesOnly = showPartnerRidesOnly !== undefined ? showPartnerRidesOnly : true;
    this.showPartnerArea = showPartnerArea !== undefined ? showPartnerArea : true;
    this.rideBounds = rideBounds || null;
    this.bikeRouteIds = bikeRouteIds || null;
    this.enableTrips = enableTrips || false;
    this.enableFacilities = enableFacilities === false ? false : true;
    this.enableViewer = enableViewer === false ? false : true;
    this.disableScrollZoom = disableScrollZoom || false;
    this.disableSmallDeviceScrollZoom = disableSmallDeviceScrollZoom || false;
    this.primaryColor = primaryColor || '#326ac2';
    this.secondaryColor = secondaryColor || '#2ac682';
    this.area = area;
  }
}
exports.WidgetConfig = WidgetConfig;