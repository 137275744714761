"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Area = void 0;
class Area {
  constructor(id, geometry) {
    this.id = id;
    this.geometry = geometry;
  }
}
exports.Area = Area;