"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.poiCategoryOrders = exports.poiCategoryLabelKeys = exports.poiCategoryIcons = exports.poiCategoryColors = exports.poiCategoryCodesMap = exports.poiBackendCategoryCodes = exports.PoiCategoryService = void 0;
var _accomodationWhite = _interopRequireDefault(require("../assets/images/poi/accomodation-white.svg"));
var _anticsWhite = _interopRequireDefault(require("../assets/images/poi/antics-white.svg"));
var _beachWhite = _interopRequireDefault(require("../assets/images/poi/beach-white.svg"));
var _bicycleHomeWhite = _interopRequireDefault(require("../assets/images/poi/bicycle-home-white.svg"));
var _bicycleRentalWhite = _interopRequireDefault(require("../assets/images/poi/bicycle-rental-white.svg"));
var _bicycleRepairWhite = _interopRequireDefault(require("../assets/images/poi/bicycle-repair-white.svg"));
var _campingWhite = _interopRequireDefault(require("../assets/images/poi/camping-white.svg"));
var _castleWhite = _interopRequireDefault(require("../assets/images/poi/castle-white.svg"));
var _chargingStationWhite = _interopRequireDefault(require("../assets/images/poi/charging-station-white.svg"));
var _collectiveWhite = _interopRequireDefault(require("../assets/images/poi/collective-white.svg"));
var _fortificationWhite = _interopRequireDefault(require("../assets/images/poi/fortification-white.svg"));
var _gardenWhite = _interopRequireDefault(require("../assets/images/poi/garden-white.svg"));
var _industryWhite = _interopRequireDefault(require("../assets/images/poi/industry-white.svg"));
var _museumWhite = _interopRequireDefault(require("../assets/images/poi/museum-white.svg"));
var _naturalSiteWhite = _interopRequireDefault(require("../assets/images/poi/natural-site-white.svg"));
var _picnicWhite = _interopRequireDefault(require("../assets/images/poi/picnic-white.svg"));
var _pumpWhite = _interopRequireDefault(require("../assets/images/poi/pump-white.svg"));
var _remarkableCityWhite = _interopRequireDefault(require("../assets/images/poi/remarkable-city-white.svg"));
var _restaurantWhite = _interopRequireDefault(require("../assets/images/poi/restaurant-white.svg"));
var _ruinsWhite = _interopRequireDefault(require("../assets/images/poi/ruins-white.svg"));
var _thermalStationWhite = _interopRequireDefault(require("../assets/images/poi/thermal-station-white.svg"));
var _toDoToSeeWhite = _interopRequireDefault(require("../assets/images/poi/to-do-to-see-white.svg"));
var _toiletsWhite = _interopRequireDefault(require("../assets/images/poi/toilets-white.svg"));
var _tourismOfficeWhite = _interopRequireDefault(require("../assets/images/poi/tourism-office-white.svg"));
var _usefulWhite = _interopRequireDefault(require("../assets/images/poi/useful-white.svg"));
var _viewPointWhite = _interopRequireDefault(require("../assets/images/poi/view-point-white.svg"));
var _waterPointWhite = _interopRequireDefault(require("../assets/images/poi/water-point-white.svg"));
var _models = require("../models");
var _http = require("./http");
var _poiCategoryLav = require("./poi-category-lav");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class PoiCategoryService {
  static async getPoiCategories() {
    let {
      widgetConfig
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const data = await _http.HttpService.get('poiCategories', '/poi_categories');
      const categories = parsePoiCategories(data);
      if (widgetConfig?.code === 'lav') {
        const codes = ['bicyclePump', 'chargingStation', 'drinkingWater', 'picnic', 'toilets'];
        const filteredCategories = categories.filter(_ref => {
          let {
            code
          } = _ref;
          return codes.indexOf(code) > -1;
        });
        return [...(0, _poiCategoryLav.parseLavCategories)(Math.max(...categories.map(_ref2 => {
          let {
            id
          } = _ref2;
          return id;
        })) + 1), ...filteredCategories];
      }
      return categories;
    } catch (err) {
      console.error('[PoiCategoryService][getPoiCategories]', err);
      throw err;
    }
  }
}
exports.PoiCategoryService = PoiCategoryService;
const poiBackendCategoryCodes = exports.poiBackendCategoryCodes = {
  accomodation: 'ACCOMODATION',
  antics: 'ANTICS',
  beach: 'BEACH',
  bicycle: 'BICYCLE',
  bicycleHome: 'BICYCLE_HOME',
  bicycleParking: 'BICYCLE_PARKING',
  bicyclePump: 'BICYCLE_PUMP',
  bicycleRental: 'BICYCLE_RENTAL',
  bicycleRepair: 'BICYCLE_REPAIR',
  bnb: 'BnB',
  camping: 'CAMPING',
  castle: 'CASTLE',
  cave: 'CAVE',
  chargingStation: 'CHARGING_STATION',
  collective: 'COLLECTIVE',
  drinkingWater: 'DRINKING_WATER',
  fortification: 'FORTIFICATION',
  garden: 'GARDEN',
  holidayVillage: 'HOLIDAY_VILLAGE',
  hotel: 'HOTEL',
  industry: 'INDUSTRY',
  leisurePark: 'LEISURE_PARK',
  museum: 'MUSEUM',
  naturalSite: 'NATURAL_SITE',
  picnic: 'PICNIC',
  religious: 'RELIGIOUS',
  remarkableCity: 'REMARKABLE_CITY',
  residence: 'RESIDENCE',
  restaurant: 'RESTAURANT',
  ruins: 'RUINS',
  smallHeritage: 'SMALL_HERITAGE',
  sport: 'SPORT',
  thermalCenter: 'THERMAL_CENTER',
  toDoToSee: 'TO_DO_TO_SEE',
  toilets: 'TOILETS',
  tourism: 'TOURISM',
  tourismOffice: 'TOURISM_OFFICE',
  tourismSite: 'TOURISM_SITE',
  useful: 'USEFUL',
  viewPoint: 'VIEWPOINT'
};
const poiCategoryCodesMap = exports.poiCategoryCodesMap = {
  ACCOMODATION: 'accomodation',
  ANTICS: 'antics',
  BEACH: 'beach',
  BICYCLE: 'bicycle',
  BICYCLE_HOME: 'bicycleHome',
  BICYCLE_PARKING: 'bicycleParking',
  BICYCLE_PUMP: 'bicyclePump',
  BICYCLE_RENTAL: 'bicycleRental',
  BICYCLE_REPAIR: 'bicycleRepair',
  BnB: 'bnb',
  CAMPING: 'camping',
  CASTLE: 'castle',
  CAVE: 'cave',
  CHARGING_STATION: 'chargingStation',
  COLLECTIVE: 'collective',
  DRINKING_WATER: 'drinkingWater',
  // EVENT: 'event',
  // EVENT_CULTURAL: 'eventCultural',
  // EVENT_FLEA_MARKET: 'eventFleaMarket',
  // EVENT_LOCAL: 'eventLocal',
  // EVENT_MARKET: 'eventMarket',
  // EVENT_SPORT: 'eventSport',
  // EVENT_VISIT: 'eventVisit',
  FORTIFICATION: 'fortification',
  GARDEN: 'garden',
  HOLIDAY_VILLAGE: 'holidayVillage',
  HOTEL: 'hotel',
  INDUSTRY: 'industry',
  LEISURE_PARK: 'leisurePark',
  MUSEUM: 'museum',
  NATURAL_SITE: 'naturalSite',
  PICNIC: 'picnic',
  RELIGIOUS: 'religious',
  REMARKABLE_CITY: 'remarkableCity',
  RESIDENCE: 'residence',
  RESTAURANT: 'restaurant',
  // ROADWORK: 'roadwork',
  RUINS: 'ruins',
  SMALL_HERITAGE: 'smallHeritage',
  SPORT: 'sport',
  THERMAL_CENTER: 'thermalCenter',
  TO_DO_TO_SEE: 'toDoToSee',
  TOILETS: 'toilets',
  TOURISM: 'tourism',
  TOURISM_OFFICE: 'tourismOffice',
  TOURISM_SITE: 'tourismSite',
  USEFUL: 'useful',
  VIEWPOINT: 'viewPoint'
};
const poiCategoryOrders = exports.poiCategoryOrders = {
  bicycleParking: 1,
  bicyclePump: 2,
  bicycleRepair: 3,
  chargingStation: 4,
  drinkingWater: 5,
  toilets: 6,
  picnic: 7,
  bicycleRental: 8,
  accomodation: 9,
  toDoToSee: 10,
  restaurant: 11
};
const poiCategoryLabelKeys = exports.poiCategoryLabelKeys = {
  accomodation: 'commons.poi.categories.accomodation',
  antics: 'commons.poi.categories.antics',
  beach: 'commons.poi.categories.beach',
  bicycle: 'commons.poi.categories.bicycle',
  bicycleHome: 'commons.poi.categories.bicycle_home',
  bicycleParking: 'commons.poi.categories.parking_other',
  bicyclePump: 'commons.poi.categories.bicycle_pump',
  bicycleRental: 'commons.poi.categories.bicycle_rental',
  bicycleRepair: 'commons.poi.categories.bicycle_repair',
  bnb: 'commons.poi.categories.bnb',
  camping: 'commons.poi.categories.camping',
  castle: 'commons.poi.categories.castle',
  cave: 'commons.poi.categories.cave',
  chargingStation: 'commons.poi.categories.charging_station',
  collective: 'commons.poi.categories.collective',
  drinkingWater: 'commons.poi.categories.drinking_water',
  fortification: 'commons.poi.categories.fortification',
  garden: 'commons.poi.categories.garden',
  holidayVillage: 'commons.poi.categories.holiday_village',
  hotel: 'commons.poi.categories.hotel',
  industry: 'commons.poi.categories.industry',
  leisurePark: 'commons.poi.categories.leisure_park',
  museum: 'commons.poi.categories.museum',
  naturalSite: 'commons.poi.categories.natural_site',
  picnic: 'commons.poi.categories.picnic',
  religious: 'commons.poi.categories.religious',
  remarkableCity: 'commons.poi.categories.remarkable_city',
  residence: 'commons.poi.categories.residence',
  restaurant: 'commons.poi.categories.restaurant',
  ruins: 'commons.poi.categories.ruins',
  smallHeritage: 'commons.poi.categories.small_heritage',
  sport: 'commons.poi.categories.sport',
  thermalCenter: 'commons.poi.categories.thermal_center',
  toDoToSee: 'commons.poi.categories.to_do_to_see',
  toilets: 'commons.poi.categories.toilets',
  tourism: 'commons.poi.categories.tourism',
  tourismOffice: 'commons.poi.categories.tourism_office',
  tourismSite: 'commons.poi.categories.tourism_site',
  useful: 'commons.poi.categories.useful',
  viewPoint: 'commons.poi.categories.viewpoint'
};
const poiCategoryColors = exports.poiCategoryColors = {
  accomodation: '#24549e',
  antics: '#957360',
  beach: '#24549e',
  bicycleHome: '#24549e',
  bicyclePump: '#595959',
  bicycleRepair: '#51aa4a',
  bicycleRental: '#957360',
  camping: '#e18d51',
  castle: '#957360',
  chargingStation: '#deaa44',
  drinkingWater: '#2699cb',
  fortification: '#957360',
  garden: '#00885f',
  industry: '#595959',
  museum: '#8551c7',
  naturalSite: '#00885f',
  picnic: '#51aa4a',
  remarkableCity: '#bd64ba',
  restaurant: '#e18d51',
  ruins: '#595959',
  thermalCenter: '#2699cb',
  toDoToSee: '#bd64ba',
  tourismOffice: '#e18d51',
  useful: '#868686',
  viewPoint: '#deaa44'
};
const poiCategoryIcons = exports.poiCategoryIcons = {
  accomodation: _accomodationWhite.default,
  antics: _anticsWhite.default,
  beach: _beachWhite.default,
  bicycleHome: _bicycleHomeWhite.default,
  bicyclePump: _pumpWhite.default,
  bicycleRental: _bicycleRentalWhite.default,
  bicycleRepair: _bicycleRepairWhite.default,
  camping: _campingWhite.default,
  castle: _castleWhite.default,
  chargingStation: _chargingStationWhite.default,
  collective: _collectiveWhite.default,
  drinkingWater: _waterPointWhite.default,
  fortification: _fortificationWhite.default,
  garden: _gardenWhite.default,
  hotel: _accomodationWhite.default,
  industry: _industryWhite.default,
  museum: _museumWhite.default,
  naturalSite: _naturalSiteWhite.default,
  picnic: _picnicWhite.default,
  remarkableCity: _remarkableCityWhite.default,
  restaurant: _restaurantWhite.default,
  ruins: _ruinsWhite.default,
  thermalCenter: _thermalStationWhite.default,
  toDoToSee: _toDoToSeeWhite.default,
  toilets: _toiletsWhite.default,
  tourismOffice: _tourismOfficeWhite.default,
  useful: _usefulWhite.default,
  viewPoint: _viewPointWhite.default
};
function parsePoiCategories(_categories) {
  const parentCodes = ['BICYCLE', 'USEFUL', 'TOURISM'];
  const categories = [{
    id: -1,
    code: 'BICYCLE',
    parent: null
  }, {
    id: -3,
    code: 'TOURISM',
    parent: null
  }, ..._categories].filter(_ref3 => {
    let {
      code
    } = _ref3;
    return code && poiCategoryCodesMap[code];
  }).map(data => {
    if (data.code && ['BICYCLE_PUMP', 'BICYCLE_REPAIR', 'CHARGING_STATION'].includes(data.code)) return {
      ...data,
      parent: -1
    };
    if (data.code && ['BICYCLE_RENTAL', 'ACCOMODATION', 'TO_DO_TO_SEE', 'RESTAURANT'].includes(data.code)) return {
      ...data,
      parent: -3
    };
    return data;
  });
  let order = 1000;
  return parentCodes.reduce((res, _code) => {
    const code = poiCategoryCodesMap[_code];
    const category = categories.find(_ref4 => {
      let {
        code
      } = _ref4;
      return code === _code;
    });
    if (code && category) {
      const parentColor = poiCategoryColors[code];
      const parentIcon = poiCategoryIcons[code];
      const _children = categories.filter(_ref5 => {
        let {
          parent
        } = _ref5;
        return parent === category.id;
      });
      const children = _children.map(_ref6 => {
        let {
          id: childId,
          code: _childCode
        } = _ref6;
        const childCode = poiCategoryCodesMap[_childCode];
        return new _models.PoiCategory(childId, childCode, poiCategoryLabelKeys[childCode], poiCategoryColors[childCode] || parentColor, poiCategoryIcons[childCode] || parentIcon, code, categories.filter(_ref7 => {
          let {
            parent
          } = _ref7;
          return parent === childId;
        }).map(_ref8 => {
          let {
            id: childChildId,
            code: _childChildCode
          } = _ref8;
          const childChildCode = poiCategoryCodesMap[_childChildCode];
          return new _models.PoiCategory(childChildId, childChildCode, poiCategoryLabelKeys[childChildCode], poiCategoryColors[childChildCode] || poiCategoryColors[childCode] || parentColor, poiCategoryIcons[childChildCode] || poiCategoryIcons[childCode] || parentIcon, code, [], poiCategoryOrders[childChildCode] || order++);
        }), poiCategoryOrders[childCode] || order++);
      });
      res.push(new _models.PoiCategory(category.id, code, poiCategoryLabelKeys[code], parentColor, parentIcon, null, children, poiCategoryOrders[code] || order++), ...children);
    }
    return res;
  }, []);
}