"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OriginDestinationService = void 0;
var _models = require("../models");
var _http = require("./http");
class OriginDestinationService {
  static async getFlows(_ref) {
    let {
      startId,
      period,
      timePeriod,
      daysOfWeek,
      departureCyclabilityZoneIds,
      arrivalCyclabilityZoneIds
    } = _ref;
    try {
      const body = {
        start_date: period.from.format('YYYY-MM-DD'),
        end_date: period.to.format('YYYY-MM-DD'),
        days_of_week: daysOfWeek,
        hours_of_day: timePeriod
      };
      if (departureCyclabilityZoneIds) body.cyclability_zone_start_ids = departureCyclabilityZoneIds;
      if (arrivalCyclabilityZoneIds) body.cyclability_zone_end_ids = arrivalCyclabilityZoneIds;
      const data = await _http.HttpService.post('originDestination', '/stats/origin_destination', [], [], JSON.stringify(body));
      return data.map((flow, index) => parseFlows(flow, (startId || 1) + index));
    } catch (err) {
      console.error('[OriginDestinationService][getFlows]', err);
      throw err;
    }
  }
  static async getH3Flows(_ref2) {
    let {
      period,
      timePeriod,
      daysOfWeek,
      departureCyclabilityZoneIds,
      arrivalCyclabilityZoneIds
    } = _ref2;
    try {
      const body = {
        start_date: period.from.format('YYYY-MM-DD'),
        end_date: period.to.format('YYYY-MM-DD'),
        days_of_week: daysOfWeek,
        hours_of_day: timePeriod
      };
      if (departureCyclabilityZoneIds) body.cyclability_zone_start_ids = departureCyclabilityZoneIds;
      if (arrivalCyclabilityZoneIds) body.cyclability_zone_end_ids = arrivalCyclabilityZoneIds;
      const data = await _http.HttpService.post('v1', '/stats/h3_origin_destination', [], [], JSON.stringify(body));
      return data.map(parseH3Flows);
    } catch (err) {
      console.error('[OriginDestinationService][getFlows]', err);
      throw err;
    }
  }
}
exports.OriginDestinationService = OriginDestinationService;
function parseFlows(_ref3, id) {
  let {
    cyclability_zone_start_id,
    cyclability_zone_end_id,
    cyclability_zone_start_reference,
    cyclability_zone_end_reference,
    count
  } = _ref3;
  return new _models.Flow(id, cyclability_zone_start_id, cyclability_zone_end_id, count, {
    origin: cyclability_zone_start_reference || '',
    destination: cyclability_zone_end_reference || ''
  });
}
function parseH3Flows(_ref4) {
  let {
    count,
    h3_start: origin,
    h3_end: destination,
    internal_start: isInternalOrigin,
    internal_end: isInternalDestination
  } = _ref4;
  return {
    count,
    destination,
    isInternalDestination,
    isInternalOrigin,
    origin
  };
}