import { Box, Toolbar } from '@mui/material';
import { ReactNode } from 'react';

import Footer from './footer';
import Header from './header';

interface IProps {
  children: ReactNode;
}

function Layout({ children }: IProps): JSX.Element {
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
      minHeight="100%"
      sx={{ overflowY: 'auto' }}
    >
      <Header />
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Toolbar />
        <Box
          bgcolor="#fcfcfc"
          display="flex"
          flexDirection="column"
          flexGrow={1}
          paddingX={{ xs: 5, xl: 10 }}
          paddingY={5}
        >
          {children}
        </Box>
        <Footer />
      </Box>
    </Box>
  );
}

export default Layout;
