"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toPoint = toPoint;
function toPoint(point) {
  let inverted = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  let lat, lng;
  if (typeof point === 'string') {
    const coords = point.split(',').map(ele => parseFloat(ele));
    [lng, lat] = inverted ? coords.reverse() : coords;
  } else if ('lat' in point) {
    ({
      lat,
      lng
    } = point);
  } else {
    [lng, lat] = point.coordinates;
  }
  return {
    lat,
    lng
  };
}