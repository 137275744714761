"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApiKey = void 0;
class ApiKey {
  constructor(id, label, keyNumber) {
    let creationDate = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    let enabled = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : true;
    this.id = id;
    this.label = label;
    this.keyNumber = keyNumber;
    this.creationDate = creationDate;
    this.enabled = enabled;
  }
  get keyNumberCutted() {
    if (this.keyNumber.length <= 8) return this.keyNumber;
    return this.keyNumber.substring(0, 4) + '...' + this.keyNumber.substring(this.keyNumber.length - 4);
  }
}
exports.ApiKey = ApiKey;