"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.douglasPeucker = douglasPeucker;
exports.getElevationColor = getElevationColor;
exports.getSimplifiedPoints = getSimplifiedPoints;
exports.getSlopePercentages = getSlopePercentages;
exports.slopePercentages = void 0;
var _distance = _interopRequireDefault(require("@turf/distance"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function distanceToPoint(_ref, point) {
  let [p1, p2] = _ref;
  const m = (p2.y - p1.y) / (p2.x - p1.x),
    b = p1.y - m * p1.x,
    d = [];
  d.push(Math.abs(point.y - m * point.x - b) / Math.sqrt(Math.pow(m, 2) + 1));
  d.push(Math.sqrt(Math.pow(point.x - p1.x, 2) + Math.pow(point.y - p1.y, 2)));
  d.push(Math.sqrt(Math.pow(point.x - p2.x, 2) + Math.pow(point.y - p2.y, 2)));
  return d.sort((a, b) => a - b)[0];
}
function douglasPeucker(points, tolerance) {
  if (points.length <= 2) return [points[0]];
  let returnPoints = [];
  const line = [points[0], points[points.length - 1]];
  let maxDistance = 0,
    maxDistanceIndex = 0;
  for (let i = 1; i <= points.length - 2; i++) {
    const distance = distanceToPoint(line, points[i]);
    if (distance > maxDistance) {
      maxDistance = distance;
      maxDistanceIndex = i;
    }
  }
  if (maxDistance >= tolerance) {
    returnPoints = returnPoints.concat(douglasPeucker(points.slice(0, maxDistanceIndex + 1), tolerance), douglasPeucker(points.slice(maxDistanceIndex, points.length), tolerance));
  } else {
    returnPoints = [points[0]];
  }
  return returnPoints;
}
function getSimplifiedPoints(points, step) {
  if (!points[0]) return [];
  const returnPoints = [points[0]];
  let lastConsideredElevation = points[0].y;
  let lastConsideredElevationIndex = 0;
  let index = 1;
  points.slice(1, -1).forEach(point => {
    const prevPoint = points[index - 1];
    if (lastConsideredElevationIndex !== index && Math.abs(point.y - prevPoint.y) >= step) {
      returnPoints.push(prevPoint, point);
      lastConsideredElevation = point.y;
      lastConsideredElevationIndex = index;
    } else if (Math.abs(point.y - lastConsideredElevation) >= step) {
      returnPoints.push(point);
      lastConsideredElevation = point.y;
      lastConsideredElevationIndex = index;
    }
    ++index;
  });
  returnPoints.push(points[points.length - 1]);
  return returnPoints;
}
function getSlopePercentages(coordinates, elevations, _distances) {
  let simplificationStep = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1;
  let distances;
  if (!_distances) {
    let totalDistance = 0;
    distances = coordinates.slice(0, -1).map((position, index) => {
      totalDistance += (0, _distance.default)(position, coordinates[index + 1], {
        units: 'meters'
      });
      return totalDistance;
    });
    distances.unshift(0);
  } else {
    distances = _distances;
  }
  const points = elevations.map((y, index) => {
    if (typeof y === 'number') {
      return {
        index,
        x: distances[index],
        y
      };
    } else {
      return {
        index: y.geometryIndex,
        elevationIndex: index,
        x: distances[y.geometryIndex],
        y: y.elevation
      };
    }
  });
  const simplifiedPoints = getSimplifiedPoints(points, simplificationStep);
  return simplifiedPoints.slice(0, -1).reduce((res, point, index) => {
    const nextPoint = simplifiedPoints[index + 1];
    const d = nextPoint.x - point.x;
    const h = nextPoint.y - point.y;
    const slopePercentage = d ? 100 * h / d : 0;
    res.push(...new Array(point.elevationIndex !== undefined && nextPoint.elevationIndex !== undefined ? nextPoint.elevationIndex - point.elevationIndex : nextPoint.index - point.index).fill(slopePercentage));
    return res;
  }, []);
}
const slopePercentages = exports.slopePercentages = [{
  min: 2,
  color: '#e24949'
}, {
  min: 1,
  color: '#e29c49'
}, {
  min: 0,
  color: '#2ac682'
}];
function getElevationColor(slopePercentage) {
  return slopePercentages.find(_ref2 => {
    let {
      min
    } = _ref2;
    return slopePercentage >= min;
  })?.color || slopePercentages[slopePercentages.length - 1].color;
}