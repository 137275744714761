"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CounterService = void 0;
var _models = require("../models");
var _stats = require("../utils/stats");
var _http = require("./http");
class CounterService {
  static async getCounters() {
    try {
      const data = await _http.HttpService.get('counters', `/counters`);
      const counters = data.map(parseCounter);
      return counters;
    } catch (err) {
      console.error('[CounterService][getCounters]', err);
      throw err;
    }
  }
  static async getCountersStats(period) {
    try {
      const {
        results
      } = await _http.HttpService.get('counters', `/stats/counters/stats`, [...(0, _models.periodToParams)(period), {
        key: 'page_size',
        value: 500
      }, {
        key: 'unit',
        value: 'all'
      }]);
      const stats = results.map(_ref => {
        let {
          counter_id: counterId,
          count
        } = _ref;
        return {
          counterId,
          count
        };
      });
      return stats;
    } catch (err) {
      console.error('[CounterService][getCountersStats]', err);
      throw err;
    }
  }
  static async getCounterStats(counterId, period) {
    try {
      const data = await _http.HttpService.get('counters', `/counters/${counterId}`, [...(0, _models.periodToParams)(period)]);
      const stats = parseCounterStats(data);
      return stats;
    } catch (err) {
      console.error('[CounterService][getCounterStats]', err);
      throw err;
    }
  }
}
exports.CounterService = CounterService;
function parseCounter(_ref2) {
  let {
    id,
    title,
    location,
    address
  } = _ref2;
  return new _models.Counter(id, title, new _models.Place(id, location, {
    primaryText: address
  }), false);
}
function parseCounterStats(_ref3) {
  let {
    stats
  } = _ref3;
  return (stats || []).map(_ref4 => {
    let {
      date: strDate,
      month,
      year,
      count
    } = _ref4;
    const date = (0, _stats.parseStatsDate)({
      strDate,
      month,
      year
    });
    if (!date) throw new Error('invalid stats');
    return {
      date,
      count
    };
  });
}