"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StatsService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _stats = require("../utils/stats");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class StatsService {
  static async getComputedRouteStats(period) {
    try {
      const data = await _http.HttpService.get('stats', '/stats/computed_itinerary', [...(0, _models.periodToParams)(period)]);
      const stats = parseComputedRouteStats(data);
      return stats;
    } catch (err) {
      console.error('[StatsService][getComputedRouteStats]', err);
      throw err;
    }
  }
  static async getOsmWaySections(partnerId) {
    try {
      const {
        bnac_data
      } = await _http.HttpService.get('cyclabilityZones', '/cyclability_zones/tags');
      const {
        geojson
      } = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/osm_way_sections`);
      const facilitiesMap = {};
      Object.keys(bnac_data).forEach(category => {
        bnac_data[category].forEach(facility => facilitiesMap[facility] = category);
      });
      return {
        ...geojson,
        features: geojson.features.map(feature => ({
          ...feature,
          properties: {
            bnacFacilityLeft: feature.properties.facility_left || null,
            bnacFacilityRight: feature.properties.facility_right || null,
            closeFacility: facilitiesMap[feature.properties.close_facility || ''] || 'none',
            facilityLeft: facilitiesMap[feature.properties.facility_left || ''] || 'none',
            facilityRight: facilitiesMap[feature.properties.facility_right || ''] || 'none',
            wayName: feature.properties.way_name || null,
            roadType: feature.properties.road_type || null,
            osmWayId: feature.properties.osm_way_id || null,
            sectionLength: feature.properties.section_length || null
          }
        }), [])
      };
    } catch (err) {
      console.error('[StatsService][getOsmWaySections]', err);
      throw err;
    }
  }
  static async getSectionsSpeedsAndFrequencies(partnerId, period, _ref) {
    let {
      dayPeriod,
      timePeriod,
      departureZone,
      arrivalZone
    } = _ref;
    try {
      const params = [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.to.format('YYYY-MM-DD')
      }];
      if (dayPeriod) params.push({
        key: 'daysOfWeek',
        value: dayPeriod
      });
      if (timePeriod) params.push({
        key: 'timeframe',
        value: timePeriod.toLowerCase()
      });
      if (departureZone) params.push({
        key: 'startZoneId',
        value: departureZone.id
      });
      if (arrivalZone) params.push({
        key: 'endZoneId',
        value: arrivalZone.id
      });
      const res = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/sections_speeds_and_frequencies`, params);
      const stats = res.map(data => parseSectionStats(data));
      return stats;
    } catch (err) {
      console.error('[StatsService][getSectionsSpeedsAndFrequencies]', err);
      throw err;
    }
  }
  static async getSectionsQuality(partnerId, period) {
    try {
      const res = await _http.HttpService.get('roadsQuality', `/stats/partner/${partnerId}/road_quality`, [{
        key: 'monthDate',
        value: period.from.format('YYYY-MM')
      }]);
      const stats = res.map(data => parseSectionStats(data));
      return stats;
    } catch (err) {
      console.error('[StatsService][getRoadsQuality]', err);
      throw new Error('');
    }
  }
  static async getSectionStats(partnerId, sectionId, period) {
    try {
      const params = [{
        key: 'period',
        value: 'custom'
      }, {
        key: 'date_start',
        value: period.from.format('DD-MM-YYYY')
      }, {
        key: 'date_end',
        value: period.to.format('DD-MM-YYYY')
      }];
      const {
        results
      } = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/section_passages/${sectionId}`, params);
      const stats = results.map(data => parseSectionStats(data));
      return stats;
    } catch (err) {
      console.error('[StatsService][getSectionStats]', err);
      throw new Error('');
    }
  }
  static async getTrafficExtrapolation(partnerId, period, dayPeriod, timePeriod) {
    try {
      const {
        traffic_extrapolation
      } = await _http.HttpService.get('trafficExtrapolation', `/stats/partner/${partnerId}/traffic_extrapolation_for_sections`, [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.to.format('YYYY-MM-DD')
      }, {
        key: 'daysOfWeek',
        value: dayPeriod
      }, {
        key: 'timeframe',
        value: timePeriod.toLowerCase()
      }]);
      const stats = traffic_extrapolation.map(data => parseSectionStats(data));
      return stats;
    } catch (err) {
      console.error('[StatsService][getTrafficExtrapolation]', err);
      throw err;
    }
  }
  static async getDistancesTravelled(partnerId, period) {
    let dayPeriod = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'all';
    let timePeriod = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'all_day';
    try {
      const res = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/section_stats_distance_travelled`, [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.to.format('YYYY-MM-DD')
      }, {
        key: 'daysOfWeek',
        value: dayPeriod
      }, {
        key: 'timeframe',
        value: timePeriod.toLowerCase()
      }]);
      return parseDistancesTravelled(res);
    } catch (err) {
      console.error('[StatsService][getDistancesTravelled]', err);
      throw new Error('');
    }
  }
  static async getCyclabilityDistribution(partnerId) {
    try {
      const res = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/cyclability_distribution`, []);
      return res;
    } catch (err) {
      console.error('[StatsService][getCyclabilityDistribution]', err);
      throw new Error('');
    }
  }
  static async getDiscontinuities(_ref2) {
    let {
      partnerId,
      quantity,
      facility_types
    } = _ref2;
    try {
      const params = [];
      if (quantity) params.push({
        key: 'quantity',
        value: quantity
      });
      if (facility_types && facility_types.length > 0) params.push(...facility_types.map(type => ({
        key: 'facility_types[]',
        value: type
      })));
      const res = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/discontinuity`, params);
      return res.map(value => parseDiscontinuity(value));
    } catch (err) {
      console.error('[StatsService][getDiscontinuities]', err);
      throw err;
    }
  }
  static async getDiscontinuityResolutions(_ref3, id) {
    let {
      id: partnerId
    } = _ref3;
    try {
      const res = await _http.HttpService.get('v1', `/stats/partner/${partnerId}/discontinuity/${id}/resolution`);
      return res.map(value => parseDiscontinuityResolution(value));
    } catch (err) {
      console.error('[StatsService][getDiscontinuityResolutions]', err);
      throw err;
    }
  }
  static async getSuggestions(_ref4) {
    let {
      partnerId
    } = _ref4;
    try {
      const res = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/facility-suggestion`, []);
      return res.map(suggestion => parseSuggestion(suggestion));
    } catch (err) {
      console.error('[StatsService][getSuggestions]', err);
      throw new Error('');
    }
  }
  static async createSuggestion(_ref5) {
    let {
      partnerId,
      budget,
      title,
      startDate,
      endDate
    } = _ref5;
    try {
      const data = await _http.HttpService.post('stats', `/stats/partner/${partnerId}/facility-suggestion`, [], [], JSON.stringify({
        budget,
        title,
        start_date: startDate,
        end_date: endDate,
        model: 'BAO_SIMPLE_MODEL'
      }));
      return parseSuggestion(data);
    } catch (err) {
      console.error('[StatsService][createSuggestion]', err);
      throw err;
    }
  }
  static async updateSuggestion(_ref6) {
    let {
      partnerId,
      budget,
      title,
      startDate,
      endDate,
      suggestionId
    } = _ref6;
    try {
      const formData = new FormData();
      if (title) formData.append('title', title);
      if (budget) formData.append('budget', budget.toString());
      if (startDate) formData.append('start_date', startDate);
      if (endDate) formData.append('end_date', endDate);
      const data = await _http.HttpService.patch('stats', `/stats/partner/${partnerId}/facility-suggestion/${suggestionId}`, [], [], formData);
      return parseSuggestion(data);
    } catch (err) {
      console.error('[StatsService][updateSuggestion]', err);
      throw err;
    }
  }
  static async deleteSuggestion(_ref7) {
    let {
      partnerId,
      suggestionId
    } = _ref7;
    try {
      await _http.HttpService.delete('stats', `/stats/partner/${partnerId}/facility-suggestion/${suggestionId}`, [], []);
      return true;
    } catch (err) {
      console.error('[StatsService][deleteSuggestion]', err);
      throw err;
    }
  }
  static async getSuggestionResults(_ref8) {
    let {
      partnerId,
      suggestionId
    } = _ref8;
    try {
      const res = await _http.HttpService.get('stats', `/stats/partner/${partnerId}/facility-suggestion/${suggestionId}/result`, []);
      return res;
    } catch (err) {
      console.error('[StatsService][getSuggestionResults]', err);
      throw new Error('');
    }
  }
}
exports.StatsService = StatsService;
function parseComputedRouteStats(_ref9) {
  let {
    count,
    distance,
    data
  } = _ref9;
  return new _models.ComputedRouteStats(count || 0, distance || 0, data.map(_ref10 => {
    let {
      unit: strDate,
      ...data
    } = _ref10;
    const date = (0, _stats.parseStatsDate)({
      strDate
    });
    if (!date) throw new Error('invalid stats');
    return {
      date,
      ...data
    };
  }));
}
function parseSectionStats(data) {
  if ('frequency_backward' in data) {
    const {
      section_id,
      frequency_backward,
      frequency_onward,
      average_speed_backward,
      average_speed_onward
    } = data;
    return new _models.SectionStats(section_id, frequency_backward, frequency_onward, average_speed_backward, average_speed_onward);
  }
  if ('extrapolation' in data) {
    return new _models.SectionStats(data.section_id, undefined, undefined, undefined, undefined, undefined, undefined, Math.round(data.extrapolation));
  }
  if ('average_speed_going_onward_compared_to_osm_way_direction' in data) {
    return new _models.SectionStats(data.section_id, data.frequency_going_backward_compared_to_osm_way_direction, data.frequency_going_onward_compared_to_osm_way_direction, data.average_speed_going_backward_compared_to_osm_way_direction, data.average_speed_going_onward_compared_to_osm_way_direction, undefined, undefined, undefined, data.distance_travelled_going_backward_compared_to_osm_way_direction, data.distance_travelled_going_onward_compared_to_osm_way_direction, (0, _moment.default)(data.day_date));
  }
  const {
    section_id,
    normalized_backward_roughness,
    normalized_onward_roughness
  } = data;
  return new _models.SectionStats(section_id, 1, 1, undefined, undefined, normalized_backward_roughness.map(value => typeof value === 'number' ? Math.max(0, 4 - value) : null), normalized_onward_roughness.map(value => typeof value === 'number' ? Math.max(0, 4 - value) : null));
}
function parseSuggestion(_ref11) {
  let {
    id,
    created,
    model,
    title,
    partner_id,
    budget,
    start_date,
    end_date,
    resolution_date
  } = _ref11;
  return new _models.Suggestion(id, (0, _moment.default)(created), model, title, partner_id, budget, (0, _moment.default)(start_date), (0, _moment.default)(end_date), resolution_date ? (0, _moment.default)(resolution_date) : null);
}
function parseDiscontinuity(_ref12) {
  let {
    id,
    index,
    section_id,
    section_onward,
    cyclability,
    entering_type,
    contact_facility,
    nb_trace_total,
    nb_trace_discontinuity
  } = _ref12;
  return new _models.Discontinuity(id, section_id, index, section_onward, cyclability === 'very_good' ? 4 : cyclability === 'good' ? 3 : cyclability === 'bad' ? 2 : 1, entering_type, contact_facility, nb_trace_total, nb_trace_discontinuity);
}
function parseDiscontinuityResolution(_ref13) {
  let {
    resolution_section_ids,
    resolution_following_way_direction,
    score,
    distance
  } = _ref13;
  return new _models.DiscontinuityResolution(resolution_section_ids.map((id, index) => ({
    id,
    isOnward: resolution_following_way_direction[index]
  })), score, distance);
}
function parseDistancesTravelled(_ref14) {
  let {
    total,
    cycleway,
    greenway,
    lane,
    sharebusway,
    opposite,
    mixedfacilities,
    forbidden,
    other,
    total_extrapolated
  } = _ref14;
  return {
    cycleway,
    greenway,
    lane,
    mixedfacilities,
    opposite,
    sharebusway,
    forbidden,
    other,
    all: total,
    allExtrapolated: total_extrapolated,
    none: other + forbidden
  };
}