"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.backendJerseyTypesMap = exports.JerseyService = void 0;
var _environment = require("../environment");
var _employee = require("./employee");
var _http = require("./http");
const jerseyTypesMap = {
  distance: 'DISTANCE',
  consecutiveDaysCount: 'CONSECUTIVE_DAYS_COUNT',
  homeWorkJourneysCount: 'WORK_HOME_JOURNEYS_COUNT'
};
class JerseyService {
  static async getJerseyLeaderboard(_ref) {
    let {
      partner: {
        code: partner
      },
      groupId,
      type,
      page,
      pageSize,
      query,
      userId
    } = _ref;
    try {
      const params = [{
        key: 'jersey_type',
        value: jerseyTypesMap[type]
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'exclude_null_progress',
        value: true
      }];
      if (query) params.push({
        key: 'query',
        value: query
      });
      if (userId) params.push({
        key: 'user_id',
        value: userId
      });
      const {
        count,
        results
      } = await _http.HttpService.get('geogroupJerseyLeaderboard', `/groups/${groupId}/jersey_leaderboard`, params, [], null, {
        partner
      });
      const items = parseJerseyLeaderboard(results);
      return {
        count,
        items
      };
    } catch (err) {
      console.error('[JerseyService][getJerseyLeaderboard]', err);
      throw err;
    }
  }
  static async getJerseysHolders(_ref2) {
    let {
      partner: {
        code: partner
      },
      groupId,
      query
    } = _ref2;
    try {
      const params = [];
      if (query) params.push({
        key: 'query',
        value: query
      });
      const results = await _http.HttpService.get('geogroupJerseyLeaderboard', `/groups/${groupId}/jersey_holder`, params, [], null, {
        partner
      });
      const leaderboard = parseJerseyLeaderboard(results);
      return leaderboard;
    } catch (err) {
      console.error('[JerseyService][getJerseysHolders]', err);
      throw err;
    }
  }
}
exports.JerseyService = JerseyService;
const backendJerseyTypesMap = exports.backendJerseyTypesMap = {
  DISTANCE: 'distance',
  CONSECUTIVE_DAYS_COUNT: 'consecutiveDaysCount',
  WORK_HOME_JOURNEYS_COUNT: 'homeWorkJourneysCount'
};
function parseJerseyLeaderboard(items) {
  return items.map(_ref3 => {
    let {
      jersey_type,
      has_jersey: hasJersey,
      leaderboard_index: leaderboardIndex,
      progress_value: progressValue,
      rank,
      user
    } = _ref3;
    return {
      type: backendJerseyTypesMap[jersey_type],
      hasJersey,
      leaderboardIndex,
      progressValue,
      rank,
      user: {
        ...user,
        profilePicture: user.profile_picture ? `${_environment.defaultEnvironment.backendUrl}${user.profile_picture}` : user.profile_picture,
        employee: user.employee[0] ? (0, _employee.parseEmployee)(user.employee[0]) : null
      }
    };
  });
}