"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefRoute = void 0;
class RefRoute {
  constructor(id, creator, created, computedRouteId, profile, wayPoints, geometry, expectedGeometry, description, status, successDate) {
    this.id = id;
    this.creator = creator;
    this.created = created;
    this.computedRouteId = computedRouteId;
    this.profile = profile;
    this.wayPoints = wayPoints;
    this.geometry = geometry;
    this.expectedGeometry = expectedGeometry;
    this.description = description;
    this.status = status;
    this.successDate = successDate;
  }
  clone() {
    return new RefRoute(this.id, this.creator, this.created, this.computedRouteId, this.profile, this.wayPoints, this.geometry, this.expectedGeometry, this.description, this.status, this.successDate);
  }
}
exports.RefRoute = RefRoute;