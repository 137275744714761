"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CoveloContact = void 0;
class CoveloContact {
  constructor(id, created, email, phoneNumber, route, message) {
    this.id = id;
    this.created = created;
    this.email = email;
    this.phoneNumber = phoneNumber;
    this.route = route;
    this.message = message;
  }
}
exports.CoveloContact = CoveloContact;