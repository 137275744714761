"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DirectRoutingService = void 0;
class DirectRoutingService {
  static async getRoute(wayPoints, profile, env, calculator, bearing, excludable) {
    try {
      const result = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        const coordinates = wayPoints.reduce((res, wayPoint, index) => {
          if (wayPoint && index !== 0) res += ';';
          res += wayPoint?.asParam;
          return res;
        }, '');
        let url = `https://routing-${calculator}-${profile}-${env}.geovelo.fr/route/${coordinates}?overview=full&geometries=geojson`;
        if (bearing) url += `&bearings=${bearing}`;
        if (excludable) url += `&exclude=${excludable}`;
        request.open('GET', url, true);
        request.responseType = 'json';
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
        };
        request.send();
      });
      return parseRoute(result.routes[0]);
    } catch (err) {
      console.error('[DirectRoutingService][getRoute]', err);
      throw err;
    }
  }
  static async getRouteFromSpecificUrl(_url, wayPoints, bearing, excludable) {
    try {
      const result = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        const coordinates = wayPoints.reduce((res, wayPoint, index) => {
          if (wayPoint && index !== 0) res += ';';
          res += wayPoint?.asParam;
          return res;
        }, '');
        let url = `${_url}/route${_url.includes('localhost') ? '/v1/toto/' : '/'}${coordinates}?overview=full&geometries=geojson`;
        if (bearing) url += `&bearings=${bearing}`;
        if (excludable) url += `&exclude=${excludable}`;
        request.open('GET', url, true);
        request.responseType = 'json';
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
        };
        request.send();
      });
      return parseRoute(result.routes[0]);
    } catch (err) {
      console.error('[DirectRoutingService][getRouteFromSpecificUrl]', err);
      throw err;
    }
  }
}
exports.DirectRoutingService = DirectRoutingService;
function parseRoute(_ref) {
  let {
    distance,
    duration,
    elevation_gain,
    elevation_loss,
    geometry,
    weight
  } = _ref;
  return {
    distance: distance,
    duration: duration,
    elevation_gain: elevation_gain,
    elevation_loss: elevation_loss,
    geometry: geometry,
    weight: weight
  };
}