"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ApiService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class ApiService {
  static async getApiKeys(_ref) {
    let {
      id: partnerId
    } = _ref;
    try {
      const data = await _http.HttpService.get('apiAccounts', `/partners/${partnerId}/api_accounts`);
      const apiKeys = data.map(parseApiKey);
      return apiKeys;
    } catch (err) {
      console.error('[ApiService][getApiKeys]', err);
      return [];
    }
  }
  static async getApiStats(currentPartner, period, _ref2) {
    let {
      id: selectedApiKeyId
    } = _ref2;
    try {
      let apiStats;
      if (selectedApiKeyId !== 'all') {
        const res = await _http.HttpService.get('apiAccounts', `/api_accounts/${selectedApiKeyId}/stats`, [...(0, _models.periodToParams)(period)]);

        // TODO: remove those lines if fixed in server side
        const statsByDate = {};
        res.forEach(data => {
          if (!statsByDate[data.date]) statsByDate[data.date] = data;
        });
        apiStats = Object.values(statsByDate).map(parseApiStats);
      } else {
        const res = await _http.HttpService.get('apiAccounts', `/partner/${currentPartner.id}/api_accounts_stats`, [...(0, _models.periodToParams)(period)]);
        const statsByDate = {};
        res.forEach(_ref3 => {
          let {
            stats
          } = _ref3;
          stats.forEach(_ref4 => {
            let {
              date,
              count,
              count_compute_itinerary,
              count_compute_itinerary_light,
              count_m2m,
              maximum_response_time,
              maximum_response_time_compute_itinerary,
              maximum_response_time_compute_itinerary_light,
              maximum_response_time_m2m,
              median_response_time,
              median_response_time_compute_itinerary,
              median_response_time_compute_itinerary_light,
              median_response_time_m2m,
              minimum_response_time,
              minimum_response_time_compute_itinerary,
              minimum_response_time_compute_itinerary_light,
              minimum_response_time_m2m
            } = _ref4;
            if (!statsByDate[date]) {
              statsByDate[date] = {
                date,
                count: 0,
                count_compute_itinerary: 0,
                count_compute_itinerary_light: 0,
                count_m2m: 0,
                maximum_response_time: null,
                maximum_response_time_compute_itinerary: null,
                maximum_response_time_compute_itinerary_light: null,
                maximum_response_time_m2m: null,
                median_response_time: null,
                median_response_time_compute_itinerary: null,
                median_response_time_compute_itinerary_light: null,
                median_response_time_m2m: null,
                minimum_response_time: null,
                minimum_response_time_compute_itinerary: null,
                minimum_response_time_compute_itinerary_light: null,
                minimum_response_time_m2m: null
              };
            }
            const {
              count: old_count,
              count_compute_itinerary: old_count_compute_itinerary,
              count_compute_itinerary_light: old_count_compute_itinerary_light,
              count_m2m: old_count_m2m,
              maximum_response_time: old_maximum_response_time,
              maximum_response_time_compute_itinerary: old_maximum_response_time_compute_itinerary,
              maximum_response_time_compute_itinerary_light: old_maximum_response_time_compute_itinerary_light,
              maximum_response_time_m2m: old_maximum_response_time_m2m,
              median_response_time: old_median_response_time,
              median_response_time_compute_itinerary: old_median_response_time_compute_itinerary,
              median_response_time_compute_itinerary_light: old_median_response_time_compute_itinerary_light,
              median_response_time_m2m: old_median_response_time_m2m,
              minimum_response_time: old_minimum_response_time,
              minimum_response_time_compute_itinerary: old_minimum_response_time_compute_itinerary,
              minimum_response_time_compute_itinerary_light: old_minimum_response_time_compute_itinerary_light,
              minimum_response_time_m2m: old_minimum_response_time_m2m
            } = statsByDate[date];
            statsByDate[date] = {
              ...statsByDate[date],
              count: old_count + count,
              count_compute_itinerary: old_count_compute_itinerary + count_compute_itinerary,
              count_compute_itinerary_light: old_count_compute_itinerary_light + count_compute_itinerary_light,
              count_m2m: old_count_m2m + count_m2m,
              maximum_response_time: maximum_response_time !== null ? Math.max(old_maximum_response_time || -1, maximum_response_time) : old_maximum_response_time,
              maximum_response_time_compute_itinerary: maximum_response_time_compute_itinerary !== null ? Math.max(old_maximum_response_time_compute_itinerary || -1, maximum_response_time_compute_itinerary) : old_maximum_response_time_compute_itinerary,
              maximum_response_time_compute_itinerary_light: maximum_response_time_compute_itinerary_light !== null ? Math.max(old_maximum_response_time_compute_itinerary_light || -1, maximum_response_time_compute_itinerary_light) : old_maximum_response_time_compute_itinerary_light,
              maximum_response_time_m2m: maximum_response_time_m2m !== null ? Math.max(old_maximum_response_time_m2m || -1, maximum_response_time_m2m) : old_maximum_response_time_m2m,
              median_response_time: (old_median_response_time || 0) + (median_response_time || 0) * count,
              median_response_time_compute_itinerary: (old_median_response_time_compute_itinerary || 0) + (median_response_time_compute_itinerary || 0) * count_compute_itinerary,
              median_response_time_compute_itinerary_light: (old_median_response_time_compute_itinerary_light || 0) + (median_response_time_compute_itinerary_light || 0) * count_compute_itinerary_light,
              median_response_time_m2m: (old_median_response_time_m2m || 0) + (median_response_time_m2m || 0) * count_m2m,
              minimum_response_time: minimum_response_time !== null ? Math.min(old_minimum_response_time || Number.MAX_VALUE, minimum_response_time) : old_minimum_response_time,
              minimum_response_time_compute_itinerary: minimum_response_time_compute_itinerary !== null ? Math.min(old_minimum_response_time_compute_itinerary || Number.MAX_VALUE, minimum_response_time_compute_itinerary) : old_minimum_response_time_compute_itinerary,
              minimum_response_time_compute_itinerary_light: minimum_response_time_compute_itinerary_light !== null ? Math.min(old_minimum_response_time_compute_itinerary_light || Number.MAX_VALUE, minimum_response_time_compute_itinerary_light) : old_minimum_response_time_compute_itinerary_light,
              minimum_response_time_m2m: minimum_response_time_m2m !== null ? Math.min(old_minimum_response_time_m2m || Number.MAX_VALUE, minimum_response_time_m2m) : old_minimum_response_time_m2m
            };
          });
        });
        Object.keys(statsByDate).forEach(key => {
          const {
            count,
            count_compute_itinerary,
            count_compute_itinerary_light,
            count_m2m,
            median_response_time,
            median_response_time_compute_itinerary,
            median_response_time_compute_itinerary_light,
            median_response_time_m2m
          } = statsByDate[key];
          statsByDate[key] = {
            ...statsByDate[key],
            median_response_time: count && median_response_time !== null ? median_response_time / count : null,
            median_response_time_compute_itinerary: count_compute_itinerary && median_response_time_compute_itinerary !== null ? median_response_time_compute_itinerary / count_compute_itinerary : null,
            median_response_time_compute_itinerary_light: count_compute_itinerary_light && median_response_time_compute_itinerary_light !== null ? median_response_time_compute_itinerary_light / count_compute_itinerary_light : null,
            median_response_time_m2m: count_m2m && median_response_time_m2m !== null ? median_response_time_m2m / count_m2m : null
          };
          if (count === 0) {
            statsByDate[key].median_response_time = null;
            statsByDate[key].median_response_time_compute_itinerary = null;
            statsByDate[key].median_response_time_compute_itinerary_light = null;
            statsByDate[key].median_response_time_m2m = null;
          } else {
            statsByDate[key].median_response_time = (median_response_time || 0) / count;
            statsByDate[key].median_response_time_compute_itinerary = (median_response_time_compute_itinerary || 0) / count;
            statsByDate[key].median_response_time_compute_itinerary_light = (median_response_time_compute_itinerary_light || 0) / count;
            statsByDate[key].median_response_time_m2m = (median_response_time_m2m || 0) / count;
          }
        });
        apiStats = Object.values(statsByDate).map(parseApiStats);
      }
      return apiStats;
    } catch (err) {
      console.error('[ApiService][getApiStats]', err);
      return [];
    }
  }
  static async createApiKey(_ref5, label) {
    let {
      id: partnerId
    } = _ref5;
    try {
      const data = await _http.HttpService.post('apiAccounts', `/partners/${partnerId}/api_accounts`, [], [], JSON.stringify({
        title: label
      }));
      const apiKey = parseApiKey(data);
      return apiKey;
    } catch (err) {
      console.error('[ApiService][createApiKey]', err);
      return null;
    }
  }
}
exports.ApiService = ApiService;
function parseApiKey(_ref6) {
  let {
    id,
    created,
    title,
    api_key,
    enabled
  } = _ref6;
  return new _models.ApiKey(id, title, api_key, (0, _moment.default)(created), enabled);
}
function parseApiStats(_ref7) {
  let {
    date,
    count,
    minimum_response_time,
    maximum_response_time,
    median_response_time,
    count_compute_itinerary,
    minimum_response_time_compute_itinerary,
    maximum_response_time_compute_itinerary,
    median_response_time_compute_itinerary,
    count_compute_itinerary_light,
    minimum_response_time_compute_itinerary_light,
    maximum_response_time_compute_itinerary_light,
    median_response_time_compute_itinerary_light,
    count_m2m,
    minimum_response_time_m2m,
    maximum_response_time_m2m,
    median_response_time_m2m
  } = _ref7;
  return new _models.ApiStats((0, _moment.default)(date), {
    routeComputer: {
      count: count_compute_itinerary || 0,
      minimumResponseTime: minimum_response_time_compute_itinerary,
      maximumResponseTime: maximum_response_time_compute_itinerary,
      medianResponseTime: median_response_time_compute_itinerary
    },
    m2m: {
      count: count_m2m || 0,
      minimumResponseTime: minimum_response_time_m2m,
      maximumResponseTime: maximum_response_time_m2m,
      medianResponseTime: median_response_time_m2m
    },
    routeComputedSimplified: {
      count: count_compute_itinerary_light || 0,
      minimumResponseTime: minimum_response_time_compute_itinerary_light,
      maximumResponseTime: maximum_response_time_compute_itinerary_light,
      medianResponseTime: median_response_time_compute_itinerary_light
    },
    all: {
      count,
      minimumResponseTime: minimum_response_time,
      maximumResponseTime: maximum_response_time,
      medianResponseTime: median_response_time
    }
  });
}