"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.reportTypesMap = exports.reportTypes = exports.ReportType = void 0;
var _blockedRoadWhite = _interopRequireDefault(require("../assets/images/report/blocked-road-white.svg"));
var _dangerousRoadWhite = _interopRequireDefault(require("../assets/images/report/dangerous-road-white.svg"));
var _mapErrorWhite = _interopRequireDefault(require("../assets/images/report/map-error-white.svg"));
var _missingFacilityWhite = _interopRequireDefault(require("../assets/images/report/missing-facility-white.svg"));
var _missingParkingWhite = _interopRequireDefault(require("../assets/images/report/missing-parking-white.svg"));
var _missingPoiWhite = _interopRequireDefault(require("../assets/images/report/missing-poi-white.svg"));
var _poorQualityRoadWhite = _interopRequireDefault(require("../assets/images/report/poor-quality-road-white.svg"));
var _roadworksWhite = _interopRequireDefault(require("../assets/images/report/roadworks-white.svg"));
var _report = require("../components/icons/report");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
const reportTypes = exports.reportTypes = ['bikeFacility', 'bikeParking', 'cartographicIssue', 'toDoToSee', 'dangerous', 'roadwork', 'roadBlocked', 'pothole'];
class ReportType {
  constructor(id, code, isBindToOSM, titleKey) {
    this.id = id;
    this.code = code;
    this.isBindToOSM = isBindToOSM;
    this.titleKey = titleKey;
  }
}
exports.ReportType = ReportType;
const reportTypesMap = exports.reportTypesMap = {
  bikeFacility: {
    Icon: _report.MissingFacilityIcon,
    color: '#03966a',
    icon: _missingFacilityWhite.default
  },
  bikeParking: {
    Icon: _report.MissingParkingIcon,
    color: '#326ac2',
    icon: _missingParkingWhite.default
  },
  cartographicIssue: {
    Icon: _report.MapErrorIcon,
    color: '#28a5db',
    icon: _mapErrorWhite.default
  },
  dangerous: {
    Icon: _report.DangerousRoadIcon,
    color: '#dd428d',
    icon: _dangerousRoadWhite.default
  },
  pothole: {
    Icon: _report.PoorQualityRoadIcon,
    color: '#a066eb',
    icon: _poorQualityRoadWhite.default
  },
  roadwork: {
    Icon: _report.RoadworksIcon,
    color: '#ee7734',
    icon: _roadworksWhite.default
  },
  roadBlocked: {
    Icon: _report.BlockedRoadIcon,
    color: '#212121',
    icon: _blockedRoadWhite.default
  },
  toDoToSee: {
    Icon: _report.MissingPoiIcon,
    color: '#ecb54b',
    icon: _missingPoiWhite.default
  }
};