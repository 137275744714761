"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useAmplitudeTracker = useAmplitudeTracker;
exports.useTracker = useTracker;
var _analyticsBrowser = require("@amplitude/analytics-browser");
var _matomoTrackerReact = require("@jonkoops/matomo-tracker-react");
var _react = require("react");
var _services = require("../services");
/* eslint-disable @typescript-eslint/no-explicit-any */

function useTracker() {
  const {
    trackPageView: _trackPageView,
    trackEvent: _trackEvent
  } = (0, _matomoTrackerReact.useMatomo)();
  const lastPathname = (0, _react.useRef)();
  function trackPageView() {
    if (!_services.AppService.disableTracking && lastPathname.current !== window.location.pathname) _trackPageView({});
    lastPathname.current = window.location.pathname;
  }
  function trackEvent(category, action, name, value) {
    if (!_services.AppService.disableTracking) _trackEvent({
      category,
      action,
      name,
      value
    });
  }
  return {
    trackPageView,
    trackEvent
  };
}
function useAmplitudeTracker() {
  const lastPathname = (0, _react.useRef)();
  function trackEvent(name, properties) {
    const currentUser = _services.UserService.currentUser;
    if (name !== 'Page Visited' || lastPathname.current !== window.location.pathname) {
      (0, _analyticsBrowser.track)(name, {
        geovelo_user_id: currentUser?.id,
        is_staff: currentUser?.isGeovelo || currentUser?.isEcoCompteur,
        ...properties,
        env: process.env.REACT_APP_ENV || process.env.GATSBY_APP_ENV
      });
      lastPathname.current = window.location.pathname;
    }
  }
  return {
    trackEvent
  };
}