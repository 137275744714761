"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BikeRoute = void 0;
var _utils = require("../utils");
class BikeRoute {
  constructor(id, creator, title, description, distance, area, iconUrl, iconTitle, center, condensedGeometry, condensedGeometryReturn, stepsCount, rides, photos, medias, primaryColor, secondaryColor, publicationStatus) {
    this.id = id;
    this.creator = creator;
    this.title = title;
    this.description = description;
    this.distance = distance;
    this.area = area;
    this.iconUrl = iconUrl;
    this.iconTitle = iconTitle;
    this.center = center;
    this.condensedGeometry = condensedGeometry;
    this.condensedGeometryReturn = condensedGeometryReturn;
    this.stepsCount = stepsCount;
    this.rides = rides;
    this.photos = photos;
    this.medias = medias;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.publicationStatus = publicationStatus;
    this.bounds = condensedGeometry ? (0, _utils.toBounds)(condensedGeometry) : undefined;
    if (this.bounds && !this.center) this.center = {
      type: 'Point',
      coordinates: [(this.bounds.east + this.bounds.west) / 2, (this.bounds.north + this.bounds.south) / 2]
    };
  }
}
exports.BikeRoute = BikeRoute;