"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useLayers = useLayers;
function useLayers(map) {
  function initListeners(layerId) {
    map?.on('mousemove', layerId, _ref => {
      let {
        features
      } = _ref;
      if (features && features.length > 0 && features[0].properties?.movable) {
        map.getCanvas().style.cursor = 'move';
      }
    });
    map?.on('mouseleave', layerId, () => {
      map.getCanvas().style.cursor = '';
    });
  }
  function addCircleLayer(layerId, sourceId, paint) {
    let {
      before,
      filter,
      disableCursorChange
    } = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
    if (!map || map.getLayer(layerId)) return;
    map.addLayer({
      id: layerId,
      type: 'circle',
      source: sourceId,
      paint: {
        ...paint
      },
      ...(filter ? {
        filter
      } : {})
    }, before);
    if (!disableCursorChange) initListeners(layerId);
  }
  function addLineLayer(layerId, sourceId, layout, paint) {
    let {
      filter,
      before
    } = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};
    if (!map || map.getLayer(layerId)) return;
    map.addLayer({
      id: layerId,
      type: 'line',
      source: sourceId,
      layout: {
        ...layout
      },
      paint: {
        ...paint
      },
      ...(filter ? {
        filter
      } : {})
    }, before || 'labels');
    initListeners(layerId);
  }
  function getLayerOpacities(layers, sourceId) {
    const layerOpacities = {};
    layers.forEach(layer => {
      if ('source' in layer && layer.source === sourceId) {
        if (layer.type === 'fill') {
          layerOpacities[layer.id] = layer.paint?.['fill-opacity'];
        } else if (layer.type === 'line') {
          layerOpacities[layer.id] = layer.paint?.['line-opacity'];
        }
      }
    });
    return layerOpacities;
  }
  return {
    addCircleLayer,
    addLineLayer,
    getLayerOpacities
  };
}