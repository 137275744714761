"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EventService = void 0;
var _centroid = _interopRequireDefault(require("@turf/centroid"));
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _app = require("./app");
var _geogroup = require("./geogroup");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class EventService {
  static async getAvailableEvents(_ref) {
    let {
      partner: {
        code: partner
      },
      geogroupId,
      page,
      pageSize,
      state,
      automaticSubscription
    } = _ref;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }];
      if (state) state.forEach(value => params.push({
        key: 'state[]',
        value
      }));
      if (automaticSubscription) params.push({
        key: 'automatic_subscription',
        value: automaticSubscription
      });
      const {
        results
      } = await _http.HttpService.get('v2', `/geogroups/${geogroupId}/available_events`, params, [], null, {
        partner
      });
      return results.map(data => parseEvent(data, _app.AppService.environment.backendUrl));
    } catch (err) {
      console.error('[EventService][getAvailableEvents]', err);
      throw err;
    }
  }
  static async getEvents(_ref2) {
    let {
      period,
      search,
      ordering,
      page,
      pageSize,
      query
    } = _ref2;
    try {
      const params = [{
        key: 'state[]',
        value: period === 'current' ? 'running' : period === 'future' ? 'future' : 'finished'
      }, {
        key: 'ordering',
        value: ordering
      }, {
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }];
      if (search) params.push({
        key: 'search',
        value: search
      });
      const {
        count,
        results
      } = await _http.HttpService.get('v3', `/events`, params);
      const events = results.map(data => parseEvent(data, _app.AppService.environment.backendUrl));
      return {
        count,
        events
      };
    } catch (err) {
      console.error('[EventService][getEvents]', err);
      throw err;
    }
  }
  static async getEvent(id) {
    try {
      const data = await _http.HttpService.get('v3', `/events/${id}`);
      return parseEvent(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[EventService][getEvent]', err);
      throw err;
    }
  }
  static async getRanking(eventId, _ref3) {
    let {
      page,
      pageSize,
      groupTypes,
      size,
      cyclabilityZoneId,
      search
    } = _ref3;
    try {
      const queryParams = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }];
      if (groupTypes.length > 0) queryParams.push({
        key: 'geogroup_type',
        value: groupTypes.map(key => _geogroup.typesMap[key]).join(',')
      });
      if (size?.min) queryParams.push({
        key: 'size_range_min',
        value: size.min
      });
      if (size?.max) queryParams.push({
        key: 'size_range_max',
        value: size.max
      });
      if (cyclabilityZoneId) queryParams.push({
        key: 'in_cyclability_zone',
        value: cyclabilityZoneId
      });
      if (search) queryParams.push({
        key: 'search',
        value: search
      });
      const {
        count,
        results
      } = await _http.HttpService.get('eventsV3', `/events/${eventId}/ranking`, queryParams);
      return {
        count,
        items: results.map(data => parseEventRankingItem(data, _app.AppService.environment.backendUrl))
      };
    } catch (err) {
      console.error('[EventService][getRanking]', err);
      throw err;
    }
  }
  static async getCyclabilityZones(eventId) {
    try {
      const res = await _http.HttpService.get('events', `/events/${eventId}/cyclability_zones`);
      return res.map(_ref4 => {
        let {
          region_id: regionId,
          epcis
        } = _ref4;
        return {
          regionId,
          children: epcis.map(_ref5 => {
            let {
              epci_id: id,
              epci_name: name
            } = _ref5;
            return {
              id,
              name
            };
          })
        };
      });
    } catch (err) {
      console.error('[EventService][getCyclabilityZones]', err);
      throw err;
    }
  }
  static async addGeogroup(eventId, _ref6) {
    let {
      type,
      name,
      min,
      max,
      address,
      sameLocation,
      cyclabilityZone,
      icon,
      email
    } = _ref6;
    try {
      let data = {
        type: _geogroup.typesMap[type],
        title: name,
        code: name.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replace(/\s+/g, '_'),
        size_range: {
          min: min || 0,
          max: max || 100_000_000
        },
        admin: {
          email
        }
      };
      if (address) data = {
        ...data,
        geo_point: address.point,
        address: address.address
      };else if (cyclabilityZone) {
        const {
          geometry
        } = cyclabilityZone;
        if (!geometry) throw new Error('cyclability zone has no geometry');
        data = {
          ...data,
          geo_point: (0, _centroid.default)(geometry).geometry,
          cyclability_zone: cyclabilityZone.id
        };
      }
      if (sameLocation !== undefined) data.is_local = sameLocation;
      const formData = new FormData();
      formData.append('data', JSON.stringify(data));
      if (icon) formData.append('photo', icon);
      const props = await _http.HttpService.post('events', `/events/${eventId}/geogroups`, [], [], formData);
      return (0, _geogroup.parseGeogroup)(props, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[EventService][addGeogroup]', err);
      throw err;
    }
  }
}
exports.EventService = EventService;
function parseEvent(_ref7, backendUrl) {
  let {
    id,
    title,
    start_datetime,
    end_datetime,
    challenge_template,
    scope_permited_contract_codes,
    scope_permited_group_types,
    challenges_count,
    challenges_progress_value
  } = _ref7;
  return new _models.Event(id, title, (0, _moment.default)(start_datetime), (0, _moment.default)(end_datetime), challenge_template ? (0, _geogroup.parseChallengeTemplate)(id, {
    ...challenge_template,
    title,
    start_datetime,
    end_datetime
  }, backendUrl) : null, scope_permited_contract_codes || [], scope_permited_group_types || [], challenges_count || 0, challenges_progress_value || 0);
}
function parseEventRankingItem(_ref8, backendUrl) {
  let {
    id,
    title,
    type,
    photo,
    parent_id,
    parent_title,
    parent_photo,
    rank,
    leaderboard_index,
    progress_value
  } = _ref8;
  return {
    id,
    title,
    type: _geogroup.backendTypesMap[type],
    photo: photo ? `${backendUrl}${photo}` : null,
    parent_id,
    parent_title,
    parent_photo: parent_photo ? `${backendUrl}${parent_photo}` : null,
    rank,
    leaderboard_index,
    progress_value
  };
}