"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.IfpenFlow = exports.Flow = void 0;
class Flow {
  constructor(id, origin, destination, count, references) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.count = count;
    this.references = references;
  }
}
exports.Flow = Flow;
class IfpenFlow {
  constructor(id, origin, destination, count) {
    this.id = id;
    this.origin = origin;
    this.destination = destination;
    this.count = count;
  }
}
exports.IfpenFlow = IfpenFlow;