"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parseStatsDate = parseStatsDate;
var _moment = _interopRequireDefault(require("moment"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
function parseStatsDate(_ref) {
  let {
    strDate,
    month,
    year
  } = _ref;
  if (year) {
    if (month !== undefined) return (0, _moment.default)(new Date(year, month - 1));
    return (0, _moment.default)(new Date(year));
  }
  if (!strDate) return null;
  const parts = strDate.split('-');
  if (parts.length === 3) return (0, _moment.default)(strDate);
  if (parts.length === 2) return (0, _moment.default)(`${strDate}-1`);
  return null;
}