"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Isochrone = void 0;
var _utils = require("../utils");
class Isochrone {
  constructor(duration, geometry) {
    this.duration = duration;
    this.geometry = geometry;
    this.bounds = (0, _utils.toBounds)(geometry);
  }
}
exports.Isochrone = Isochrone;