"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.MobilitySurveyService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _environment = require("../environment");
var _models = require("../models");
var _fmdParameters = require("./fmd-parameters");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class MobilitySurveyService {
  static async getSurveys(_ref) {
    let {
      partner
    } = _ref;
    try {
      const results = await _http.HttpService.get('v1', `/partners/${partner.id}/mobility_surveys`, [], [], null, {
        partner: partner.code
      });
      return results.map(parseMobilitySurvey);
    } catch (err) {
      console.error('[MobilitySurveyService][getSurveys]', err);
      throw err;
    }
  }
  static async getSurvey(_ref2) {
    let {
      code
    } = _ref2;
    try {
      const data = await _http.HttpService.get('v1', `/mobility_surveys/${code}`);
      return parseMobilitySurvey(data);
    } catch (err) {
      console.error('[MobilitySurveyService][getSurvey]', err);
      throw err;
    }
  }
  static async createSurvey(_ref3) {
    let {
      partner
    } = _ref3;
    try {
      const data = await _http.HttpService.post('v1', `/partners/${partner.id}/mobility_surveys`, [], [], JSON.stringify({
        partner: partner.id
      }), {
        partner: partner.code
      });
      return parseMobilitySurvey(data);
    } catch (err) {
      console.error('[MobilitySurveyService][createSurvey]', err);
      throw err;
    }
  }
  static async respond(_ref4) {
    let {
      survey,
      respondentEmail: respondent_email,
      duration: commute_duration_in_minutes,
      daysPerWeek: average_commute_count_by_week,
      userHome,
      userWork,
      transportModes: _transportModes,
      workGeogroupId,
      computedRoute,
      emittedCO2PerYear: estimated_emitted_co2_kg_per_year
    } = _ref4;
    try {
      const data = await _http.HttpService.post('v1', `/mobility_surveys/${survey.code}/responses`, [], [], JSON.stringify({
        survey: survey.id,
        respondent_email,
        commute_duration_in_minutes,
        average_commute_count_by_week,
        transportation_modes: _transportModes.map(key => transportModes[key]),
        home_geo_point: `SRID=4326;POINT (${userHome.point.coordinates.join(' ')})`,
        home_address: userHome.address,
        work_geo_point: `SRID=4326;POINT (${userWork.point.coordinates.join(' ')})`,
        work_address: userWork.address,
        ...(workGeogroupId !== null ? {
          work_site_id: workGeogroupId
        } : {}),
        bike_itinerary_duration_in_seconds: computedRoute.duration,
        bike_itinerary_distance_in_meters: computedRoute.distances.total,
        estimated_emitted_co2_kg_per_year
      }));
      return parseMobilitySurveyResponse(data);
    } catch (err) {
      console.error('[MobilitySurveyService][respond]', err);
      throw err;
    }
  }
  static async getResponses(_ref5) {
    let {
      partner,
      survey
    } = _ref5;
    try {
      const res = await _http.HttpService.get('v1', `/partners/${partner.id}/mobility_surveys/${survey.id}/results`, [], [], null, {
        partner: partner.code
      });
      return res.map(parseMobilitySurveyResponse);
    } catch (err) {
      console.error('[MobilitySurveyService][getResponses]', err);
      throw err;
    }
  }
}
exports.MobilitySurveyService = MobilitySurveyService;
const transportModes = {
  bicycle: 'BIKE',
  car: 'CAR',
  carpooling: 'CAR_POOLING',
  motorbike: 'MOTORBIKE',
  publicTransport: 'PUBLIC_TRANSPORT',
  scooter: 'SCOOTER',
  walk: 'WALKING'
};
const backendTransportModes = {
  BIKE: 'bicycle',
  CAR: 'car',
  CAR_POOLING: 'carpooling',
  MOTORBIKE: 'motorbike',
  PUBLIC_TRANSPORT: 'publicTransport',
  SCOOTER: 'scooter',
  WALKING: 'walk'
};
function parseMobilitySurveyPartner(_ref6) {
  let {
    title,
    icon,
    fmd_parameters,
    geo_group
  } = _ref6;
  return {
    title,
    icon: icon ? `${_environment.defaultEnvironment.backendUrl}${icon}` : null,
    fmdConfig: fmd_parameters ? (0, _fmdParameters.parseFMDParameters)(fmd_parameters) : null,
    geogroup: {
      id: geo_group.id,
      title: geo_group.title,
      place: new _models.Place(-1, geo_group.geo_point, {
        primaryText: geo_group.address
      })
    },
    sites: (geo_group.sub_groups || []).filter(_ref7 => {
      let {
        sub_group_type
      } = _ref7;
      return sub_group_type === 'COMPANY_SITE';
    }).map(_ref8 => {
      let {
        id,
        title,
        geo_point,
        address
      } = _ref8;
      return {
        id,
        title,
        place: new _models.Place(-1, geo_point, {
          primaryText: address
        })
      };
    })
  };
}
function parseMobilitySurvey(_ref9) {
  let {
    id,
    code,
    created,
    updated,
    closed,
    partner
  } = _ref9;
  return {
    id,
    code,
    created: (0, _moment.default)(created),
    updated: (0, _moment.default)(updated),
    closed: (0, _moment.default)(closed),
    partner: partner && typeof partner !== 'number' ? parseMobilitySurveyPartner(partner) : undefined
  };
}
function parseMobilitySurveyResponse(_ref10) {
  let {
    average_commute_count_by_week: daysPerWeek,
    bike_itinerary_distance_in_meters: computedRouteDistance,
    bike_itinerary_duration_in_seconds: computedRouteDuration,
    commute_duration_in_minutes: duration,
    transportation_modes,
    work_site_id: workGeogroupId,
    estimated_emitted_co2_kg_per_year: emittedCO2PerYear
  } = _ref10;
  return {
    daysPerWeek,
    computedRouteDistance,
    computedRouteDuration,
    duration,
    transportationModes: transportation_modes.map(key => backendTransportModes[key]),
    workGeogroupId,
    emittedCO2PerYear
  };
}