"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BrevoService = void 0;
var _app = require("./app");
class BrevoService {
  static async sendEmail(_ref) {
    let {
      templateId,
      to,
      params,
      attachments
    } = _ref;
    try {
      return new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('POST', `https://api.brevo.com/v3/smtp/email`, true);
        request.responseType = 'json';
        request.setRequestHeader('api-key', _app.AppService.environment.brevoApiKey);
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status == 201) {
            resolve(true);
          } else {
            reject(response || new Error(`Error ${status}`));
          }
        };
        request.onerror = () => {
          console.error('[HTTP] Request Error');
          reject(request.response);
        };
        request.send(JSON.stringify({
          templateId,
          to: [{
            email: to
          }],
          ...(process.env.REACT_APP_ENV === 'production' ? {
            cc: [{
              email: 'team-sales@geovelo.fr'
            }]
          } : {}),
          replyTo: {
            email: 'contact@geovelo.fr'
          },
          params,
          ...(attachments ? {
            attachment: attachments
          } : {})
        }));
      });
    } catch (err) {
      console.error('[BrevoService][sendEmail]', err);
      throw err;
    }
  }
}
exports.BrevoService = BrevoService;