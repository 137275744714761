"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PartnerContractTemplate = exports.PartnerContract = void 0;
class PartnerContractTemplate {
  constructor(id, code, title, apiUsageLimit, permissions) {
    this.id = id;
    this.code = code;
    this.title = title;
    this.apiUsageLimit = apiUsageLimit;
    this.permissions = permissions;
  }
}
exports.PartnerContractTemplate = PartnerContractTemplate;
class PartnerContract {
  constructor(id, title, startDateTime, endDateTime, apiUsageLimit, contractTemplateId, companyMaxSize) {
    this.id = id;
    this.title = title;
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.apiUsageLimit = apiUsageLimit;
    this.contractTemplateId = contractTemplateId;
    this.companyMaxSize = companyMaxSize;
  }
}
exports.PartnerContract = PartnerContract;