"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GISFileMessage = void 0;
class GISFileMessage {
  constructor(created, creator, message, attachmentUrl) {
    this.created = created;
    this.creator = creator;
    this.message = message;
    this.attachmentUrl = attachmentUrl;
  }
}
exports.GISFileMessage = GISFileMessage;