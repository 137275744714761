"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Search = void 0;
var _services = require("../services");
class Search {
  transportModes = ['bike'];
  profile = 'daily';
  bikeType = 'own';
  wayPoints = [];
  eBikeEnabled = false;
  constructor() {
    let props = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    this.reset(props);
  }
  get computable() {
    return Boolean(this.wayPoints[0]) && Boolean(this.wayPoints[1]);
  }
  reset() {
    let {
      transportModes,
      profile,
      bikeType,
      wayPoints,
      eBikeEnabled,
      loopSettings,
      datetimeOfDeparture
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (transportModes) this.transportModes = transportModes;
    if (profile) this.profile = profile;
    if (bikeType) this.bikeType = bikeType;
    if (wayPoints) this.wayPoints = wayPoints;
    if (loopSettings) this.loopSettings = loopSettings;
    if (datetimeOfDeparture) this.datetimeOfDeparture = datetimeOfDeparture;else if (datetimeOfDeparture === null) this.datetimeOfDeparture = null;
    this.eBikeEnabled = Boolean(eBikeEnabled);
  }
  clone() {
    return new Search({
      transportModes: this.transportModes,
      profile: this.profile,
      bikeType: this.bikeType,
      eBikeEnabled: this.eBikeEnabled,
      wayPoints: [...this.wayPoints],
      loopSettings: this.loopSettings ? {
        ...this.loopSettings
      } : undefined,
      datetimeOfDeparture: this.datetimeOfDeparture ? this.datetimeOfDeparture.clone() : this.datetimeOfDeparture
    });
  }
  toParams() {
    const transportModes = {
      bike: 'BIKE',
      pedestrian: 'PEDESTRIAN',
      publicTransports: 'PUBLIC_TRANSPORT'
    };
    const params = {
      bikeDetails: {
        profile: _services.cyclingProfilesMap[this.profile],
        bikeType: _services.bikeTypesMap[this.bikeType],
        eBike: this.eBikeEnabled
      },
      transportModes: this.transportModes.map(key => transportModes[key]),
      waypoints: this.wayPoints.reduce((res, wayPoint) => {
        if (wayPoint) {
          const {
            point: {
              coordinates: [longitude, latitude]
            },
            address: title
          } = wayPoint;
          res.push({
            latitude,
            longitude,
            title
          });
        }
        return res;
      }, [])
    };
    if (this.loopSettings) {
      const {
        duration,
        distance,
        visitPois
      } = this.loopSettings;
      if (duration) {
        params.loop_details = {
          loop_type: visitPois ? 'poi_total_distance' : 'total_distance',
          duration
        };
      } else if (distance) {
        params.loop_details = {
          loop_type: visitPois ? 'poi_total_distance' : 'total_distance',
          distance
        };
      }
    }
    if (this.datetimeOfDeparture) {
      params.datetimeOfDeparture = this.datetimeOfDeparture.format('YYYY-MM-DDTHH:mm');
    }
    return params;
  }
}
exports.Search = Search;