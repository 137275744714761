"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.parkingTypes = exports.parkingNetworks = exports.Parking = void 0;
var _bicibox = _interopRequireDefault(require("../assets/images/parking/bicibox.svg"));
var _parkingWhite = _interopRequireDefault(require("../assets/images/parking/parking-white.svg"));
var _shelteredWhite = _interopRequireDefault(require("../assets/images/parking/sheltered-white.svg"));
var _supervisedWhite = _interopRequireDefault(require("../assets/images/parking/supervised-white.svg"));
var _veligo = _interopRequireDefault(require("../assets/images/parking/veligo.svg"));
var _veloceo = _interopRequireDefault(require("../assets/images/parking/veloceo.svg"));
var _velocity = _interopRequireDefault(require("../assets/images/parking/velocity.svg"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class Parking {
  constructor(id, code, location, capacity, rackType, accessType, free, sheltered, station, supervised, locked, subscription, network, website) {
    this.id = id;
    this.code = code;
    this.location = location;
    this.capacity = capacity;
    this.rackType = rackType;
    this.accessType = accessType;
    this.free = free;
    this.sheltered = sheltered;
    this.station = station;
    this.supervised = supervised;
    this.locked = locked;
    this.subscription = subscription;
    this.network = network;
    this.website = website;
    this.type = this.locked ? 'locked' : !this.free ? 'toll' : this.supervised ? 'supervised' : this.sheltered ? 'sheltered' : 'simple';
  }
}
exports.Parking = Parking;
const parkingNetworks = exports.parkingNetworks = {
  bicibox: {
    title: 'Bicibox',
    color: '#337ab7',
    icon: _bicibox.default
  },
  veligo: {
    title: 'Véligo',
    color: '#57585A',
    icon: _veligo.default
  },
  velocity: {
    title: 'Velocity',
    color: '#00297e',
    icon: _velocity.default
  },
  veloceo: {
    title: 'Vélocéo',
    color: '#02495e',
    icon: _veloceo.default
  }
};
const parkingTypes = exports.parkingTypes = {
  sheltered: {
    icon: _shelteredWhite.default,
    labelKey: 'commons.parking.sheltered'
  },
  simple: {
    icon: _parkingWhite.default,
    labelKey: 'commons.parking.free'
  },
  supervised: {
    icon: _supervisedWhite.default,
    labelKey: 'commons.parking.supervised'
  },
  locked: {
    icon: _supervisedWhite.default,
    labelKey: 'commons.parking.locked'
  },
  // TODO : change me
  toll: {
    icon: _parkingWhite.default,
    labelKey: 'commons.parking.toll'
  }
};