"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.rideThemesMap = exports.publicKindsMap = exports.mediaTypesMap = exports.difficultiesMap = exports.RideTheme = exports.Ride = void 0;
var _VideoLibrary2 = _interopRequireDefault(require("@mui/icons-material/VideoLibrary"));
var _PictureAsPdf2 = _interopRequireDefault(require("@mui/icons-material/PictureAsPdf"));
var _PhotoLibrary2 = _interopRequireDefault(require("@mui/icons-material/PhotoLibrary"));
var _LibraryBooks2 = _interopRequireDefault(require("@mui/icons-material/LibraryBooks"));
var _LibraryMusic2 = _interopRequireDefault(require("@mui/icons-material/LibraryMusic"));
var _ChildCare2 = _interopRequireDefault(require("@mui/icons-material/ChildCare"));
var _everyone = _interopRequireDefault(require("../assets/images/ride-difficulties/everyone.svg"));
var _family = _interopRequireDefault(require("../assets/images/ride-difficulties/family.svg"));
var _sportive = _interopRequireDefault(require("../assets/images/ride-difficulties/sportive.svg"));
var _coastal = _interopRequireDefault(require("../assets/images/ride-themes/coastal.png"));
var _cultural = _interopRequireDefault(require("../assets/images/ride-themes/cultural.png"));
var _family2 = _interopRequireDefault(require("../assets/images/ride-themes/family.png"));
var _gourmet = _interopRequireDefault(require("../assets/images/ride-themes/gourmet.png"));
var _mountain = _interopRequireDefault(require("../assets/images/ride-themes/mountain.png"));
var _nature = _interopRequireDefault(require("../assets/images/ride-themes/nature.png"));
var _sportive2 = _interopRequireDefault(require("../assets/images/ride-themes/sportive.png"));
var _urban = _interopRequireDefault(require("../assets/images/ride-themes/urban.png"));
var _icons = require("../components/icons");
var _utils = require("../utils");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class Ride {
  constructor(id, creator, title, description, distance, duration, overloadDuration, verticalGain, difficulty, publicKind, area, themeIds, iconUrl, from, to, center, condensedGeometry, condensedReturnGeometry, routeId, returnRouteId, isLoop, isHighlighted, steps, poiIds, photos, medias, primaryColor, secondaryColor, publicationStatus, partners, traceIds, belongingBikeRoutes, previousRideId, nextRideId, updated, created, privacy, bikeType, profile, metaTitle, metaDescription) {
    this.id = id;
    this.creator = creator;
    this.title = title;
    this.description = description;
    this.distance = distance;
    this.duration = duration;
    this.overloadDuration = overloadDuration;
    this.verticalGain = verticalGain;
    this.difficulty = difficulty;
    this.publicKind = publicKind;
    this.area = area;
    this.themeIds = themeIds;
    this.iconUrl = iconUrl;
    this.from = from;
    this.to = to;
    this.center = center;
    this.condensedGeometry = condensedGeometry;
    this.condensedReturnGeometry = condensedReturnGeometry;
    this.routeId = routeId;
    this.returnRouteId = returnRouteId;
    this.isLoop = isLoop;
    this.isHighlighted = isHighlighted;
    this.steps = steps;
    this.poiIds = poiIds;
    this.photos = photos;
    this.medias = medias;
    this.primaryColor = primaryColor;
    this.secondaryColor = secondaryColor;
    this.publicationStatus = publicationStatus;
    this.partners = partners;
    this.traceIds = traceIds;
    this.belongingBikeRoutes = belongingBikeRoutes;
    this.previousRideId = previousRideId;
    this.nextRideId = nextRideId;
    this.updated = updated;
    this.created = created;
    this.privacy = privacy;
    this.bikeType = bikeType;
    this.profile = profile;
    this.metaTitle = metaTitle;
    this.metaDescription = metaDescription;
    this.bounds = condensedGeometry ? (0, _utils.toBounds)(condensedGeometry) : undefined;
    this.boundsCenter = this.bounds ? {
      type: 'Point',
      coordinates: [(this.bounds.east + this.bounds.west) / 2, (this.bounds.north + this.bounds.south) / 2]
    } : undefined;
  }
  clone() {
    return new Ride(this.id, this.creator, this.title, this.description, this.distance, this.duration, this.overloadDuration, this.verticalGain, this.difficulty, this.publicKind, this.area, this.themeIds, this.iconUrl, this.from, this.to, this.center, this.condensedGeometry, this.condensedReturnGeometry, this.routeId, this.returnRouteId, this.isLoop, this.isHighlighted, this.steps, this.poiIds, this.photos, this.medias, this.primaryColor, this.secondaryColor, this.publicationStatus, this.partners, this.traceIds, this.belongingBikeRoutes, this.previousRideId, this.nextRideId, this.updated, this.created, this.privacy, this.bikeType, this.profile, this.metaTitle, this.metaDescription);
  }
}
exports.Ride = Ride;
const rideThemesMap = exports.rideThemesMap = {
  nature: {
    labelKey: 'commons.ride.themes.nature',
    image: _nature.default,
    hasDescription: true
  },
  cultural: {
    labelKey: 'commons.ride.themes.cultural',
    image: _cultural.default,
    hasDescription: true
  },
  sportive: {
    labelKey: 'commons.ride.themes.sportive',
    image: _sportive2.default,
    hasDescription: true
  },
  urban: {
    labelKey: 'commons.ride.themes.urban',
    image: _urban.default
  },
  mountain: {
    labelKey: 'commons.ride.themes.mountain',
    image: _mountain.default
  },
  coastal: {
    labelKey: 'commons.ride.themes.coastal',
    image: _coastal.default
  },
  family: {
    labelKey: 'commons.ride.themes.family',
    image: _family2.default,
    hasDescription: true
  },
  gourmet: {
    labelKey: 'commons.ride.themes.gourmet',
    image: _gourmet.default
  }
};
const publicKindsMap = exports.publicKindsMap = {
  everyone: {
    labelKey: 'commons.ride.public_kinds.everyone'
  },
  family: {
    labelKey: 'commons.ride.public_kinds.family',
    Icon: _ChildCare2.default
  },
  sportive: {
    labelKey: 'commons.ride.public_kinds.sportive'
  }
};
const difficultiesMap = exports.difficultiesMap = {
  easy: {
    labelKey: 'commons.ride.difficulties.easy',
    Icon: _icons.EasyIcon,
    icon: _everyone.default,
    color: '#E7F4FA'
  },
  medium: {
    labelKey: 'commons.ride.difficulties.medium',
    Icon: _icons.MediumIcon,
    icon: _family.default,
    color: '#DAF3DF'
  },
  hard: {
    labelKey: 'commons.ride.difficulties.hard',
    Icon: _icons.HardIcon,
    icon: _sportive.default,
    color: '#EAE2FC'
  }
};
const mediaTypesMap = exports.mediaTypesMap = [{
  key: 'audio',
  labelKey: 'commons.ride.media_types.audio',
  Icon: _LibraryMusic2.default
}, {
  key: 'file',
  labelKey: 'commons.ride.media_types.file',
  Icon: _LibraryBooks2.default
}, {
  key: 'image',
  labelKey: 'commons.ride.media_types.image',
  Icon: _PhotoLibrary2.default
}, {
  key: 'pdf',
  labelKey: 'commons.ride.media_types.pdf',
  Icon: _PictureAsPdf2.default
}, {
  key: 'video',
  labelKey: 'commons.ride.media_types.video',
  Icon: _VideoLibrary2.default
}, {
  key: 'website',
  labelKey: 'commons.ride.media_types.website',
  Icon: _icons.WebsiteIcon
}];
class RideTheme {
  constructor(id, key) {
    this.id = id;
    this.key = key;
  }
}
exports.RideTheme = RideTheme;