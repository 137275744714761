"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SummaryService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class SummaryService {
  static async getUserSummaries() {
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const results = await _http.HttpService.get('usersV1', `/users/${_user.UserService.currentUser.id}/summaries`);
      return results.map(parseUserSummaries);
    } catch (err) {
      console.error('[SummaryService][getUserSummaries]', err);
      throw err;
    }
  }
}
exports.SummaryService = SummaryService;
function parseUserSummaries(_ref) {
  let {
    summary_type: {
      code,
      title,
      start_datetime,
      end_datetime,
      image
    },
    preview_url,
    video_url
  } = _ref;
  return new _models.Summary(new _models.SummaryType(code, title, (0, _moment.default)(start_datetime), (0, _moment.default)(end_datetime), image), preview_url, video_url);
}