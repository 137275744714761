"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getOSRMRoutingLayers = getOSRMRoutingLayers;
exports.tilesTypes = void 0;
const tilesTypes = exports.tilesTypes = ['speeds', 'ratios', 'weights'];
const layerBounds = {
  speeds: [0, 6, 10, 12, 14, 15, 16, 18, 20, 22],
  ratios: [0, 0.6, 1.2, 1.8, 2.4, 3, 3.6, 4.2, 4.8, 5.4, 6],
  weights: [0, 10, 100, 250, 500, 750, 1000, 1500, 2000, 10000]
};
const colors = ['#C8C8C8', '#C8A6A6', '#C88585', '#C86464', '#B2755A', '#889746', '#5EBA32', '#49CB28', '#1FED14', '#0AFF0A'];
const displayedTilesTypes = {
  speeds: 'speed',
  ratios: 'rate',
  weights: 'weight'
};
function getOSRMRoutingLayers(displayedTilesType, turnsDisplayed) {
  const displayedTilesTypeKey = displayedTilesTypes[displayedTilesType];
  const iconColor = '#ff0000';
  const iconHaloColor = '#ffff00';
  const speedsLayers = [{
    // interactive: true,
    minzoom: 12,
    layout: {
      visibility: 'visible',
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][0]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds < 10',
    paint: {
      'line-color': colors[0],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][1]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][0]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 10-20',
    paint: {
      'line-color': colors[1],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][2]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][1]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 20-30',
    paint: {
      'line-color': colors[2],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][3]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][2]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 30-40',
    paint: {
      'line-color': colors[3],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][4]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][3]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 40-50',
    paint: {
      'line-color': colors[4],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][5]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][4]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 50-60',
    paint: {
      'line-color': colors[5],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][6]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][5]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 60-70',
    paint: {
      'line-color': colors[6],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][7]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][6]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 70-80',
    paint: {
      'line-color': colors[7],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['all', ['<=', displayedTilesTypeKey, layerBounds[displayedTilesType][8]], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][7]]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 80-90',
    paint: {
      'line-color': colors[8],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }, {
    // interactive: true,
    minzoom: 12,
    layout: {
      'line-cap': 'round'
    },
    metadata: {
      'mapbox:group': '1457989050091.615'
    },
    filter: ['all', ['==', '$type', 'LineString'], ['>', displayedTilesTypeKey, layerBounds[displayedTilesType][8]]],
    type: 'line',
    source: 'osrm',
    id: 'speeds 90+',
    paint: {
      'line-color': colors[9],
      'line-width': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.5, 14, 2, 18, 18],
      'line-offset': ['interpolate', ['exponential', 1.5], ['zoom'], 12.5, 0.25, 14, 1, 18, 9]
    },
    'source-layer': 'speeds'
  }];
  const turnsLayers = [{
    id: 'osrm-0',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': '0',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [10, 0]], 20, ['literal', [20, 0]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.2, 0.0]], 20, ['literal', [3, 0]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 5,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['>=', 'turn_angle', -22.5], ['<', 'turn_angle', 22.5]],
    minzoom: 18
  }, {
    id: 'osrm-45',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': '45',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [15, 15]], 20, ['literal', [30, 30]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.2, 0.2]], 20, ['literal', [3, 3]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 2,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['>=', 'turn_angle', 22.5], ['<', 'turn_angle', 67.5]],
    minzoom: 18
  }, {
    id: 'osrm-90',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': '90',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [15, 15]], 20, ['literal', [30, 30]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.2, 0.2]], 20, ['literal', [3, 3]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 2,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['>=', 'turn_angle', 67.5], ['<', 'turn_angle', 115.5]],
    minzoom: 18
  }, {
    id: 'osrm-135',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': '135',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [15, 15]], 20, ['literal', [30, 30]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.2, 0.2]], 20, ['literal', [3, 3]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 2,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['>=', 'turn_angle', 115.5], ['<=', 'turn_angle', 180]],
    minzoom: 18
  }, {
    id: 'osrm-n45',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': 'n45',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [15, 30]], 20, ['literal', [30, 60]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.15, 0.4]], 20, ['literal', [2.5, 6]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 2,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['>=', 'turn_angle', -67.5], ['<', 'turn_angle', -22.5]],
    minzoom: 18
  }, {
    id: 'osrm-n90',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': 'n90',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [15, 30]], 20, ['literal', [30, 60]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.15, 0.4]], 20, ['literal', [2.5, 6]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 2,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['<=', 'turn_angle', -67.5], ['>', 'turn_angle', -115.5]],
    minzoom: 18
  }, {
    id: 'osrm-n135',
    type: 'symbol',
    source: 'osrm',
    'source-layer': 'turns',
    layout: {
      'icon-image': 'n135',
      'icon-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [15, 30]], 20, ['literal', [30, 60]]],
      'icon-allow-overlap': true,
      'icon-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-field': ['concat', '', ['/', ['round', ['*', ['get', 'weight'], 100]], 100]],
      'text-offset': ['interpolate', ['exponential', 1], ['zoom'], 15, ['literal', [0.15, 0.4]], 20, ['literal', [2.5, 6]]],
      'text-allow-overlap': true,
      'text-rotate': ['interpolate', ['exponential', 1], ['get', 'bearing_in'], 0, 0, 360, 360],
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-font': ['Arial Unicode MS Bold'],
      'symbol-avoid-edges': false,
      visibility: turnsDisplayed ? 'visible' : 'none'
    },
    paint: {
      'icon-color': iconColor,
      'icon-halo-color': iconHaloColor,
      'icon-halo-width': 2,
      'text-color': 'hsl(0, 0%, 100%)',
      'text-halo-width': 3,
      'text-halo-color': 'hsl(0, 0%, 0%)',
      'text-halo-blur': 0
    },
    filter: ['all', ['>=', 'turn_angle', -180], ['<', 'turn_angle', -115.5]],
    minzoom: 18
  }];
  const speedsLabelLayer = [{
    minzoom: 12,
    layout: {
      'text-size': ['interpolate', ['exponential', 1], ['zoom'], 10, 12, 18, 14],
      'text-allow-overlap': true,
      'symbol-avoid-edges': false,
      'text-ignore-placement': true,
      'symbol-spacing': 5,
      'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
      'symbol-placement': 'line',
      'text-justify': 'center',
      'text-padding': 1,
      'text-offset': [0, 1],
      'text-rotation-alignment': 'map',
      'text-anchor': 'center',
      'text-keep-upright': false,
      'text-field': ['concat', 'v : ', ['get', 'speed'], '; r : ', ['get', 'rate'], '; w : ', ['/', ['round', ['*', ['get', 'weight'], 100]], 100], ' >']
    },
    metadata: {},
    filter: ['==', '$type', 'LineString'],
    type: 'symbol',
    source: 'osrm',
    id: 'speeds labels',
    paint: {
      'text-color': '#fff',
      'text-halo-width': 1,
      'text-halo-color': '#000',
      'text-halo-blur': 0
    },
    'source-layer': 'speeds'
    // interactive: true,
  }];
  return [...speedsLayers, ...turnsLayers, ...speedsLabelLayer];
}