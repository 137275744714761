"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.BaseRoute = void 0;
class BaseRoute {
  constructor(description, labelKey, type, wayPoints, distances, duration, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival, sections) {
    this.description = description;
    this.labelKey = labelKey;
    this.type = type;
    this.wayPoints = wayPoints;
    this.distances = distances;
    this.duration = duration;
    this.estimatedDatetimeOfDeparture = estimatedDatetimeOfDeparture;
    this.estimatedDatetimeOfArrival = estimatedDatetimeOfArrival;
    this.sections = sections;
    this.transportModes = [...new Set(sections.map(_ref => {
      let {
        transportMode
      } = _ref;
      return transportMode;
    }))];
    const coordinates = [];
    if (sections.length > 0) {
      const {
        geometry,
        bounds
      } = sections[0];
      let {
        north,
        east,
        south,
        west
      } = bounds;
      coordinates.push(...geometry.coordinates);
      sections.slice(1).forEach(_ref2 => {
        let {
          geometry,
          bounds: {
            north: sectionNorth,
            east: sectionEast,
            south: sectionSouth,
            west: sectionWest
          }
        } = _ref2;
        coordinates.push(...geometry.coordinates.slice(1));
        north = Math.max(north, sectionNorth);
        east = Math.max(east, sectionEast);
        south = Math.min(south, sectionSouth);
        west = Math.min(west, sectionWest);
      });
      this.bounds = {
        north,
        east,
        south,
        west
      };
    }
    this.geometry = {
      type: 'LineString',
      coordinates
    };
  }
  get cyclingProfile() {
    for (const section of this.sections) {
      if (section.transportMode === 'bike') return section.profile;
    }
  }
  get cyclingDistance() {
    return this.sections.reduce((res, section) => {
      if (section.transportMode === 'bike') res += section.distance;
      return res;
    }, 0);
  }
  get cyclingDuration() {
    return this.sections.reduce((res, section) => {
      if (section.transportMode === 'bike') res += section.duration;
      return res;
    }, 0);
  }
  get hasPublicTransportsSection() {
    for (const section of this.sections) {
      if (section.transportMode === 'publicTransports') return true;
    }
    return false;
  }
  get publicTransportsDuration() {
    return this.sections.reduce((res, section) => {
      if (section.transportMode === 'publicTransports') res += section.duration;
      return res;
    }, 0);
  }
  get recommendedRoadsPercentage() {
    return Math.round(this.distances.recommendedRoads / this.distances.total * 100);
  }
  get normalRoadsPercentage() {
    return Math.round(this.distances.normalRoads / this.distances.total * 100);
  }
  get discouragedRoadsRoadsPercentage() {
    return 100 - this.recommendedRoadsPercentage - this.normalRoadsPercentage;
  }
  get asphaltRoadsPercentage() {
    return Math.round(this.distances.cyclingQualityAsphalt / this.distances.total * 100);
  }
  get damagedTracksPercentage() {
    return Math.round(this.distances.cyclingQualityDamagedTracks / this.distances.total * 100);
  }
  get pathsPercentage() {
    return Math.round(this.distances.cyclingQualityPath / this.distances.total * 100);
  }
  get otherRoadsQualityPercentage() {
    return Math.round(this.distances.cyclingQualityOther / this.distances.total * 100);
  }
  get verticalGain() {
    return this.sections.reduce((res, section) => res + ('verticalGain' in section && section.verticalGain || 0), 0);
  }
  get verticalLoss() {
    return this.sections.reduce((res, section) => res + ('verticalLoss' in section && section.verticalLoss || 0), 0);
  }
  get calories() {
    return this.sections.reduce((res, section) => res + ('calories' in section && section.calories || 0), 0);
  }
  get eBike() {
    for (const section of this.sections) {
      if ('eBike' in section) return section.eBike;
    }
  }
  get bikeStationIds() {
    const ids = this.sections.reduce((res, section) => {
      if (section.transportMode === 'bike') {
        const {
          bikeStations
        } = section;
        if (bikeStations) {
          const {
            from,
            to
          } = bikeStations;
          res.push(from.bikeStationId, to.bikeStationId);
        }
      }
      return res;
    }, []);
    return [...new Set(ids)];
  }
}
exports.BaseRoute = BaseRoute;