"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getDraggedRoutePreview = getDraggedRoutePreview;
exports.getRoutePreview = getRoutePreview;
var _models = require("../models");
function getRoutePreview(wayPoints) {
  return {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: wayPoints.reduce((res, wayPoint) => {
        if (wayPoint) res.push(wayPoint.point.coordinates);
        return res;
      }, [])
    },
    properties: {
      index: -1,
      prevWayPointIndex: -1
    }
  };
}
function getDraggedRoutePreview(currentWayPoints, draggedWayPointIndex, _ref) {
  let {
    lat,
    lng
  } = _ref;
  const newWayPoint = new _models.Place(undefined, {
    type: 'Point',
    coordinates: [Math.round(lng * 1000000) / 1000000, Math.round(lat * 1000000) / 1000000]
  });
  const wayPoints = [...currentWayPoints];
  wayPoints[draggedWayPointIndex] = newWayPoint;
  const feature = {
    type: 'Feature',
    geometry: {
      type: 'LineString',
      coordinates: wayPoints.reduce((res, wayPoint) => {
        if (wayPoint) res.push(wayPoint.point.coordinates);
        return res;
      }, [])
    },
    properties: {
      index: -1,
      prevWayPointIndex: -1
    }
  };
  return {
    wayPoints,
    feature
  };
}