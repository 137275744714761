"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.StoppingAreaService = void 0;
var _http = require("./http");
class StoppingAreaService {
  static async getStoppingAreas(_ref) {
    let {
      partnerId,
      period,
      timePeriod,
      dayPeriod
    } = _ref;
    try {
      const params = [{
        key: 'startDate',
        value: period.from.format('YYYY-MM-DD')
      }, {
        key: 'endDate',
        value: period.to.format('YYYY-MM-DD')
      }, {
        key: 'timeframe',
        value: timePeriod
      }, {
        key: 'daysOfWeek',
        value: dayPeriod
      }, {
        key: 'duration',
        value: 'all'
      }];
      const {
        features
      } = await _http.HttpService.get('stoppingAreas', `/stats/partner/${partnerId}/user_trace_stop_clusters`, params);
      const clusterLevelIndices = {};
      features?.forEach((_ref2, index) => {
        let {
          properties: {
            cid,
            cluster_level
          }
        } = _ref2;
        if (!clusterLevelIndices[cid]) clusterLevelIndices[cid] = {
          [cluster_level]: [index]
        };else if (!clusterLevelIndices[cid][cluster_level]) clusterLevelIndices[cid][cluster_level] = [index];else clusterLevelIndices[cid][cluster_level].push(index);
      });
      for (const key in clusterLevelIndices) {
        const {
          1: clusterLevel1Indices,
          2: clusterLevel2Indices
        } = clusterLevelIndices[key];
        if (clusterLevel1Indices?.length === 1 && clusterLevel2Indices?.length === 1) {
          features[clusterLevel1Indices[0]].geometry = features[clusterLevel2Indices[0]].geometry;
        }
      }
      return {
        type: 'FeatureCollection',
        features: features?.map((_ref3, index) => {
          let {
            properties: {
              mediane: medianDuration,
              moyenne: meanDuration,
              nb_user: nbUsers,
              nb_stop: nbStops,
              cluster_level: clusterLevel,
              cid
            },
            ...feature
          } = _ref3;
          return {
            ...feature,
            id: index,
            properties: {
              medianDuration,
              meanDuration,
              nbUsers,
              nbStops,
              clusterLevel,
              cid,
              totalNbStops: clusterLevel === 2 ? nbStops : features.reduce((res, _ref4) => {
                let {
                  properties: {
                    nb_stop: featureNbStops,
                    cid: featureCid,
                    cluster_level: featureClusterLevel
                  }
                } = _ref4;
                if (cid === featureCid && featureClusterLevel === 2) return res += featureNbStops;
                return res;
              }, 0)
            }
          };
        }) || []
      };
    } catch (err) {
      console.error('[StoppingAreaService][getStoppingAreas]', err);
      throw err;
    }
  }
}
exports.StoppingAreaService = StoppingAreaService;