"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _avatar = require("./avatar");
Object.keys(_avatar).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _avatar[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _avatar[key];
    }
  });
});
var _newsletterDialog = require("./newsletter-dialog");
Object.keys(_newsletterDialog).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _newsletterDialog[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _newsletterDialog[key];
    }
  });
});
var _passwordValidation = require("./password-validation");
Object.keys(_passwordValidation).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _passwordValidation[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _passwordValidation[key];
    }
  });
});
var _signInDialog = require("./sign-in-dialog");
Object.keys(_signInDialog).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _signInDialog[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _signInDialog[key];
    }
  });
});