"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.bikeTypesMap = exports.backendBikeTypes = exports.RouteService = void 0;
exports.parseRoute = parseRoute;
exports.parseSection = parseSection;
exports.parseWayPoint = parseWayPoint;
exports.routeTypes = exports.routeLabelKeys = void 0;
var _polyline = _interopRequireDefault(require("@mapbox/polyline"));
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class RouteService {
  static async getRoutes(_ref) {
    let {
      types
    } = _ref;
    if (!_user.UserService.currentUser) throw new Error('user not connected');
    try {
      const data = await _http.HttpService.get('routes', `/users/${_user.UserService.currentUser.id}/routes`, [...types.map(type => ({
        key: 'types[]',
        value: type
      }))]);
      return data.map(parseRoute);
    } catch (err) {
      console.error('[RouteService][getRoutes]', err);
      throw err;
    }
  }
  static async getRoute(id) {
    try {
      const data = await _http.HttpService.get('routes', `/routes/${id}`);
      return parseRoute(data);
    } catch (err) {
      console.error('[RouteService][getRoute]', err);
      throw err;
    }
  }
  static async getGPX(id) {
    try {
      const data = await _http.HttpService.get('routes', `/routes/${id}`, [], [], null, {
        contentType: 'application/gpx+xml',
        responseType: 'blob'
      });
      return data;
    } catch (err) {
      console.error('[RouteService][getGPX]', err);
      throw err;
    }
  }
  static async getRouteFromGPX(gpx, title) {
    try {
      const formData = new FormData();
      formData.append('gpx', gpx);
      formData.append('description', title);
      const response = await _http.HttpService.post('routes', `/route_from_gpx`, [{
        key: 'geometry',
        value: true
      }], [], formData);
      return parseRoute(response);
    } catch (err) {
      console.error('[RouteService][getRouteFromGPX]', err);
      if (typeof err === 'object' && err && 'code' in err) throw err.code;else throw new Error(err.error);
    }
  }
  static async saveRoute(_ref2) {
    let {
      type,
      computedRouteId,
      description
    } = _ref2;
    try {
      const data = await _http.HttpService.post('routes', `/routes`, [], [], JSON.stringify({
        saved_itinerary_type: type,
        computedRouteId,
        description: description !== undefined ? description : null
      }));
      return parseRoute(data);
    } catch (err) {
      console.error('[RouteService][saveRoute]', err);
      throw err;
    }
  }
  static async updateRoute(id, _ref3) {
    let {
      type,
      computedRouteId,
      description
    } = _ref3;
    try {
      const params = {
        saved_itinerary_type: type,
        computedRouteId
      };
      if (description !== undefined) params.description = description;
      const data = await _http.HttpService.put('routes', `/routes/${id}`, [], [], JSON.stringify(params));
      return parseRoute(data);
    } catch (err) {
      console.error('[RouteService][updateRoute]', err);
      throw err;
    }
  }
  static async remove(id) {
    try {
      await _http.HttpService.delete('routes', `/routes/${id}`);
      return true;
    } catch (err) {
      console.error('[RouteService][remove]', err);
      throw err;
    }
  }
}
exports.RouteService = RouteService;
const backendBikeTypes = exports.backendBikeTypes = {
  TRADITIONAL: 'own',
  BSS: 'shared'
};
const bikeTypesMap = exports.bikeTypesMap = {
  own: 'TRADITIONAL',
  shared: 'BSS',
  hybridBike: 'TRADITIONAL',
  cargo: 'TRADITIONAL'
};
function parseWayPoint(_ref4) {
  let {
    latitude,
    longitude,
    title,
    poi_id
  } = _ref4;
  const parts = (title || '').split(',').map(part => part.trim());
  const [primaryText, secondaryText] = parts.length > 2 ? [parts.slice().splice(0, parts.length - 2).join(', '), parts.slice().splice(-2).join(', ')] : [title, ''];
  const city = parts.length >= 2 ? parts.slice().splice(-2)[0] : undefined;
  return new _models.Place(undefined, {
    type: 'Point',
    coordinates: [longitude, latitude]
  }, title ? {
    primaryText,
    secondaryText,
    city
  } : undefined, poi_id);
}
const facilitiesMap = {
  CYCLEWAY: 'cycleway',
  FORBIDDEN: 'forbidden',
  FERRY: 'ferry',
  FOOTWAY: 'footway',
  GREENWAY: 'greenway',
  LANE: 'lane',
  LIVINGSTREET: 'livingstreet',
  NONE: 'none',
  OPPOSITE: 'opposite',
  PEDESTRIAN: 'pedestrian',
  PRIMARY: 'primary',
  RESIDENTIAL: 'residential',
  SECONDARY: 'secondary',
  SERVICE: 'service',
  SHAREBUSWAY: 'sharebusway',
  STEPS: 'steps',
  TERTIARY: 'tertiary',
  ZONE30: 'zone30'
};
function parseInstructions(_instructions) {
  if (!_instructions || _instructions.length < 2) return [];
  const directionsMap = {
    CROSSING: 'crossing',
    DROP_SHARED_BIKE: 'dropSharedBike',
    ELEVATOR: 'elevator',
    ENTER_AGAINST_ALLOWED_DIRECTION: 'enterAgainstAllowedDirection',
    ENTER_ROUND_ABOUT: 'enterRoundAbout',
    GET_OFF_THE_BIKE: 'getOffTheBike',
    GET_ON_THE_BIKE: 'getOnTheBike',
    GO_STRAIGHT: 'goStraight',
    HEAD_ON: 'headOn',
    STAY_ON: 'stayOn',
    LEAVE_AGAINST_ALLOWED_DIRECTION: 'leaveAgainstAllowedDirection',
    LEAVE_PUBLIC_TRANSPORT: 'leavePublicTransport',
    LEAVE_ROUND_ABOUT: 'leaveRoundAbout',
    REACH_VIA_LOCATION: 'reachViaLocation',
    REACHED_THE_STATION: 'reachedTheStation',
    REACHED_YOUR_DESTINATION: 'reachedYourDestination',
    ROUND_ABOUT_EXIT_1: 'roundAboutExit1',
    ROUND_ABOUT_EXIT_2: 'roundAboutExit2',
    ROUND_ABOUT_EXIT_3: 'roundAboutExit3',
    ROUND_ABOUT_EXIT_4: 'roundAboutExit4',
    ROUND_ABOUT_EXIT_5: 'roundAboutExit5',
    ROUND_ABOUT_EXIT_6: 'roundAboutExit6',
    ROUND_ABOUT_EXIT_7: 'roundAboutExit7',
    ROUND_ABOUT_EXIT_8: 'roundAboutExit8',
    ROUND_ABOUT_EXIT_9: 'roundAboutExit9',
    ROUND_ABOUT_EXIT_10: 'roundAboutExit10',
    ROUND_ABOUT_EXIT_11: 'roundAboutExit11',
    ROUND_ABOUT_EXIT_12: 'roundAboutExit12',
    ROUND_ABOUT_EXIT_13: 'roundAboutExit13',
    ROUND_ABOUT_EXIT_14: 'roundAboutExit14',
    ROUND_ABOUT_EXIT_15: 'roundAboutExit15',
    ROUND_ABOUT_EXIT_16: 'roundAboutExit16',
    ROUND_ABOUT_EXIT_17: 'roundAboutExit17',
    ROUND_ABOUT_EXIT_18: 'roundAboutExit18',
    ROUND_ABOUT_EXIT_19: 'roundAboutExit19',
    ROUND_ABOUT_EXIT_20: 'roundAboutExit20',
    ROUND_ABOUT_EXIT_X: 'roundAboutExitX',
    START_AT_END_OF_STREET: 'startAtTheEndOfStreet',
    STAY_ON_ROUND_ABOUT: 'stayOnRoundAbout',
    TAKE_PUBLIC_TRANSPORT: 'takePublicTransport',
    TAKE_SHARED_BIKE: 'takeSharedBike',
    TURN_LEFT: 'turnLeft',
    TURN_RIGHT: 'turnRight',
    TURN_SHARP_LEFT: 'turnSharpLeft',
    TURN_SHARP_RIGHT: 'turnSharpRight',
    TURN_SLIGHT_LEFT: 'turnSlightLeft',
    TURN_SLIGHT_RIGHT: 'turnSlightRight',
    U_TURN: 'uTurn',
    UNKNOWN: 'unknown'
  };
  const [, ...instructions] = _instructions;
  return instructions.reduce((res, _ref5) => {
    let [direction, roadName, roadLength, facility, cyclability, geometryIndex, orientation,, duration] = _ref5;
    res.push({
      direction: directionsMap[direction] || 'unknown',
      roadName,
      distance: roadLength,
      facility: facilitiesMap[facility] || 'none',
      cyclability,
      geometryIndex,
      orientation,
      duration
    });
    return res;
  }, []);
}
function parseElevations(_elevations) {
  if (!_elevations || _elevations.length < 2) return [];
  const [, ...elevations] = _elevations;
  return elevations.reduce((res, _ref6) => {
    let [distanceFromStart, elevation, geometryIndex] = _ref6;
    res.push({
      geometryIndex,
      elevation,
      distanceFromStart
    });
    return res;
  }, []);
}
function parseFacilities(_facilities) {
  if (!_facilities || _facilities.length < 2) return [];
  const [, ...facilities] = _facilities;
  return facilities.reduce((res, _ref7) => {
    let [geometryIndex, key] = _ref7;
    res.push({
      geometryIndex,
      facility: facilitiesMap[key]
    });
    return res;
  }, []);
}
function parseSection(props, index, _prevWayPointIndex) {
  const {
    details,
    duration,
    geometry: _geometry,
    waypoints,
    waypointsIndices: _wayPointsIndices,
    estimatedDatetimeOfDeparture: _estimatedDatetimeOfDeparture,
    estimatedDatetimeOfArrival: _estimatedDatetimeOfArrival
  } = props;
  const {
    instructions: _instructions
  } = details;
  const instructions = parseInstructions(_instructions);
  const wayPointsIndices = _wayPointsIndices || [];
  let geometry = {
    type: 'LineString',
    coordinates: []
  };
  const features = [];
  if (_geometry && _wayPointsIndices) {
    const {
      coordinates
    } = geometry = _polyline.default.toGeoJSON(_geometry, 6);
    const stepIndices = _wayPointsIndices.slice(1, -1);
    const getOffBikeIndices = [];
    const getOnBikeIndices = [];
    if ('bikeType' in details) {
      instructions.forEach(_ref8 => {
        let {
          direction,
          geometryIndex: index
        } = _ref8;
        if (direction === 'getOffTheBike') getOffBikeIndices.push(index);
        if (direction === 'getOnTheBike') getOnBikeIndices.push(index);
      });
    }
    let prevWayPointIndex = _prevWayPointIndex;
    let startGeometryIndex = 0;
    let walking = false;
    let facility = 'bikeType' in details && details.facilities && details.facilities[1] && facilitiesMap[details.facilities[1][1]] || undefined;
    coordinates.forEach((_, index) => {
      const newFacility = 'bikeType' in details && details.facilities && details.facilities.slice(2).find(_ref9 => {
        let [geometryIndex] = _ref9;
        return geometryIndex === index;
      })?.[1];
      const isStep = stepIndices.indexOf(index) !== -1;
      const getOffBike = getOffBikeIndices.indexOf(index) !== -1;
      const getOnBike = getOnBikeIndices.indexOf(index) !== -1;
      if (isStep || getOffBike || getOnBike || newFacility) {
        features.push({
          type: 'Feature',
          geometry: {
            type: 'LineString',
            coordinates: coordinates.slice(startGeometryIndex, index + 1)
          },
          properties: {
            index,
            prevWayPointIndex,
            walking,
            facility
          }
        });
        startGeometryIndex = index;
        if (getOffBike) walking = true;
        if (getOnBike) walking = false;
        if (isStep) prevWayPointIndex += 1;
        if (newFacility) facility = newFacility !== 'facility' ? facilitiesMap[newFacility] : undefined;
      }
    });
    features.push({
      type: 'Feature',
      geometry: {
        type: 'LineString',
        coordinates: coordinates.slice(startGeometryIndex)
      },
      properties: {
        index,
        prevWayPointIndex,
        walking,
        facility
      }
    });
  }
  const wayPoints = waypoints.map(_ref10 => {
    let {
      latitude,
      longitude,
      title
    } = _ref10;
    return new _models.Place(undefined, {
      type: 'Point',
      coordinates: [longitude, latitude]
    }, title ? {
      primaryText: title
    } : undefined);
  });
  const estimatedDatetimeOfDeparture = (0, _moment.default)(_estimatedDatetimeOfDeparture);
  const estimatedDatetimeOfArrival = (0, _moment.default)(_estimatedDatetimeOfArrival);
  if (props.transportMode === 'PEDESTRIAN') {
    const {
      direction,
      distances,
      verticalGain,
      verticalLoss,
      calories,
      elevations
    } = props.details;
    return new _models.PedestrianSection(direction, distances.total, duration, verticalGain, verticalLoss, calories || 0, geometry, features, wayPoints, wayPointsIndices, instructions, parseElevations(elevations), estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival);
  }
  if (props.transportMode === 'PUBLIC_TRANSPORT') {
    const {
      distances
    } = props.details;
    return new _models.PublicTransportSection(details, distances.total, duration, geometry, features, wayPoints, wayPointsIndices, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival);
  }
  const {
    profile,
    electric_adapted_itinerary,
    direction,
    distances,
    verticalGain,
    verticalLoss,
    elevations,
    facilities,
    bikeStations,
    calories
  } = props.details;
  return new _models.BikeSection(_cyclingProfile.backendCyclingProfiles[profile], Boolean(electric_adapted_itinerary), direction, distances.total, duration, verticalGain, verticalLoss, props.calories || calories || 0, geometry, features, wayPoints, wayPointsIndices, instructions, parseElevations(elevations), parseFacilities(facilities), bikeStations ? {
    from: {
      bikeStationId: bikeStations.from.selected
    },
    to: {
      bikeStationId: bikeStations.to.selected
    }
  } : null, estimatedDatetimeOfDeparture, estimatedDatetimeOfArrival);
}
const routeLabelKeys = exports.routeLabelKeys = {
  FASTER: 'faster',
  RECOMMENDED: 'recommended',
  RIDE: 'ride',
  SAFER: 'safer',
  VTC: 'hybrid_bike',
  CARGO: 'cargo'
};
const routeTypes = exports.routeTypes = {
  RIDE: 'ride',
  USER: 'user',
  USER_GUIDED: 'userGuided',
  USER_TRIP: 'userTrip'
};
function parseRoute(_ref11) {
  let {
    id,
    created,
    description,
    title,
    type,
    waypoints,
    distances,
    duration,
    estimatedDatetimeOfDeparture,
    estimatedDatetimeOfArrival,
    sections,
    computedRouteId
  } = _ref11;
  let prevWayPointIndex = 0;
  return new _models.Route(id, (0, _moment.default)(created), description, routeLabelKeys[title], routeTypes[type], waypoints.map(parseWayPoint), distances, duration, (0, _moment.default)(estimatedDatetimeOfDeparture), (0, _moment.default)(estimatedDatetimeOfArrival), sections.map((sectionData, index) => {
    const section = parseSection(sectionData, index, prevWayPointIndex);
    prevWayPointIndex += section.wayPointsIndices.length - 2;
    return section;
  }), computedRouteId);
}