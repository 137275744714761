"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.poiCategoryCodes = exports.geoveloCategoryCodes = exports.asGeoveloCategoryCode = exports.accomodationCategoryCodes = exports.PoiCategory = void 0;
var _poiCategoryLav = require("./poi-category-lav");
const geoveloCategoryCodes = exports.geoveloCategoryCodes = ['accomodation', 'antics', 'beach', 'bicycleHome', 'bicyclePump', 'bicycleRental', 'bicycleRepair', 'bnb', 'camping', 'castle', 'cave', 'chargingStation', 'collective', 'drinkingWater',
// 'event',
// 'eventCultural',
// 'eventFleaMarket',
// 'eventLocal',
// 'eventMarket',
// 'eventSport',
// 'eventVisit',
'fortification', 'garden', 'holidayVillage', 'hotel', 'industry', 'leisurePark', 'museum', 'naturalSite', 'picnic', 'religious', 'remarkableCity', 'residence', 'restaurant',
// 'roadwork',
'ruins', 'smallHeritage', 'sport', 'thermalCenter', 'toDoToSee', 'toilets', 'tourismOffice', 'tourismSite', 'useful', 'viewPoint'];
const poiCategoryCodes = exports.poiCategoryCodes = [..._poiCategoryLav.lavPoiCategoryCodes, ...geoveloCategoryCodes];
const accomodationCategoryCodes = exports.accomodationCategoryCodes = [..._poiCategoryLav.lavAccomodationCategoryCodes, 'accomodation', 'bnb', 'camping', 'collective', 'holidayVillage', 'hotel', 'residence'];
const asGeoveloCategoryCode = exports.asGeoveloCategoryCode = {
  accomodation: 'accomodation',
  antics: 'antics',
  beach: 'beach',
  bicycle: 'bicycle',
  bicycleHome: 'bicycleHome',
  bicycleParking: 'bicycleParking',
  bicyclePump: 'bicyclePump',
  bicycleRental: 'bicycleRental',
  bicycleRepair: 'bicycleRepair',
  bnb: 'bnb',
  camping: 'camping',
  castle: 'castle',
  cave: 'cave',
  chargingStation: 'chargingStation',
  collective: 'collective',
  drinkingWater: 'drinkingWater',
  fortification: 'fortification',
  garden: 'garden',
  holidayVillage: 'holidayVillage',
  hotel: 'hotel',
  industry: 'industry',
  lavAccomodation: 'accomodation',
  lavActivities: 'toDoToSee',
  lavBicycleRental: 'bicycleRental',
  lavBicycleRepair: 'bicycleRepair',
  lavBicycleStopingArea: 'useful',
  lavBnB: 'bnb',
  lavCamping: 'camping',
  lavCampingCarArea: 'accomodation',
  lavCastle: 'castle',
  lavCave: 'cave',
  lavCollective: 'collective',
  lavCottage: 'holidayVillage',
  lavFamilyActivities: 'toDoToSee',
  lavFood: 'restaurant',
  lavGarden: 'garden',
  lavHotel: 'hotel',
  lavLocalProducts: 'restaurant',
  lavOtherActivities: 'toDoToSee',
  lavPackedLunch: 'restaurant',
  lavResidence: 'residence',
  lavRestaurant: 'restaurant',
  lavSmallHeritage: 'smallHeritage',
  lavToDoToSee: 'toDoToSee',
  lavTourismOffice: 'tourismOffice',
  lavUseful: 'useful',
  lavWaterActivities: 'toDoToSee',
  leisurePark: 'leisurePark',
  museum: 'museum',
  naturalSite: 'naturalSite',
  picnic: 'picnic',
  religious: 'religious',
  remarkableCity: 'remarkableCity',
  residence: 'residence',
  restaurant: 'restaurant',
  ruins: 'ruins',
  smallHeritage: 'smallHeritage',
  sport: 'sport',
  thermalCenter: 'thermalCenter',
  toDoToSee: 'toDoToSee',
  toilets: 'toilets',
  tourism: 'tourism',
  tourismOffice: 'tourismOffice',
  tourismSite: 'tourismSite',
  useful: 'useful',
  viewPoint: 'viewPoint'
};
class PoiCategory {
  constructor(id, code, labelKey, color, icon, parentCode, children, order) {
    this.id = id;
    this.code = code;
    this.labelKey = labelKey;
    this.color = color;
    this.icon = icon;
    this.parentCode = parentCode;
    this.children = children;
    this.order = order;
  }
}
exports.PoiCategory = PoiCategory;