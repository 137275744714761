"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.cyclingProfilesMap = exports.backendCyclingProfiles = void 0;
const backendCyclingProfiles = exports.backendCyclingProfiles = {
  BEGINNER: 'safe',
  EXPERT: 'direct',
  MEDIAN: 'daily',
  RIDE: 'touristic',
  VTC: 'hybridBike',
  VAE: 'eBike',
  CARGO: 'cargo'
};
const cyclingProfilesMap = exports.cyclingProfilesMap = {
  safe: 'BEGINNER',
  daily: 'MEDIAN',
  direct: 'EXPERT',
  touristic: 'RIDE',
  hybridBike: 'VTC',
  eBike: 'VAE',
  cargo: 'CARGO'
};