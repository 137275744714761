"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ReportService = void 0;
var _polyline = require("@mapbox/polyline");
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _reportType = require("./report-type");
var _user = require("./user");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class ReportService {
  static async addReport(_ref) {
    let {
      type,
      place,
      point,
      geometry,
      description,
      scheduleDates,
      photo
    } = _ref;
    try {
      const formData = new FormData();
      formData.append('type', type.toString());
      if (place) {
        formData.append('geo_point', JSON.stringify(place.point));
        formData.append('address', place.address);
      } else if (point) formData.append('geo_point', JSON.stringify(point));
      if (geometry) formData.append('geometry', JSON.stringify(geometry));
      formData.append('description', description);
      if (photo) formData.append('photo', photo);
      if (scheduleDates && scheduleDates[0]) formData.append('start_date', (0, _moment.default)(scheduleDates[0]).format('YYYY-MM-DDTHH:mm'));
      if (scheduleDates && scheduleDates[1]) formData.append('end_date', (0, _moment.default)(scheduleDates[1]).format('YYYY-MM-DDTHH:mm'));
      formData.append('is_temporary', (scheduleDates ? scheduleDates[1] !== null : false).toString());
      const data = await _http.HttpService.post('reportsV2', '/reports', [], [], formData);
      const report = parseReport(data, _app.AppService.environment.backendUrl);
      return report;
    } catch (err) {
      console.error('[ReportService][addReport]', err);
      throw err;
    }
  }
  static async addReview(_ref2) {
    let {
      reportId,
      comment,
      rating
    } = _ref2;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const params = {
        report: reportId
      };
      if (rating) params.rating = rating;
      if (comment) params.comment = comment;
      const data = await _http.HttpService.post('reviews', '/reviews', [], [], JSON.stringify(params));
      const review = parseReview(data, _user.UserService.currentUser.id);
      return review;
    } catch (err) {
      console.error('[ReportService][addReview]', err);
      throw err;
    }
  }
  static async updateReview(id, _ref3) {
    let {
      comment,
      rating
    } = _ref3;
    try {
      if (!_user.UserService.currentUser) throw new Error('user not connected');
      const params = {};
      if (rating) params.rating = rating;
      if (comment) params.comment = comment;
      const data = await _http.HttpService.put('reviews', `/reviews/${id}`, [], [], JSON.stringify(params));
      const review = parseReview(data, _user.UserService.currentUser.id);
      return review;
    } catch (err) {
      console.error('[ReportService][updateReview]', err);
      throw err;
    }
  }
  static async deleteReview(id) {
    try {
      await _http.HttpService.delete('reviews', `/reviews/${id}`);
      return true;
    } catch (err) {
      console.error('[ReportService][deleteReview]', err);
      throw err;
    }
  }
  static async getReports(_ref4) {
    let {
      geometry,
      geometryBuffer,
      bounds,
      period,
      typeCodes,
      status,
      ordering,
      page,
      rowsPerPage,
      query,
      cyclabilityZoneId,
      sources,
      reviewCountMax,
      reviewCountMin,
      ongoingDateStart,
      ongoingDateEnd
    } = _ref4;
    try {
      const params = [];
      if (geometry) {
        params.push({
          key: 'geometry',
          value: (0, _polyline.fromGeoJSON)(geometry, 6)
        }, {
          key: 'geometry_buffer',
          value: geometryBuffer || 50
        });
      } else if (bounds) {
        const {
          north,
          east,
          south,
          west
        } = bounds;
        params.push({
          key: 'north',
          value: north
        }, {
          key: 'east',
          value: east
        }, {
          key: 'south',
          value: south
        }, {
          key: 'west',
          value: west
        });
      }
      if (period) params.push(...(0, _models.periodToParams)(period));
      if (ongoingDateStart) params.push({
        key: 'ongoing_report_date_start',
        value: ongoingDateStart.format('YYYY-MM-DD')
      });
      if (ongoingDateEnd) params.push({
        key: 'ongoing_report_date_end',
        value: ongoingDateEnd.format('YYYY-MM-DD')
      });
      if (typeCodes && typeCodes.length > 0) params.push({
        key: 'type_code',
        value: typeCodes.map(code => _reportType.backendReportTypesCodes[code]).join(',')
      });
      if (sources && sources.length === 1) {
        if (sources[0] === 'geovelo') params.push({
          key: 'no_opendata',
          value: true
        });else if (sources[0] === 'openData') params.push({
          key: 'opendata_only',
          value: true
        });
      }
      if (ordering) params.push({
        key: 'ordering',
        value: ordering
      });
      if (page) {
        params.push({
          key: 'page',
          value: page
        });
        if (rowsPerPage) params.push({
          key: 'page_size',
          value: rowsPerPage
        });
      }
      if (status && status.length > 0) params.push({
        key: 'status',
        value: status.map(status => status).join(',')
      });
      if (query) params.push({
        key: 'query',
        value: query
      });
      if (cyclabilityZoneId) params.push({
        key: 'cyclability_zone_id',
        value: cyclabilityZoneId
      });
      if (reviewCountMax !== undefined) params.push({
        key: 'review_count_max',
        value: reviewCountMax
      });
      if (reviewCountMin !== undefined) params.push({
        key: 'review_count_min',
        value: reviewCountMin
      });
      const {
        count,
        next,
        results
      } = await _http.HttpService.get('reports', '/reports', params);
      const reports = results.map(data => parseReport(data, _app.AppService.environment.backendUrl));
      return {
        count,
        next,
        reports
      };
    } catch (err) {
      console.error('[ReportService][getReports]', err);
      throw Array.isArray(err) ? new Error(err[0]) : err;
    }
  }
  static async getReport(id) {
    try {
      const data = await _http.HttpService.get('reportsV2', `/reports/${id}`);
      return parseReport(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[ReportService][getReport]', err);
      throw err;
    }
  }
  static async updateReport(id, _ref5) {
    let {
      description,
      geometry,
      isValid,
      point,
      status,
      type,
      scheduleDates
    } = _ref5;
    try {
      const body = {};
      if (description) body.description = description;
      if (status) body.status = status;
      if (type) body.type = type.id;
      if (typeof isValid === 'boolean') body.is_valid = true;
      if (geometry) body.geometry = JSON.stringify(geometry);
      if (point) body.geo_point = JSON.stringify(point);
      if (scheduleDates && scheduleDates[0]) body.start_date = (0, _moment.default)(scheduleDates[0]).format('YYYY-MM-DDTHH:mm');
      if (scheduleDates && scheduleDates[1]) body.end_date = (0, _moment.default)(scheduleDates[1]).format('YYYY-MM-DDTHH:mm');
      const data = await _http.HttpService.put('reportsV2', `/reports/${id}`, [], [], JSON.stringify(body));
      return parseReport(data, _app.AppService.environment.backendUrl);
    } catch (err) {
      console.error('[ReportService][updateReport]', err);
      throw err;
    }
  }
  static async deleteReport(id) {
    try {
      await _http.HttpService.delete('reportsV2', `/reports/${id}`);
      return true;
    } catch (err) {
      console.error('[ReportService][deleteReport]', err);
      throw err;
    }
  }
  static async addDropZone(_ref6) {
    let {
      reportId,
      title,
      description,
      point,
      geometry,
      capacity
    } = _ref6;
    try {
      const formData = new FormData();
      if (title) formData.append('title', title);
      if (description) formData.append('description', description);
      formData.append('point', JSON.stringify(point));
      if (geometry) formData.append('geometry', JSON.stringify(geometry));
      if (capacity) formData.append('capacity', capacity.toString());
      const data = await _http.HttpService.post('reports', `/reports/${reportId}/drop_zones`, [], [], formData);
      return parseDropZone(data);
    } catch (err) {
      console.error('[ReportService][addDropZone]', err);
      throw err;
    }
  }
  static async updateDropZone(_ref7) {
    let {
      reportId,
      dropZoneId,
      title,
      description,
      point,
      geometry,
      capacity
    } = _ref7;
    try {
      const formData = new FormData();
      if (title) formData.append('title', title);
      if (description) formData.append('description', description);
      formData.append('geo_point', JSON.stringify(point));
      if (geometry) formData.append('geometry', JSON.stringify(geometry));
      if (capacity) formData.append('capacity', capacity.toString());
      const data = await _http.HttpService.patch('reports', `/reports/${reportId}/drop_zones/${dropZoneId}`, [], [], formData);
      return parseDropZone(data);
    } catch (err) {
      console.error('[ReportService][updateDropZone]', err);
      throw err;
    }
  }
  static async deleteDropZone(reportId, dropZoneId) {
    try {
      await _http.HttpService.delete('reports', `/reports/${reportId}/drop_zones/${dropZoneId}`);
      return true;
    } catch (err) {
      console.error('[ReportService][deleteDropZone]', err);
      throw err;
    }
  }
}
exports.ReportService = ReportService;
function parseReview(_ref8, userId) {
  let {
    id,
    created,
    creator,
    comment,
    rating,
    is_creator: isCreator
  } = _ref8;
  return new _models.Review(id, (0, _moment.default)(created), typeof creator === 'string' ? {
    id: isCreator ? userId : undefined,
    username: creator
  } : creator, comment, rating);
}
function parseReport(_ref9, backendUrl) {
  let {
    id,
    created,
    updated,
    creator,
    description,
    geo_point,
    address,
    photo,
    source,
    status,
    report_type_code,
    reviews,
    start_date,
    end_date,
    is_temporary,
    osm_note_id,
    computed_route_id,
    report_source,
    is_valid,
    city_name,
    city_reference,
    geometry,
    drop_zones
  } = _ref9;
  return new _models.Report(id, (0, _moment.default)(created), updated ? (0, _moment.default)(updated) : (0, _moment.default)(created), (typeof creator === 'string' ? creator : creator?.username) || '', description, geo_point, address, city_name || city_reference ? {
    name: city_name || null,
    reference: city_reference || null
  } : undefined, photo ? `${backendUrl}${photo}` : undefined, (0, _models.toSource)(source), status, _reportType.reportTypesCodes[report_type_code], (reviews || []).map(data => parseReview(data)), start_date ? (0, _moment.default)(start_date) : null, end_date ? (0, _moment.default)(end_date) : null, is_temporary, osm_note_id, computed_route_id, report_source, Boolean(is_valid), geometry && geometry.type === 'Polygon' ? {
    type: 'MultiPolygon',
    coordinates: [geometry.coordinates]
  } : geometry, drop_zones?.map(dropZone => parseDropZone(dropZone)) || []);
}
function parseDropZone(_ref10) {
  let {
    id,
    created,
    updated,
    title,
    description,
    point,
    geometry,
    capacity
  } = _ref10;
  return new _models.DropZone(id, (0, _moment.default)(created), (0, _moment.default)(updated), title, description, point, geometry, capacity);
}