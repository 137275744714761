"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.useFacilities = useFacilities;
var _react = require("react");
var _services = require("../../services");
var _map = require("../../utils/map");
var _layers = require("./layers");
function useFacilities(map) {
  const initialized = (0, _react.useRef)(false);
  const facilityVectorLayers = (0, _react.useRef)();
  const layerOpacities = (0, _react.useRef)({});
  const {
    getLayerOpacities
  } = (0, _layers.useLayers)(map);
  async function init(enabled) {
    const {
      tileServer: {
        url: tileServerUrl,
        styleDirs: {
          facilities: styleDir
        }
      }
    } = _services.AppService.environment;
    const {
      sources,
      layers
    } = await (0, _map.getDataFromUrl)(`${tileServerUrl}/styles/${styleDir}/style.json`);
    Object.keys(sources).map(key => {
      if (!map?.getSource(key)) {
        map?.addSource(key, sources[key]);
      }
    });
    facilityVectorLayers.current = layers;
    layerOpacities.current = {
      ...getLayerOpacities(facilityVectorLayers.current, 'NewFacilities'),
      ...getLayerOpacities(facilityVectorLayers.current, 'NewFacilitiesSimplified')
    };
    toggle(enabled);
    initialized.current = true;
  }
  function toggle(enabled) {
    if (!map) return;
    if (enabled) {
      facilityVectorLayers.current?.forEach(layer => {
        if (!map.getLayer(layer.id)) map.addLayer(layer, 'facilities');
      });
    }
    map.getStyle().layers?.forEach(layer => {
      if ('source' in layer && (layer.source === 'NewFacilities' || layer.source === 'NewFacilitiesSimplified')) {
        const layerOpacity = layerOpacities.current[layer.id];
        if (layer.type === 'fill') {
          map.setPaintProperty(layer.id, 'fill-opacity', enabled ? layerOpacity !== undefined ? layerOpacity : 1 : 0);
        } else if (layer.type === 'line') {
          map.setPaintProperty(layer.id, 'line-opacity', enabled ? layerOpacity !== undefined ? layerOpacity : 1 : 0);
        } else {
          map.setLayoutProperty(layer.id, 'visibility', enabled ? 'visible' : 'none');
        }
      }
    });
  }
  function destroy() {
    initialized.current = false;
    facilityVectorLayers.current = undefined;
    layerOpacities.current = {};
  }
  return {
    initialized: initialized.current || false,
    init,
    toggle,
    destroy
  };
}