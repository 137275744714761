"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var _english = require("./english");
Object.keys(_english).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _english[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _english[key];
    }
  });
});
var _french = require("./french");
Object.keys(_french).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _french[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _french[key];
    }
  });
});
var _german = require("./german");
Object.keys(_german).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _german[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _german[key];
    }
  });
});
var _spanish = require("./spanish");
Object.keys(_spanish).forEach(function (key) {
  if (key === "default" || key === "__esModule") return;
  if (key in exports && exports[key] === _spanish[key]) return;
  Object.defineProperty(exports, key, {
    enumerable: true,
    get: function () {
      return _spanish[key];
    }
  });
});