"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CarRoutingService = void 0;
var _polyline = _interopRequireDefault(require("@mapbox/polyline"));
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class CarRoutingService {
  static async getRoute(wayPoints) {
    try {
      const result = await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        const coordinates = wayPoints.reduce((res, wayPoint, index) => {
          if (wayPoint && index !== 0) res += ';';
          res += wayPoint?.asParam;
          return res;
        }, '');
        request.open('GET', `https://router.project-osrm.org/route/v1/driving/${coordinates}`, true);
        request.responseType = 'json';
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300) {
            resolve(response);
          } else {
            reject(response);
          }
        };
        request.send();
      });
      return parseRoute(result.routes[0]);
    } catch (err) {
      console.error('[CarRoutingService][getRoute]', err);
      throw err;
    }
  }
}
exports.CarRoutingService = CarRoutingService;
function parseRoute(_ref) {
  let {
    distance,
    duration,
    geometry
  } = _ref;
  return {
    distance: distance,
    duration: duration,
    geometry: _polyline.default.toGeoJSON(geometry, 5)
  };
}