"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeepLinkService = void 0;
var _app = require("./app");
class DeepLinkService {
  static getCallback(_ref) {
    let {
      iosLink,
      androidLink
    } = _ref;
    const {
      android
    } = _app.AppService.environment;
    const userAgent = navigator.userAgent.toLowerCase();
    if (iosLink && userAgent.indexOf('iphone') > -1) {
      return () => {
        window.location.replace(`geovelo://${iosLink}`);
        // setTimeout(() => (window.location.href = ios.storeUrl), 25);
      };
    }
    if (androidLink && userAgent.indexOf('android') > -1) {
      return () => {
        window.location.replace(`${android.bundleId}://${androidLink}`);
        // setTimeout(() => (window.location.href = android.storeUrl), 500);
      };
    }
  }
}
exports.DeepLinkService = DeepLinkService;