"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.RefRouteService = void 0;
var _moment = _interopRequireDefault(require("moment"));
var _models = require("../models");
var _computedRoute = require("./computed-route");
var _cyclingProfile = require("./cycling-profile");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class RefRouteService {
  static async create(_ref) {
    let {
      description,
      ...input
    } = _ref;
    try {
      let body = {
        description
      };
      if ('computedRouteId' in input) {
        body.computedRouteId = input.computedRouteId;
      } else {
        const {
          cyclingProfile: profile,
          wayPoints,
          geometry: expected_geometry
        } = input.route;
        const search = new _models.Search({
          profile,
          wayPoints
        });
        const {
          computedRoutes
        } = await _computedRoute.ComputedRouteService.compute(search, {
          singleResult: true
        });
        if (!computedRoutes[0]) throw new Error('no computed route');
        body = {
          ...body,
          computedRouteId: computedRoutes[0].id,
          expected_geometry
        };
      }
      const {
        id
      } = await _http.HttpService.post('refRoutes', `/test_itineraries`, [], [], JSON.stringify(body));
      return this.run(id);
    } catch (err) {
      console.error('[RefRouteService][create]', err);
      throw err;
    }
  }
  static async getAll(_ref2) {
    let {
      page,
      pageSize,
      orderBy,
      order,
      search,
      query
    } = _ref2;
    try {
      const params = [{
        key: 'page',
        value: page
      }, {
        key: 'page_size',
        value: pageSize
      }, {
        key: 'query',
        value: query
      }];
      if (orderBy && order) {
        let value;
        if (orderBy === 'id') value = 'id';
        if (orderBy === 'status') value = 'passed';
        if (orderBy === 'creator') value = 'creator__username';
        if (orderBy === 'successDate') value = 'last_passed_date';
        if (orderBy === 'profile') value = 'profile';
        if (value) {
          if (order === 'desc') value = `-${value}`;
          params.push({
            key: 'ordering',
            value
          });
        }
      }
      if (search) params.push({
        key: 'search',
        value: search
      });
      const {
        count,
        results
      } = await _http.HttpService.get('refRoutesV3', `/test_itineraries`, params);
      const refRoutes = results.map(parseRefRouteV3);
      return {
        count,
        refRoutes
      };
    } catch (err) {
      console.error('[RefRouteService][getAll]', err);
      throw err;
    }
  }
  static async get(id) {
    try {
      const data = await _http.HttpService.get('refRoutes', `/test_itineraries/${id}`);
      const refRoutes = parseRefRoute(data);
      return refRoutes;
    } catch (err) {
      console.error('[RefRouteService][get]', err);
      throw err;
    }
  }
  static async run(id) {
    try {
      const data = await _http.HttpService.get('refRoutes', `/test_itineraries/${id}/run`);
      const refRoute = parseRefRoute(data);
      return refRoute;
    } catch (err) {
      console.error('[RefRouteService][create]', err);
      throw err;
    }
  }
  static async remove(id) {
    try {
      await _http.HttpService.delete('refRoutes', `/test_itineraries/${id}`);
      return true;
    } catch (err) {
      console.error('[RefRouteService][remove]', err);
      throw err;
    }
  }
}
exports.RefRouteService = RefRouteService;
function parseRefRoute(_ref3) {
  let {
    id,
    creator,
    created,
    computed_route_id,
    profile,
    geo_steps,
    geometry,
    expected_geometry,
    description,
    passed,
    succeeded
  } = _ref3;
  return new _models.RefRoute(id, creator, (0, _moment.default)(created), computed_route_id, profile && _cyclingProfile.backendCyclingProfiles[profile], geo_steps?.coordinates.map(coordinates => ({
    type: 'Point',
    coordinates
  })), geometry, expected_geometry, description, passed ? 'success' : 'failure', succeeded ? (0, _moment.default)(succeeded) : null);
}
function parseRefRouteV3(_ref4) {
  let {
    id,
    creator,
    created,
    computed_route_id,
    profile,
    geo_steps,
    geometry,
    expected_geometry,
    description,
    passed,
    last_passed_date
  } = _ref4;
  return new _models.RefRoute(id, creator, (0, _moment.default)(created), computed_route_id, profile && _cyclingProfile.backendCyclingProfiles[profile], geo_steps?.coordinates.map(coordinates => ({
    type: 'Point',
    coordinates
  })), geometry, expected_geometry, description, passed ? 'success' : 'failure', last_passed_date ? (0, _moment.default)(last_passed_date) : null);
}