"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.josmWikiUrl = exports.JOSMService = void 0;
const url = 'http://127.0.0.1:8111';
const josmWikiUrl = exports.josmWikiUrl = 'https://wiki.openstreetmap.org/wiki/FR:JOSM';
class JOSMService {
  static getLoadAndZoomUrl(_ref) {
    let {
      north,
      east,
      south,
      west
    } = _ref;
    return `${url}/load_and_zoom?left=${west}&right=${east}&top=${north}&bottom=${south}`;
  }
  static async openJOSM(bounds) {
    try {
      return await new Promise((resolve, reject) => {
        const request = new XMLHttpRequest();
        request.open('GET', JOSMService.getLoadAndZoomUrl(bounds), true);
        request.setRequestHeader('Content-Type', 'application/json;charset=UTF-8');
        request.onerror = err => {
          reject(err);
        };
        request.onload = () => {
          const {
            status,
            response
          } = request;
          if (status >= 200 && status < 300) {
            resolve();
          } else {
            reject(response);
          }
        };
        request.send();
      });
    } catch (err) {
      console.error('[JOSMService][openJOSM]', err);
      throw err;
    }
  }
}
exports.JOSMService = JOSMService;