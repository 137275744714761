"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.GeoaggloService = void 0;
var _models = require("../models");
var _app = require("./app");
var _http = require("./http");
var _photo = require("./photo");
var _maplibreGl = require("!maplibre-gl");
class GeoaggloService {
  static async getGeoagglos() {
    let {
      query
    } = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    try {
      const res = await _http.HttpService.get('geoagglos', `/geoagglos`, query ? [{
        key: 'query',
        value: query
      }] : undefined);
      const geoagglos = res.map(data => parseGeoagglo(data, _app.AppService.environment.backendUrl));
      return geoagglos;
    } catch (err) {
      console.error('[GeoaggloService][getGeoagglos]', err);
      throw err;
    }
  }
  static async getHighlights(geoaggloId) {
    try {
      const res = await _http.HttpService.get('highlights', `/highlights`, [{
        key: 'geoagglo_id',
        value: geoaggloId
      }]);
      const highlights = res.map(data => parseGeoaggloHighlight(data, _app.AppService.environment.backendUrl));
      return highlights;
    } catch (err) {
      console.error('[GeoaggloService][getHighlights]', err);
      throw err;
    }
  }
}
exports.GeoaggloService = GeoaggloService;
function parseGeoagglo(_ref, backendUrl) {
  let {
    id,
    code,
    area,
    bounds: _bounds,
    geo_center,
    long_name,
    logo,
    logo_alt,
    photo,
    has_bss,
    importance
  } = _ref;
  let bounds = null;
  let center = geo_center;
  if (_bounds) {
    const [west, south, east, north] = _bounds;
    bounds = {
      north,
      east,
      south,
      west
    };
    if (!center) {
      const {
        lat,
        lng
      } = new _maplibreGl.LngLatBounds({
        lat: south,
        lng: west
      }, {
        lat: north,
        lng: east
      }).getCenter();
      center = {
        type: 'Point',
        coordinates: [lng, lat]
      };
    }
  }
  return new _models.Geoagglo(id, code, area, bounds, center, long_name, logo ? `${backendUrl}${logo}` : null, logo_alt ? `${backendUrl}${logo_alt}` : null, photo ? {
    url: `${backendUrl}${photo}`,
    squaredThumbnailUrl: `${backendUrl}${photo.replace('large', 'thumbnail')}`
  } : null, has_bss, importance || 0);
}
function parseGeoaggloHighlight(_ref2, backendUrl) {
  let {
    id,
    title,
    description,
    photo,
    ride,
    rideset,
    poi
  } = _ref2;
  return new _models.GeoaggloHighlight(id, title, description, photo ? (0, _photo.parsePhoto)(photo, backendUrl) : null, null, false, ride, rideset, poi);
}