"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.HttpService = void 0;
var _app = require("./app");
class HttpService {
  static getAuthorizationToken() {
    try {
      const authorizationToken = typeof window !== 'undefined' && (localStorage.getItem('impersonate_authorization_token') || localStorage.getItem('authorization_token') || sessionStorage.getItem('authorization_token')) || null;
      return authorizationToken;
    } catch {
      console.error('localStorage access is denied');
    }
    return null;
  }
  static authorizationToken = this.getAuthorizationToken();
  static partner = null;
  static request(method, endPoint, route, queryParams, headers, body, _ref) {
    let {
      setAuthorizationToken,
      contentType,
      responseType,
      expectedStatus,
      partner
    } = _ref;
    const {
      backendUrl,
      defaultApiVersion,
      apiVersions,
      apiKey,
      source
    } = _app.AppService.environment;
    const apiUrl = `${backendUrl}/api`;
    const apiVersion = apiVersions[endPoint] || defaultApiVersion;
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.open(method, `${apiUrl}/v${apiVersion}${route}?${queryParams.map(_ref2 => {
        let {
          key,
          value
        } = _ref2;
        return `${key}=${value}`;
      }).join('&')}`, true);
      request.responseType = responseType || 'json';
      if (!(body instanceof FormData)) request.setRequestHeader('Content-Type', contentType || 'application/json;charset=UTF-8');
      request.setRequestHeader('Api-key', apiKey);
      if (source) request.setRequestHeader('Source', source);
      if (this.authorizationToken) request.setRequestHeader('Authorization', this.authorizationToken);
      if (partner) request.setRequestHeader('partner', partner);else if (this.partner) request.setRequestHeader('partner', this.partner);
      headers.forEach(_ref3 => {
        let {
          key,
          value
        } = _ref3;
        return request.setRequestHeader(key, value);
      });
      request.onload = () => {
        let authorizationToken;
        let userId;
        if (setAuthorizationToken) {
          const _authorizationToken = request.getResponseHeader('Authorization');
          const _userId = request.getResponseHeader('UserId');
          if (_authorizationToken && _userId) {
            authorizationToken = this.authorizationToken = _authorizationToken;
            userId = parseInt(_userId, 10);
          } else {
            reject({
              code: request.status,
              message: request.statusText
            });
            return;
          }
        }
        const {
          status,
          response
        } = request;
        if (expectedStatus ? status === expectedStatus : status >= 200 && status < 300) {
          resolve(setAuthorizationToken ? {
            authorizationToken,
            userId
          } : response);
        } else {
          reject(expectedStatus ? {
            status,
            message: response
          } : response || new Error(`Error ${status}`));
        }
      };
      request.onerror = () => {
        console.error('[HTTP] Request Error');
        reject(request.response);
      };
      request.send(body);
    });
  }
  static get(endPoint, route) {
    let queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let body = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    let params = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
    return this.request('GET', endPoint, route, queryParams, headers, body, params);
  }
  static post(endPoint, route) {
    let queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let body = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    let params = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
    return this.request('POST', endPoint, route, queryParams, headers, body, params);
  }
  static put(endPoint, route) {
    let queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let body = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    let params = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
    return this.request('PUT', endPoint, route, queryParams, headers, body, params);
  }
  static patch(endPoint, route) {
    let queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let body = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    let params = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
    return this.request('PATCH', endPoint, route, queryParams, headers, body, params);
  }
  static async delete(endPoint, route) {
    let queryParams = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
    let headers = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
    let body = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
    let params = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : {};
    await this.request('DELETE', endPoint, route, queryParams, headers, body, params);
    return true;
  }
}
exports.HttpService = HttpService;