"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.usePopup = usePopup;
function usePopup(map) {
  function centerPopup(markerLocation, popup) {
    let {
      height,
      offset
    } = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
    if (!map) return;
    const px = map.project(markerLocation);
    px.x -= offset?.x || 0;
    px.y -= (height || popup.getElement().clientHeight) / 2 + (offset?.y || 0) / 2;
    map.stop();
    map.panTo(map.unproject(px), {
      animate: true
    });
  }
  return {
    centerPopup
  };
}