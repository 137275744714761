"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ExportDataService = void 0;
exports.parseExportData = parseExportData;
exports.parseExportDataProjection = parseExportDataProjection;
var _moment = _interopRequireDefault(require("moment"));
var _exportData = require("../models/export-data");
var _http = require("./http");
function _interopRequireDefault(e) { return e && e.__esModule ? e : { default: e }; }
class ExportDataService {
  static async getExportData() {
    try {
      const data = await _http.HttpService.get('exportData', '/partners/export_data');
      return data.map(parseExportData);
    } catch (err) {
      console.error('[ExportDataService][getExportData]', err);
      throw err;
    }
  }
  static async getExportDataProjections() {
    try {
      const data = await _http.HttpService.get('exportData', '/export_data_allowed_projections');
      return data.map(parseExportDataProjection);
    } catch (err) {
      console.error('[ExportDataService][getExportDataProjections]', err);
      throw err;
    }
  }
}
exports.ExportDataService = ExportDataService;
function parseExportData(_ref) {
  let {
    id,
    created,
    type,
    model,
    description,
    title,
    tabler_export
  } = _ref;
  return new _exportData.ExportData(id, (0, _moment.default)(created), type, model, description, title, tabler_export);
}
function parseExportDataProjection(_ref2) {
  let {
    id,
    title,
    srid
  } = _ref2;
  return new _exportData.ExportDataProjection(id, title, srid);
}